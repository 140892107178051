import {
  AfterViewInit,
  Component,
  EventEmitter,
  Host,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Optional,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';

import { DwMatchMediaService } from '../core/services/dw-match-media.service';
import { toBoolean } from '../core/util/convert';

import { DwLayoutComponent } from './dw-layout.component';

export type DwBreakPoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

@Component({
  selector           : 'dw-sider',
  preserveWhitespaces: false,
  templateUrl        : './dw-sider.component.html',
  host               : {
    '[class.ant-layout-sider]': 'true'
  }
})
export class DwSiderComponent implements OnInit, AfterViewInit {
  private _collapsed = false;
  private _collapsible = false;
  @ViewChild('defaultTrigger') _trigger: TemplateRef<void>;
  private _reverseArrow = false;
  private below = false;
  private isInit = false;
  private dimensionMap = {
    xs : '480px',
    sm : '576px',
    md : '768px',
    lg : '992px',
    xl : '1200px',
    xxl: '1600px'
  };
  @Input() dwWidth = 200;
  @Input() dwCollapsedWidth = 80;
  @Input() dwBreakpoint: DwBreakPoint;

  @Input()
  set dwReverseArrow(value: boolean) {
    this._reverseArrow = toBoolean(value);
  }

  get dwReverseArrow(): boolean {
    return this._reverseArrow;
  }

  @Input()
  set dwTrigger(value: TemplateRef<void>) {
    this._trigger = value;
  }

  get dwTrigger(): TemplateRef<void> {
    return this._trigger;
  }

  @Input()
  set dwCollapsible(value: boolean) {
    this._collapsible = toBoolean(value);
  }

  get dwCollapsible(): boolean {
    return this._collapsible;
  }

  @Input()
  @HostBinding('class.ant-layout-sider-collapsed')
  set dwCollapsed(value: boolean) {
    this._collapsed = toBoolean(value);
  }

  get dwCollapsed(): boolean {
    return this._collapsed;
  }

  @Output() dwCollapsedChange = new EventEmitter();

  @HostBinding('class.ant-layout-sider-zero-width')
  get setZeroClass(): boolean {
    return this.dwCollapsed && (this.dwCollapsedWidth === 0);
  }

  @HostBinding('style.flex')
  get setFlex(): string {
    if (this.dwCollapsed) {
      return `0 0 ${this.dwCollapsedWidth}px`;
    } else {
      return `0 0 ${this.dwWidth}px`;
    }
  }

  @HostBinding('style.max-width.px')
  @HostBinding('style.min-width.px')
  @HostBinding('style.width.px')
  get setWidth(): number {
    if (this.dwCollapsed) {
      return this.dwCollapsedWidth;
    } else {
      return this.dwWidth;
    }
  }

  @HostListener('window:resize', [ '$event' ])
  onWindowResize(e: UIEvent): void {
    this.watchMatchMedia();
  }

  watchMatchMedia(): void {
    if (this.dwBreakpoint) {
      const matchBelow = this.dwMatchMediaService.matchMedia(`(max-width: ${this.dimensionMap[ this.dwBreakpoint ]})`).matches;
      this.below = matchBelow;
      this.dwCollapsed = matchBelow;
      if (this.isInit) {
        this.dwCollapsedChange.emit(matchBelow);
      }
    }
  }

  toggleCollapse(): void {
    this.dwCollapsed = !this.dwCollapsed;
    this.dwCollapsedChange.emit(this.dwCollapsed);
  }

  get isZeroTrigger(): boolean {
    return this.dwCollapsible && this.dwTrigger && (this.dwCollapsedWidth === 0) && ((this.dwBreakpoint && this.below) || (!this.dwBreakpoint));
  }

  get isSiderTrigger(): boolean {
    return this.dwCollapsible && this.dwTrigger && (this.dwCollapsedWidth !== 0);
  }

  constructor(@Optional() @Host() private dwLayoutComponent: DwLayoutComponent, private dwMatchMediaService: DwMatchMediaService) {
  }

  ngOnInit(): void {
    this.watchMatchMedia();
    if (this.dwLayoutComponent) {
      this.dwLayoutComponent.hasSider = true;
    }
  }

  ngAfterViewInit(): void {
    this.isInit = true;
  }

}
