import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DwRateComponent } from './dw-rate.component';

@NgModule({
  exports     : [ DwRateComponent ],
  declarations: [ DwRateComponent ],
  imports     : [ CommonModule ]
})
export class DwRateModule {
}
