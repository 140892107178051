import {
  Component,
  Input,
  TemplateRef
} from '@angular/core';

@Component({
  selector           : '[dw-tab-body]',
  preserveWhitespaces: false,
  templateUrl        : './dw-tab-body.component.html'
})
export class DwTabBodyComponent {
  @Input() content: TemplateRef<void>;
}
