import { ModuleWithProviders, NgModule } from '@angular/core';
import { DwAffixModule } from './affix/dw-affix.module';
import { DwAlertModule } from './alert/dw-alert.module';
import { DwAnchorModule } from './anchor/dw-anchor.module';
import { DwAutocompleteModule } from './auto-complete/dw-autocomplete.module';
import { DwAvatarModule } from './avatar/dw-avatar.module';
import { DwBackTopModule } from './back-top/dw-back-top.module';
import { DwBadgeModule } from './badge/dw-badge.module';
import { DwBreadCrumbModule } from './breadcrumb/dw-breadcrumb.module';
import { DwButtonModule } from './button/dw-button.module';
import { DwCalendarModule } from './calendar/dw-calendar.module';
import { DwCardModule } from './card/dw-card.module';
import { DwCarouselModule } from './carousel/dw-carousel.module';
import { DwCascaderModule } from './cascader/dw-cascader.module';
import { DwCheckboxModule } from './checkbox/dw-checkbox.module';
import { DwCollapseModule } from './collapse/dw-collapse.module';
import { DwWaveModule } from './core/wave/dw-wave.module';
import { DwDatePickerModule } from './date-picker/date-picker.module';
import { DwDividerModule } from './divider/dw-divider.module';
import { DwDrawerModule } from './drawer/dw-drawer.module';
import { DwDropDownModule } from './dropdown/dw-dropdown.module';
import { DwFormModule } from './form/dw-form.module';
import { DwGridModule } from './grid/dw-grid.module';
import { DwI18nModule } from './i18n/dw-i18n.module';
import { DwInputNumberModule } from './input-number/dw-input-number.module';
import { DwInputModule } from './input/dw-input.module';
import { DwLayoutModule } from './layout/dw-layout.module';
import { DwListModule } from './list/dw-list.module';
import { DwMentionModule } from './mention/mention.module';
import { DwMenuModule } from './menu/dw-menu.module';
import { DwMessageModule } from './message/dw-message.module';
import { DwModalModule } from './modal/dw-modal.module';
import { DwNotificationModule } from './notification/dw-notification.module';
import { DwTreeSelectModule } from './tree-select/dw-tree-select.module';
import { DwPaginationModule } from './pagination/dw-pagination.module';
import { DwPopconfirmModule } from './popconfirm/dw-popconfirm.module';
import { DwPopoverModule } from './popover/dw-popover.module';
import { DwProgressModule } from './progress/dw-progress.module';
import { DwRadioModule } from './radio/dw-radio.module';
import { DwRateModule } from './rate/dw-rate.module';
import { DwSelectModule } from './select/dw-select.module';
import { DwSkeletonModule } from './skeleton/dw-skeleton.module';
import { DwSliderModule } from './slider/dw-slider.module';
import { DwSpinModule } from './spin/dw-spin.module';
import { DwStepsModule } from './steps/dw-steps.module';
import { DwSwitchModule } from './switch/dw-switch.module';
import { DwTableModule } from './table/dw-table.module';
import { DwTabsModule } from './tabs/dw-tabs.module';
import { DwTagModule } from './tag/dw-tag.module';
import { DwTimePickerModule } from './time-picker/dw-time-picker.module';
import { DwTimelineModule } from './timeline/dw-timeline.module';
import { DwToolTipModule } from './tooltip/dw-tooltip.module';
import { DwTransferModule } from './transfer/dw-transfer.module';
import { DwTreeModule } from './tree/dw-tree.module';
import { DwUploadModule } from './upload/dw-upload.module';

export * from './affix';
export * from './alert';
export * from './anchor';
export * from './avatar';
export * from './back-top';
export * from './badge';
export * from './breadcrumb';
export * from './button';
export * from './calendar';
export * from './card';
export * from './carousel';
export * from './checkbox';
export * from './collapse';
export * from './date-picker';
export * from './divider';
export * from './drawer';
export * from './dropdown';
export * from './drawer';
export * from './form';
export * from './grid';
export * from './i18n';
export * from './input';
export * from './input-number';
export * from './layout';
export * from './list';
export * from './mention';
export * from './menu';
export * from './pagination';
export * from './progress';
export * from './radio';
export * from './rate';
export * from './select';
export * from './spin';
export * from './steps';
export * from './switch';
export * from './table';
export * from './tabs';
export * from './timeline';
export * from './transfer';
export * from './upload';
export * from './tag';
export * from './auto-complete';
export * from './message';
export * from './time-picker';
export * from './tooltip';
export * from './skeleton';
export * from './slider';
export * from './popover';
export * from './notification';
export * from './popconfirm';
export * from './modal';
export * from './cascader';
export * from './tree';
export * from './tree-select';
export * from './time-picker';
export * from './version';
export * from './core/wave';
export * from './core/util';

@NgModule({
  exports: [
    DwButtonModule,
    DwCalendarModule,
    DwGridModule,
    DwSwitchModule,
    DwSelectModule,
    DwMenuModule,
    DwMentionModule,
    DwAnchorModule,
    DwAffixModule,
    DwDropDownModule,
    DwLayoutModule,
    DwBreadCrumbModule,
    DwPaginationModule,
    DwStepsModule,
    DwInputModule,
    DwCheckboxModule,
    DwInputNumberModule,
    DwSliderModule,
    DwRateModule,
    DwBadgeModule,
    DwRadioModule,
    DwAlertModule,
    DwSpinModule,
    DwProgressModule,
    DwTabsModule,
    DwCardModule,
    DwAvatarModule,
    DwTimelineModule,
    DwTransferModule,
    DwCarouselModule,
    DwCollapseModule,
    DwTableModule,
    DwDatePickerModule,
    DwDividerModule,
    DwDrawerModule,
    DwFormModule,
    DwListModule,
    DwI18nModule,
    DwUploadModule,
    DwAutocompleteModule,
    DwTagModule,
    DwMessageModule,
    DwNotificationModule,
    DwPopoverModule,
    DwToolTipModule,
    DwPopconfirmModule,
    DwModalModule,
    DwBackTopModule,
    DwCascaderModule,
    DwTreeModule,
    DwTreeSelectModule,
    DwTimePickerModule,
    DwWaveModule,
    DwSkeletonModule
  ]
})
export class NgQuicksilverModule {
  /**
   * @deprecated Use `NgQuicksilverModule` instead.
   */
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: NgQuicksilverModule
    };
  }
}
