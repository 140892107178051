import { Component, ElementRef, Optional, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { IAfterGuiAttachedParams, ICellEditorParams } from 'ag-grid-community';
import { FxAgGridValidationEditorComponent } from './fx-ag-grid-validation-editor';
import { FxAgGridEventHandlerService } from './fx-ag-grid-event-handler.service';
import { Subscription } from 'rxjs';
import { FxUserInfoService } from 'app/implementation/auth/fx-auth/fx-user-info.service';

@Component({
  selector: 'fx-ag-number-cell-editor',
  template: `
    <div dw-tooltip dwPlacement="topLeft" dwOverlayClassName="ag-invalid-cell-overlay"
         [dwTitle]="errorContent" [dwVisible]="errorVisible"
         *ngIf="errorVisible"></div>
    <ng-template #errorContent>
      <div *ngFor="let msg of validationMessages"> {{message}} {{ msg.key | translate:msg.params }}</div>
    </ng-template>
    <form [formGroup]="validateForm">
      <dw-form-control>
        <dw-input-group>
          <input [formControl]="formControl" (keydown)="onKeyDown($event)" [readOnly]="readOnlyExpr(params)" dw-input dw-input-number placeholder=""  [(ngModel)] = "dwValueDisplay" #cellInput>
        </dw-input-group>
      </dw-form-control>
    </form>
  `
})
export class FxAgNumberFormatCellEditorComponent extends FxAgGridValidationEditorComponent implements ICellEditorAngularComp, OnDestroy {
  modalService;
  colId;
  name: string;
  params: ICellEditorParams | any;  // Added by David Lee 25Feb2019
  valueSetSubscription: Subscription;  // Added by David Lee 25Feb2019
  validateForm: FormGroup;  // Added by David Lee 1Mar2019
  message: string;
  dwType: string = 'default'; // 小数位数类别
  dwCode: string; // 小数位代码
  dwMin: number;          // 最小值
  dwMax: number;          // 最大值
  dwPositiveNum: number;   // 正数的小数点位数
  dwNegtiveNum: number;   // 负数的小数点位数
  dwHasZero: boolean = false; // 是否允许输入00开头的数值
  defaultDecimal = null;
  decimal = null;
  defaultRoundType = null;
  roundType = null;
  oldDwValue: number = null; // 记录旧值
  dwValue: number;
  public value;
  dwValueDisplay: number;// 显示的数值，1.解决返回number类型小数末尾是0会舍去的问题
  @ViewChild('cellInput') cellInput: ElementRef;
  readOnlyExpr: Function;

  constructor(private fb: FormBuilder, @Optional()
  private eventHandler: FxAgGridEventHandlerService,
    private fxUserService: FxUserInfoService) {
    super();
    // console.warn('创建TexCellEditor');
  }
  onKeyDown(event): void {
    if (event.keyCode == 13 || event.keyCode == 27 || event.keyCode == 37 || event.keyCode == 38
      || event.keyCode == 39 || event.keyCode == 40) {
      event.stopPropagation();
    }
  }
  // gets called once after the editor is created
  agInit(params: ICellEditorParams | any): void {
    this.getParams(params);
    this.getData();

    this.params = params;  // 传入参数
    this.colId = params.column.colId;
    this.editType = params.api.gridOptionsWrapper.gridOptions.editType || '';
    this.formControl = new FormControl(params.value, params.validators);
    this.oldDwValue = params.value; // 记录旧值
    this.dwValue = params.value ? JSON.parse(JSON.stringify(params.value)) : '';
    this.dwValueDisplay = params.value ? JSON.parse(JSON.stringify(params.value)) : '';
    this.initValue();
    this.validateForm = params.form;
    if (!params.form) {
      this.validateForm = this.fb.group({});
    }
    this.readOnlyExpr = params.readOnlyExpr
      ? ((param: any): boolean => (params.readOnlyExpr(param)))
      : ((param: any): boolean => false);

    this.name = this.params.node.id + '-' + params.column.colId;

    // console.warn('初始化TexCellEditor', this.name);

    if (this.validateForm.contains(this.name)) {
      this.validateForm.setControl(this.name, this.formControl);

    } else {
      this.validateForm.addControl(this.name, this.formControl);
    }

    this.formControl.markAsDirty();

    // 监听赋值 Add David lee 25 Feb 2019
    this.valueSetSubscription = this.eventHandler.valueSet.subscribe(event => {
      if ((event.colId == this.colId) && (event.gridId == params.context.id) && (!event.id || (event.id && event.id === params.node.id))) {
        this.formControl.setValue(event.value);
        this.message = event.message;
        // this.validationMessages.push({
        // key: 'response',
        // params: {message: event.message}
        // });
        this.formControl.markAsDirty();
      }
    });

    super.init();
  }

  // Gets called once after GUI is attached to DOM.
  // Useful if you want to focus or highlight a component
  // (this is not possible when the element is not attached)
  afterGuiAttached(): void {
    if (this.cellInput && !this.isFullRowEdit()) {
      this.cellInput.nativeElement.focus();
      this.cellInput.nativeElement.select();
    }
    // 通过订阅消息回传onFocus事件
    this.cellInput.nativeElement.onfocus = (e: any): void => {
      if (this.eventHandler) {
        this.eventHandler.onBeforeField({
          gridId: this.params.context.id,
          value: this.formControl.value,
          rowIndex: this.params.node.rowIndex,
          id: this.params.node.id,
          colId: this.colId,
          controlName: this.name
        });
      }
    };

    // 通过订阅消息回传onChange事件
    this.cellInput.nativeElement.onchange = (event): void => {
      if (this.eventHandler) {
        this.initValue();
        this.eventHandler.onChangeField({
          gridId: this.params.context.id,
          value: this.formControl.value,
          rowIndex: this.params.node.rowIndex,
          id: this.params.node.id,
          colId: this.colId,
          controlName: this.name
        });
      }
    };

    // 通过订阅消息回传onBlur事件
    this.cellInput.nativeElement.onblur = (e: any): void => {
      if (this.eventHandler) {
        this.eventHandler.onAfterField({
          gridId: this.params.context.id,
          value: this.formControl.value,
          rowIndex: this.params.node.rowIndex,
          id: this.params.node.id,
          colId: this.colId,
          controlName: this.name
        });
      }
    };

  }
  getParams(params): void {
    if (params.dwType) {
      this.dwType = params.dwType;
    }
    if (params.dwCode) {
      this.dwCode = params.dwCode;
    }
    if (params.dwMin != undefined && params.dwMin != null) {
      this.dwMin = params.dwMin;
    }
    if (params.dwMax != undefined && params.dwMax != null) {
      this.dwMax = params.dwMax;
    }
    if (params.dwPositiveNum != undefined && params.dwPositiveNum != null && params.dwPositiveNum >= 0) {
      this.dwPositiveNum = params.dwPositiveNum;
    }
    if (params.dwNegtiveNum != undefined && params.dwNegtiveNum != null && params.dwNegtiveNum >= 0) {
      this.dwNegtiveNum = params.dwNegtiveNum;
    }
    if (params.dwHasZero) {
      this.dwHasZero = params.dwHasZero;
    }
  }

  // 获取设置的币种和单位
  async getData(): Promise<any> {
    if (this.dwType == 'unit') {
      this.defaultDecimal = (this.fxUserService.getEntParamById('c_E067') === null || this.fxUserService.getEntParamById('c_E067') === undefined || this.fxUserService.getEntParamById('c_E067') === '') ? 2 : this.fxUserService.getEntParamById('c_E067');
      this.defaultRoundType = (this.fxUserService.getEntParamById('c_E068') === null || this.fxUserService.getEntParamById('c_E068') === undefined || this.fxUserService.getEntParamById('c_E068') === '') ? 2 : this.fxUserService.getEntParamById('c_E068');
    } else if ((['unitPriceDecimal', 'priceDecimal']).indexOf(this.dwType) != -1) {
      let defaultCode = this.fxUserService.userInfo['c_curr'];
      let defaultItems = this.fxUserService.getCurrencyParam([]);
      let defaultItem =  defaultItems ? defaultItems.filter(x => x.baacurr == defaultCode)[0] : null;
      if (defaultItem) {
        if (this.dwType == 'unitPriceDecimal') {
          this.defaultDecimal = defaultItem.baa001;
        } else {
          this.defaultDecimal = defaultItem.baa002;
        }
        this.defaultRoundType = defaultItem ? (defaultItem.baa003 ? defaultItem.baa003 : 2) : 2;
      } else {
        this.defaultDecimal = 2;
        this.defaultRoundType = 2;
      }
    }

    var items = [];
    var defaultCurr;
    if (this.dwType == 'unit') {
      if (this.dwCode == 'default' || !this.dwCode) { // dwCode传的default或空，取集团参数里设置的单位默认小数位数和舍入方式
        this.decimal = (this.fxUserService.getEntParamById('c_E067') === null || this.fxUserService.getEntParamById('c_E067') === undefined || this.fxUserService.getEntParamById('c_E067') === '') ? this.defaultDecimal : this.fxUserService.getEntParamById('c_E067');
        this.roundType = (this.fxUserService.getEntParamById('c_E068') === null || this.fxUserService.getEntParamById('c_E068') === undefined || this.fxUserService.getEntParamById('c_E068') === '') ? this.defaultRoundType : this.fxUserService.getEntParamById('c_E068');
      } else {
        items = this.fxUserService.getUnitParam([]);
        defaultCurr = items ? items.filter(x => x.bacunit == this.dwCode)[0] : null;
        if (!defaultCurr) {
          this.decimal = this.defaultDecimal;
          this.roundType = this.defaultRoundType;
        } else {
          this.decimal = defaultCurr.bac002;
          this.roundType = defaultCurr.bac003 ? defaultCurr.bac003 : this.defaultRoundType;
        }
      }
    } else if ((['unitPriceDecimal', 'priceDecimal']).indexOf(this.dwType) != -1) {
      if (this.dwCode == 'default' || !this.dwCode) {// dwCode传的default或空，取缓存里的当前登录组织设置的主币别
        this.dwCode = this.fxUserService.userInfo['c_curr'];
      }
      items = this.fxUserService.getCurrencyParam([]);
      defaultCurr = items ? items.filter(x => x.baacurr == this.dwCode)[0] : null;
      if (!defaultCurr) {
        this.decimal = this.defaultDecimal;
        this.roundType = this.defaultRoundType;
      } else {
        this.roundType = defaultCurr.baa003 ? defaultCurr.baa003 : this.defaultRoundType;
        switch (this.dwType) {
          case 'unitPriceDecimal':
            this.decimal = defaultCurr.baa001;
            break;

          case 'priceDecimal':
            this.decimal = defaultCurr.baa002;
            break;

          default:
            break;
        }
      }
    }
    if (this.decimal == null) this.decimal = this.defaultDecimal;
    if (this.roundType == null) this.roundType = this.defaultRoundType;
  }

  initValue(): number {
    if (this.formControl.value === undefined || this.formControl.value === null || this.formControl.value === '') {
      return;
    }
    var reg0 = /^(\-|\+)?\d+(\.\d+)?$/;
    // if (!reg0.test(this.formControl.value)) {
    if (parseFloat(this.formControl.value).toString() == "NaN") {
      this.dwValue = this.oldDwValue;
      this.formControl.setValue(this.dwValue);
      this.dwValueDisplay = this.dwValue;
      return this.dwValue;
    } else {
      if (this.formControl.value !== undefined && this.formControl.value !== null && this.formControl.value !== '') {
        this.formControl.setValue(parseFloat(this.formControl.value).toString());
      }
      this.dwValue = this.formControl.value;
    }
    if (this.dwValue == 0) {
      this.dwValue = 0;
      this.formControl.setValue(this.dwValue);
      this.dwValueDisplay = this.dwValue;
      return this.dwValue;
    }
    var reg01 = /^\d+(\.\d+)?$/;
    if (reg01.test(this.dwValue.toString())) { // 正数
      if (this.dwPositiveNum != undefined && this.dwPositiveNum != null && this.dwPositiveNum >= 0) { // 设置了正数小数位数
        this.roundType = 2;        // 使用作业自定义小数位数默认采用舍入类型为四舍六入五成双
        this.defaultRoundType = 2;
        this.decimal = this.dwPositiveNum;  // 使用作业自定义小数位数
        this.defaultDecimal = this.dwPositiveNum;
      }
    } else {
      if (this.dwNegtiveNum != undefined && this.dwNegtiveNum != null && this.dwNegtiveNum >= 0) { // 设置了负数小数位数
        this.roundType = 2;        // 使用作业自定义小数位数默认采用舍入类型为四舍六入五成双
        this.defaultRoundType = 2;
        this.decimal = this.dwNegtiveNum;  // 使用作业自定义小数位数
        this.defaultDecimal = this.dwNegtiveNum;
      }
    }
    if (this.dwHasZero) { // 允许输入0开头的数值
      this.dwValue = this.formControl.value.replace(/\D/g, '');
      this.oldDwValue = this.dwValue;
    } else {
      if (this.decimal != undefined && this.decimal != null && this.decimal >= 0) {
        this.dwValue = this.roundFormat(this.decimal, Number(this.dwValue), this.roundType); // 舍入和自动补0
        if ((this.dwMin != undefined && Number(this.dwValue) < this.dwMin) || (this.dwMax != undefined && Number(this.dwValue) > this.dwMax)) {
          this.dwValue = this.oldDwValue;
        } else {
          this.oldDwValue = this.dwValue;
        }
      } else {
        this.dwValue = this.oldDwValue;
      }
    }
    this.dwValueDisplay = this.dwValue;
    this.formControl.setValue(this.dwValue);
    return this.dwValue;
  }

  /**
   * Decimal adjustment of a number.
   *
   * @param	{String}	type	The type of adjustment.
   * @param	{Number}	value	The number.
   * @param	{Integer}	exp		The exponent (the 10 logarithm of the adjustment base).
   * @returns	{Number}			The adjusted value.
   */
  decimalAdjust(type, value, exp) {
    // If the exp is undefined or zero...
    if (typeof exp === 'undefined' || +exp === 0) {
        return Math[type](value);
    }
    value = +value;
    exp = +exp;
    // If the value is not a number or the exp is not an integer...
    if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
        return NaN;
    }
    // Shift
    value = value.toString().split('e');
    value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
    // Shift back
    value = value.toString().split('e');
    return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
  }

  // Decimal round
  round10 = function(value, exp) {
      return this.decimalAdjust('round', value, exp);
  };
  // Decimal floor
  floor10 = function(value, exp) {
      return this.decimalAdjust('floor', value, exp);
  };
  // Decimal ceil
  ceil10 = function(value, exp) {
      return this.decimalAdjust('ceil', value, exp);
  };

  roundFormat(decimal: any, num: any, roundType: any): number {
    if (decimal != null) {
      if (roundType == '1') {  // 舍入类型为四舍五入
        num = this.round10(num,  -1 * decimal);
      } else if (roundType == '2') {  // 舍入类型为四舍六入五成双
        num = num.toFixed(decimal);
      } else if (roundType == '3') {  // 舍入类型为无条件舍弃
        num = this.floor10(num,  -1 * decimal);
      } else if (roundType == '4') {  // 舍入类型为无条件进位
        num = this.ceil10(num,  -1 * decimal);
      }
      // 修改整数的时候不会显示0的问题
      if (num.toString().indexOf('.') == -1 && decimal != 0) {
        num += '.';
        for (var i = 0; i < decimal; i++) {
          num += '0'
        }
      }

      // 数值小数点后缺位补0
      if (num && num.toString().indexOf('.') != -1 && decimal != 0) {
        var numLength = Number((num.toString().split('.')[1]).length);
        if (numLength < decimal) {
          for (var i = numLength; i < decimal; i++) {
            num += '0'
          }
        }
      }
    }
    return num;
  }

  // If doing full row edit, then gets called when tabbing into the cell.
  focusIn(): void {
    this.cellInput.nativeElement.focus();
  }

  // If doing full row edit, then gets called when tabbing out of the cell.
  focusOut(): void {
    this.cellInput.nativeElement.blur();
  }

  // Should return the final value to the grid, the result of the editing
  getValue(): any {
    return this.formControl.value;
    // return this.initValue();
  }

  // Gets called once before editing starts, to give editor a chance to
  // cancel the editing before it even starts.
  isCancelBeforeStart(): boolean {
    return false;
  }

  // Gets called once when editing is finished (eg if enter is pressed).
  // If you return true, then the result of the edit will be ignored.
  isCancelAfterEnd(): boolean {
    return this.validationMessages.length > 0;
  }

  // Gets called once after initialised.
  // If you return true, the editor will appear in a popup
  isPopup(): boolean {
    return false;
  }

  ngOnDestroy(): void {

    // console.warn('销毁TexCellEditor:', this.name);
    this.validateForm.removeControl(this.name);
    // 当销毁时取消所有订阅
    if (this.valueSetSubscription) {
      this.valueSetSubscription.unsubscribe();
    }
  }


}
