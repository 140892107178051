import {
  Component,
  Input,
  OnInit
} from '@angular/core';

export type DwProgressGapPositionType = 'top' | 'bottom' | 'left' | 'right';
export type DwProgressStatusType = 'success' | 'exception' | 'active' | 'normal';
export type DwProgressTypeType = 'line' | 'circle' | 'dashboard';
import { isNotNil } from '../core/util/check';

@Component({
  selector           : 'dw-progress',
  preserveWhitespaces: false,
  templateUrl        : './dw-progress.component.html'
})
export class DwProgressComponent implements OnInit {
  private _gapDegree = 0;
  private _gapPosition: DwProgressGapPositionType = 'top';
  private _percent = 0;
  private _status: DwProgressStatusType = 'normal';
  private _cacheStatus: DwProgressStatusType = 'normal';
  private _strokeWidth = 8;
  private _size = 'default';
  private _type: DwProgressTypeType = 'line';
  private _format = (percent: number): string => `${percent}%`;
  trailPathStyle: { [ key: string ]: string };
  strokePathStyle: { [ key: string ]: string };
  pathString: string;
  iconClassMap;
  isStatusSet = false;
  isStrokeWidthSet = false;
  isFormatSet = false;
  isGapDegreeSet = false;
  isGapPositionSet = false;
  statusColorMap = {
    normal   : '#108ee9',
    exception: '#ff5500',
    success  : '#87d068'
  };
  @Input() dwShowInfo = true;
  @Input() dwWidth = 132;
  @Input() dwSuccessPercent = 0;

  @Input()
  set dwSize(value: string) {
    this._size = value;
    if (this.dwSize === 'small' && !this.isStrokeWidthSet) {
      this._strokeWidth = 6;
    }
  }

  get dwSize(): string {
    return this._size;
  }

  @Input()
  set dwFormat(value: (percent: number) => string) {
    if (isNotNil(value)) {
      this._format = value;
      this.isFormatSet = true;
    }
  }

  get dwFormat(): (percent: number) => string {
    return this._format;
  }

  @Input()
  set dwPercent(value: number) {
    this._percent = value;
    if (isNotNil(value)) {
      const fillAll = parseInt(value.toString(), 10) >= 100;
      if (fillAll && !this.isStatusSet) {
        this._status = 'success';
      } else {
        this._status = this._cacheStatus;
      }
      this.updatePathStyles();
      this.updateIconClassMap();
    }
  }

  get dwPercent(): number {
    return this._percent;
  }

  @Input()
  set dwStrokeWidth(value: number) {
    if (isNotNil(value)) {
      this._strokeWidth = value;
      this.isStrokeWidthSet = true;
      this.updatePathStyles();
    }
  }

  get dwStrokeWidth(): number {
    return this._strokeWidth;
  }

  @Input()
  set dwStatus(value: DwProgressStatusType) {
    if (isNotNil(value)) {
      this._status = value;
      this._cacheStatus = value;
      this.isStatusSet = true;
      this.updateIconClassMap();
    }
  }

  get dwStatus(): DwProgressStatusType {
    return this._status;
  }

  @Input()
  set dwType(value: DwProgressTypeType) {
    this._type = value;
    if (!this.isStrokeWidthSet) {
      if (this.dwType !== 'line') {
        this._strokeWidth = 6;
      }
    }
    if (this.dwType === 'dashboard') {
      if (!this.isGapPositionSet) {
        this._gapPosition = 'bottom';
      }
      if (!this.isGapDegreeSet) {
        this._gapDegree = 75;
      }
    }
    this.updateIconClassMap();
    this.updatePathStyles();
  }

  get dwType(): DwProgressTypeType {
    return this._type;
  }

  @Input()
  set dwGapDegree(value: number) {
    if (isNotNil(value)) {
      this._gapDegree = value;
      this.isGapDegreeSet = true;
      this.updatePathStyles();
    }

  }

  get dwGapDegree(): number {
    return this._gapDegree;
  }

  @Input()
  set dwGapPosition(value: DwProgressGapPositionType) {
    if (isNotNil(value)) {
      this._gapPosition = value;
      this.isGapPositionSet = true;
      this.updatePathStyles();
    }
  }

  get dwGapPosition(): DwProgressGapPositionType {
    return this._gapPosition;
  }

  get isCirCleStyle(): boolean {
    return this.dwType === 'circle' || this.dwType === 'dashboard';
  }

  updatePathStyles(): void {
    const radius = 50 - (this.dwStrokeWidth / 2);
    let beginPositionX = 0;
    let beginPositionY = -radius;
    let endPositionX = 0;
    let endPositionY = radius * -2;
    switch (this.dwGapPosition) {
      case 'left':
        beginPositionX = -radius;
        beginPositionY = 0;
        endPositionX = radius * 2;
        endPositionY = 0;
        break;
      case 'right':
        beginPositionX = radius;
        beginPositionY = 0;
        endPositionX = radius * -2;
        endPositionY = 0;
        break;
      case 'bottom':
        beginPositionY = radius;
        endPositionY = radius * 2;
        break;
      default:
    }
    this.pathString = `M 50,50 m ${beginPositionX},${beginPositionY}
     a ${radius},${radius} 0 1 1 ${endPositionX},${-endPositionY}
     a ${radius},${radius} 0 1 1 ${-endPositionX},${endPositionY}`;
    const len = Math.PI * 2 * radius;
    this.trailPathStyle = {
      strokeDasharray : `${len - this.dwGapDegree}px ${len}px`,
      strokeDashoffset: `-${this.dwGapDegree / 2}px`,
      transition      : 'stroke-dashoffset .3s ease 0s, stroke-dasharray .3s ease 0s, stroke .3s'
    };
    this.strokePathStyle = {
      strokeDasharray : `${(this.dwPercent / 100) * (len - this.dwGapDegree)}px ${len}px`,
      strokeDashoffset: `-${this.dwGapDegree / 2}px`,
      transition      : 'stroke-dashoffset .3s ease 0s, stroke-dasharray .3s ease 0s, stroke .3s, stroke-width .06s ease .3s' // eslint-disable-line
    };
  }

  updateIconClassMap(): void {
    const isCircle = (this.dwType === 'circle' || this.dwType === 'dashboard');
    this.iconClassMap = {
      'anticon-check'       : (this.dwStatus === 'success') && isCircle,
      'anticon-cross'       : (this.dwStatus === 'exception') && isCircle,
      'anticon-check-circle': (this.dwStatus === 'success') && !isCircle,
      'anticon-cross-circle': (this.dwStatus === 'exception') && !isCircle
    };
  }

  ngOnInit(): void {
    this.updatePathStyles();
    this.updateIconClassMap();
  }

}
