import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { DwButtonModule } from '../button/dw-button.module';
import { DwMenuDividerDirective } from '../menu/dw-menu-divider.directive';
import { DwMenuGroupComponent } from '../menu/dw-menu-group.component';
import { DwMenuItemDirective } from '../menu/dw-menu-item.directive';
import { DwMenuDirective } from '../menu/dw-menu.directive';
import { DwSubMenuComponent } from '../menu/dw-submenu.component';

@NgModule({
  imports     : [ CommonModule, FormsModule, DwButtonModule, OverlayModule ],
  declarations: [ DwMenuDirective, DwMenuItemDirective, DwSubMenuComponent, DwMenuDividerDirective, DwMenuGroupComponent ],
  exports     : [ DwMenuDirective, DwMenuItemDirective, DwSubMenuComponent, DwMenuDividerDirective, DwMenuGroupComponent ]
})
export class DwMenuModule {
}
