import {
  ComponentFactory,
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  Optional,
  Renderer2,
  ViewContainerRef
} from '@angular/core';
import { DwTooltipDirective } from '../tooltip/dw-tooltip.directive';
import { DwPopoverComponent } from './dw-popover.component';

@Directive({
  selector: '[dw-popover]'
})
export class DwPopoverDirective extends DwTooltipDirective {
  factory: ComponentFactory<DwPopoverComponent> = this.resolver.resolveComponentFactory(DwPopoverComponent);

  constructor(
    elementRef: ElementRef,
    hostView: ViewContainerRef,
    resolver: ComponentFactoryResolver,
    renderer: Renderer2,
    @Optional() tooltip: DwPopoverComponent) {
    super(elementRef, hostView, resolver, renderer, tooltip);
  }
}
