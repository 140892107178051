import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DwPopoverComponent } from './dw-popover.component';
import { DwPopoverDirective } from './dw-popover.directive';

@NgModule({
  entryComponents: [ DwPopoverComponent ],
  exports        : [ DwPopoverDirective, DwPopoverComponent ],
  declarations   : [ DwPopoverDirective, DwPopoverComponent ],
  imports        : [ CommonModule, OverlayModule ]
})

export class DwPopoverModule {
}
