import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { toBoolean } from '../core/util/convert';

import { MarksArray } from './dw-slider-marks.component';

@Component({
  selector           : 'dw-slider-step',
  preserveWhitespaces: false,
  templateUrl        : './dw-slider-step.component.html'
})
export class DwSliderStepComponent implements OnChanges {
  private _vertical = false;
  private _included = false;

  // Dynamic properties
  @Input() dwLowerBound: number = null;
  @Input() dwUpperBound: number = null;
  @Input() dwMarksArray: MarksArray;

  // Static properties
  @Input() dwPrefixCls: string;

  @Input()
  set dwVertical(value: boolean) { // Required
    this._vertical = toBoolean(value);
  }

  get dwVertical(): boolean {
    return this._vertical;
  }

  @Input()
  set dwIncluded(value: boolean) {
    this._included = toBoolean(value);
  }

  get dwIncluded(): boolean {
    return this._included;
  }

  // TODO: using named interface
  attrs: Array<{ id: number, value: number, offset: number, classes: { [ key: string ]: boolean }, style: object }>;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dwMarksArray) {
      this.buildAttrs();
    }
    if (changes.dwMarksArray || changes.dwLowerBound || changes.dwUpperBound) {
      this.togglePointActive();
    }
  }

  trackById(index: number, attr: { id: number, value: number, offset: number, classes: { [ key: string ]: boolean }, style: object }): number {
    return attr.id;
  }

  buildAttrs(): void {
    const orient = this.dwVertical ? 'bottom' : 'left';
    const prefixCls = this.dwPrefixCls;
    this.attrs = this.dwMarksArray.map(mark => {
      const { value, offset } = mark;
      return {
        id     : value,
        value,
        offset,
        style  : {
          [ orient ]: `${offset}%`
        },
        classes: {
          [ `${prefixCls}-dot` ]       : true,
          [ `${prefixCls}-dot-active` ]: false
        }
      };
    });
  }

  togglePointActive(): void {
    if (this.attrs && this.dwLowerBound !== null && this.dwUpperBound !== null) {
      this.attrs.forEach(attr => {
        const value = attr.value;
        const isActive = (!this.dwIncluded && value === this.dwUpperBound) ||
          (this.dwIncluded && value <= this.dwUpperBound && value >= this.dwLowerBound);
        attr.classes[ `${this.dwPrefixCls}-dot-active` ] = isActive;
      });
    }
  }

}
