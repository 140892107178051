import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  Renderer2
} from '@angular/core';

import { matchMedia } from '../core/polyfill/match-media';
import { DwUpdateHostClassService } from '../core/services/update-host-class.service';

export type DwJustify = 'start' | 'end' | 'center' | 'space-around' | 'space-between';
export type DwAlign = 'top' | 'middle' | 'bottom';
export type DwType = 'flex' | null;
export type Breakpoint = 'xxl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs';

export interface BreakpointMap {
  xs?: string;
  sm?: string;
  md?: string;
  lg?: string;
  xl?: string;
  xxl?: string;
}

const responsiveMap: BreakpointMap = {
  xs : '(max-width: 575px)',
  sm : '(min-width: 576px)',
  md : '(min-width: 768px)',
  lg : '(min-width: 992px)',
  xl : '(min-width: 1200px)',
  xxl: '(min-width: 1600px)'
};

@Component({
  selector           : 'dw-row',
  preserveWhitespaces: false,
  providers          : [ DwUpdateHostClassService ],
  templateUrl        : './dw-row.component.html'
})
export class DwRowComponent implements OnInit {
  private _gutter: number | object;
  private _type: DwType;
  private _align: DwAlign = 'top';
  private _justify: DwJustify = 'start';
  private el: HTMLElement;
  private prefixCls = 'ant-row';
  private breakPoint: Breakpoint;
  actualGutter: number;

  @Input()
  set dwType(value: DwType) {
    this._type = value;
    this.setClassMap();
  }

  get dwType(): DwType {
    return this._type;
  }

  @Input()
  set dwAlign(value: DwAlign) {
    this._align = value;
    this.setClassMap();
  }

  get dwAlign(): DwAlign {
    return this._align;
  }

  @Input()
  set dwJustify(value: DwJustify) {
    this._justify = value;
    this.setClassMap();
  }

  get dwJustify(): DwJustify {
    return this._justify;
  }

  @Input()
  get dwGutter(): number | object {
    return this._gutter;
  }

  set dwGutter(value: number | object) {
    this._gutter = value;
    this.updateGutter();
    this.setStyle();
  }

  setStyle(): void {
    this.renderer.setStyle(this.el, 'margin-left', `-${this.actualGutter / 2}px`);
    this.renderer.setStyle(this.el, 'margin-right', `-${this.actualGutter / 2}px`);
  }

  calculateGutter(): number {
    if (typeof this.dwGutter !== 'object') {
      return this.dwGutter;
    } else if (this.breakPoint && this.dwGutter[ this.breakPoint ]) {
      return this.dwGutter[ this.breakPoint ];
    } else {
      return;
    }
  }

  updateGutter(): void {
    this.actualGutter = this.calculateGutter();
  }

  @HostListener('window:resize', [ '$event' ])
  onWindowResize(e: UIEvent): void {
    this.watchMedia();
  }

  watchMedia(): void {
    Object.keys(responsiveMap).map((screen: Breakpoint) => {
      const matchBelow = matchMedia(responsiveMap[ screen ]).matches;
      if (matchBelow) {
        this.breakPoint = screen;
      }
    });
    this.updateGutter();
    this.setStyle();
  }

  /** temp solution since no method add classMap to host https://github.com/angular/angular/issues/7289*/
  setClassMap(): void {
    const classMap = {
      [ `${this.prefixCls}` ]                                 : !this.dwType,
      [ `${this.prefixCls}-${this.dwType}` ]                  : this.dwType,
      [ `${this.prefixCls}-${this.dwType}-${this.dwAlign}` ]  : this.dwType && this.dwAlign,
      [ `${this.prefixCls}-${this.dwType}-${this.dwJustify}` ]: this.dwType && this.dwJustify
    };
    this.dwUpdateHostClassService.updateHostClass(this.el, classMap);
  }

  constructor(public elementRef: ElementRef, public renderer: Renderer2, public dwUpdateHostClassService: DwUpdateHostClassService) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit(): void {
    this.setClassMap();
    this.watchMedia();
  }
}
