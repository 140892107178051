import {
  AfterContentInit,
  Component,
  ContentChildren,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  TemplateRef
} from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { toBoolean } from '../core/util/convert';

import { DwStepComponent } from './dw-step.component';

export type DwDirectionType = 'horizontal' | 'vertical';
export type DwStatusType = 'wait' | 'process' | 'finish' | 'error';
export type DwSizeType = 'default' | 'small';

@Component({
  selector           : 'dw-steps',
  preserveWhitespaces: false,
  templateUrl        : './dw-steps.component.html'
})
export class DwStepsComponent implements OnInit, OnDestroy, AfterContentInit {
  private _status: DwStatusType = 'process';
  private _current = 0;
  private _size: DwSizeType = 'default';
  private _direction: DwDirectionType = 'horizontal';
  private _startIndex = 0;
  private unsubscribe$ = new Subject<void>();

  stepsClassMap: object;
  showProcessDot = false;
  customProcessDotTemplate: TemplateRef<{ $implicit: TemplateRef<void>, status: string, index: number }>;
  @ContentChildren(DwStepComponent) steps: QueryList<DwStepComponent>;

  @Input() set dwSize(value: DwSizeType) {
    this._size = value;
    this.updateClassMap();
  }

  get dwSize(): DwSizeType {
    return this._size;
  }

  @Input()
  set dwStartIndex(value: number) {
    this._startIndex = value;
    this.updateChildrenSteps();
  }

  get dwStartIndex(): number {
    return this._startIndex;
  }

  @Input()
  set dwDirection(value: DwDirectionType) {
    this._direction = value;
    this.updateClassMap();
    this.updateChildrenSteps();
  }

  get dwDirection(): DwDirectionType {
    return this._direction;
  }

  @Input()
  set dwProgressDot(value: boolean | TemplateRef<{ $implicit: TemplateRef<void>, status: string, index: number }>) {
    if (value instanceof TemplateRef) {
      this.showProcessDot = true;
      this.customProcessDotTemplate = value;
    } else {
      this.showProcessDot = toBoolean(value);
    }
    this.updateChildrenSteps();
    this.updateClassMap();
  }

  @Input()
  set dwStatus(status: DwStatusType) {
    this._status = status;
    this.updateChildrenSteps();
  }

  get dwStatus(): DwStatusType {
    return this._status;
  }

  @Input()
  set dwCurrent(current: number) {
    this._current = current;
    this.updateChildrenSteps();
  }

  get dwCurrent(): number {
    return this._current;
  }

  updateClassMap(): void {
    this.stepsClassMap = {
      [ `ant-steps-${this.dwDirection}` ]: true,
      [ `ant-steps-label-horizontal` ]   : this.dwDirection === 'horizontal',
      [ `ant-steps-label-vertical` ]     : this.showProcessDot && (this.dwDirection === 'horizontal'),
      [ `ant-steps-dot` ]                : this.showProcessDot,
      [ 'ant-steps-small' ]              : this.dwSize === 'small'
    };
  }

  updateChildrenSteps = () => {
    if (this.steps) {
      this.steps.toArray().forEach((step, index, arr) => {
        Promise.resolve().then(() => {
          step.outStatus = this.dwStatus;
          step.showProcessDot = this.showProcessDot;
          if (this.customProcessDotTemplate) {
            step.customProcessTemplate = this.customProcessDotTemplate;
          }
          step.direction = this.dwDirection;
          step.index = index + this.dwStartIndex;
          step.currentIndex = this.dwCurrent;
          step.last = arr.length === index + 1;
          step.updateClassMap();
        });
      });
    }
  }

  ngOnInit(): void {
    this.updateClassMap();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterContentInit(): void {
    this.updateChildrenSteps();
    if (this.steps) {
       this.steps.changes.pipe(takeUntil(this.unsubscribe$)).subscribe(this.updateChildrenSteps);
    }
  }
}
