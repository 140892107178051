import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { isNotNil } from '../core/util/check';
import { DwOptionComponent } from './dw-option.component';

@Component({
  selector           : '[dw-select-top-control]',
  preserveWhitespaces: false,
  animations         : [
    trigger('tagAnimation', [
      state('*', style({ opacity: 1, transform: 'scale(1)' })),
      transition('void => *', [
        style({ opacity: 0, transform: 'scale(0)' }),
        animate('150ms linear')
      ]),
      state('void', style({ opacity: 0, transform: 'scale(0)' })),
      transition('* => void', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate('150ms linear')
      ])
    ])
  ],
  templateUrl        : './dw-select-top-control.component.html',
  host               : {
    '[class.ant-select-selection__rendered]': 'true'
  }
})
export class DwSelectTopControlComponent {
  // tslint:disable-next-line:no-any
  private _listOfSelectedValue: any[];
  private _listTemplateOfOption: DwOptionComponent[] = [];
  listOfCachedSelectedOption: DwOptionComponent[] = [];
  inputValue: string;
  isComposing = false;
  @ViewChild('inputElement') inputElement: ElementRef;
  // tslint:disable-next-line:no-any
  @Output() dwListOfSelectedValueChange = new EventEmitter<any[]>();
  @Output() dwOnSearch = new EventEmitter<{ value: string, emit: boolean }>();
  @Input() dwMode = 'default';
  @Input() dwShowSearch = false;
  @Input() dwDisabled = false;

  @Input() dwPlaceHolder: string;
  @Input() dwOpen = false;
  // tslint:disable-next-line:no-any
  @Input() compareWith: (o1: any, o2: any) => boolean;

  @Input()
  // tslint:disable-next-line:no-any
  set dwListOfSelectedValue(value: any[]) {
    this._listOfSelectedValue = value;
    this.updateListOfCachedOption();
  }

  // tslint:disable-next-line:no-any
  get dwListOfSelectedValue(): any[] {
    return this._listOfSelectedValue;
  }

  @Input()
  set dwListTemplateOfOption(value: DwOptionComponent[]) {
    this._listTemplateOfOption = value;
    this.updateListOfCachedOption();
  }

  get dwListTemplateOfOption(): DwOptionComponent[] {
    return this._listTemplateOfOption;
  }

  /** cached selected option list **/
  updateListOfCachedOption(): void {
    if (this.isSingleMode) {
      const selectedOption = this.dwListTemplateOfOption.find(o => this.compareWith(o.dwValue, this.dwListOfSelectedValue[ 0 ]));
      if (isNotNil(selectedOption)) {
        this.listOfCachedSelectedOption = [ selectedOption ];
      }
    } else {
      const listOfCachedOptionFromLatestTemplate = this.dwListTemplateOfOption.filter(o => isNotNil(this.dwListOfSelectedValue.find(v => this.compareWith(v, o.dwValue))));
      const restSelectedValue = this.dwListOfSelectedValue.filter(v => !isNotNil(listOfCachedOptionFromLatestTemplate.find(o => this.compareWith(o.dwValue, v))));
      const listOfCachedOptionFromOld = this.listOfCachedSelectedOption.filter(o => isNotNil(restSelectedValue.find(v => this.compareWith(o.dwValue, v))));
      this.listOfCachedSelectedOption = listOfCachedOptionFromLatestTemplate.concat(listOfCachedOptionFromOld);
    }
  }

  setInputValue(value: string, emit: boolean): void {
    this.inputValue = value;
    this.updateWidth();
    this.dwOnSearch.emit({ value, emit });
  }

  get isSingleMode(): boolean {
    return this.dwMode === 'default';
  }

  get isMultipleOrTags(): boolean {
    return this.dwMode === 'tags' || this.dwMode === 'multiple';
  }

  get placeHolderDisplay(): string {
    return this.inputValue || this.isComposing || this.dwListOfSelectedValue.length ? 'none' : 'block';
  }

  get selectedValueDisplay(): { [ key: string ]: string } {
    let showSelectedValue = false;
    let opacity = 1;
    if (!this.dwShowSearch) {
      showSelectedValue = true;
    } else {
      if (this.dwOpen) {
        showSelectedValue = !(this.inputValue || this.isComposing);
        if (showSelectedValue) {
          opacity = 0.4;
        }
      } else {
        showSelectedValue = true;
      }
    }
    return {
      display: showSelectedValue ? 'block' : 'none',
      opacity: `${opacity}`
    };
  }

  get singleValueLabel(): string {
    return this.getPropertyFromValue(this.dwListOfSelectedValue[ 0 ], 'dwLabel');
  }

  focusOnInput(): void {
    setTimeout(() => {
      if (this.inputElement) {
        this.inputElement.nativeElement.focus();
      }
    });
  }

  // tslint:disable-next-line:no-any
  getPropertyFromValue(value: any, prop: string): string {
    const targetOption = this.listOfCachedSelectedOption.find(item => this.compareWith(item.dwValue, value));
    return targetOption ? targetOption[ prop ] : '';
  }

  // tslint:disable-next-line:no-any
  isOptionDisplay(value: any): boolean {
    return (this.dwMode === 'tags') || !!this.getPropertyFromValue(value, 'dwLabel');
  }

  // tslint:disable-next-line:no-any
  removeValueFormSelected(value: any): void {
    if (this.dwDisabled || this.getPropertyFromValue(value, 'dwDisabled')) {
      return;
    }
    this._listOfSelectedValue = this.dwListOfSelectedValue.filter(item => item !== value);
    this.dwListOfSelectedValueChange.emit(this.dwListOfSelectedValue);
  }

  updateWidth(): void {
    if (this.isMultipleOrTags && this.inputElement) {
      if (this.inputValue || this.isComposing) {
        this.renderer.setStyle(this.inputElement.nativeElement, 'width', `${this.inputElement.nativeElement.scrollWidth}px`);
      } else {
        this.renderer.removeStyle(this.inputElement.nativeElement, 'width');
      }
    }
  }

  onKeyDownInput(e: KeyboardEvent): void {
    const keyCode = e.keyCode;
    const eventTarget = e.target as HTMLInputElement;
    if (
      this.isMultipleOrTags &&
      !eventTarget.value &&
      // BackSpace
      keyCode === 8
    ) {
      e.preventDefault();
      if (this.dwListOfSelectedValue.length) {
        this.removeValueFormSelected(this.dwListOfSelectedValue[ this.dwListOfSelectedValue.length - 1 ]);
      }
    }
  }

  constructor(private renderer: Renderer2) {

  }
}
