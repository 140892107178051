import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DwColComponent } from './dw-col.component';
import { DwColDirective } from './dw-col.directive';
import { DwRowComponent } from './dw-row.component';
import { DwRowDirective } from './dw-row.directive';

@NgModule({
  declarations: [ DwRowComponent, DwColDirective, DwColComponent, DwRowDirective ],
  exports     : [ DwRowComponent, DwColDirective, DwColComponent, DwRowDirective ],
  imports     : [ CommonModule ]
})
export class DwGridModule {
}
