import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { DwTagComponent } from './dw-tag.component';

@NgModule({
  imports     : [ CommonModule, FormsModule ],
  declarations: [
    DwTagComponent
  ],
  exports     : [
    DwTagComponent
  ]
})
export class DwTagModule {
}
