import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DwMeasureScrollbarService } from '../../services/dw-measure-scrollbar.service'
import { DwBlockScrollStrategy } from './dw-block-scroll-strategy';

@Injectable({providedIn: 'root'})
export class DwScrollStrategyOptions {
  private document: Document;
  private renderer: Renderer2;
  constructor(
    rendererFactory: RendererFactory2,
    private dwMeasureScrollbarService: DwMeasureScrollbarService,
    // tslint:disable-next-line:no-any
    @Inject(DOCUMENT) document: any
  ) {
    this.document = document;
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  block = () => new DwBlockScrollStrategy(this.document, this.renderer, this.dwMeasureScrollbarService);
}
