import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DwI18nModule } from '../i18n/dw-i18n.module';
import { DwTimePickerPanelComponent } from './dw-time-picker-panel.component';
import { DwTimePickerComponent } from './dw-time-picker.component';
import { DwTimeValueAccessorDirective } from './dw-time-value-accessor.directive';

@NgModule({
  declarations   : [
    DwTimePickerComponent,
    DwTimePickerPanelComponent,
    DwTimeValueAccessorDirective
  ],
  exports        : [
    DwTimePickerPanelComponent,
    DwTimePickerComponent
  ],
  imports        : [ CommonModule, FormsModule, DwI18nModule, OverlayModule ],
  entryComponents: []
})
export class DwTimePickerModule {
}
