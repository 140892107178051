import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DwToolTipModule } from '../tooltip/dw-tooltip.module';

import { DwSliderHandleComponent } from './dw-slider-handle.component';
import { DwSliderMarksComponent } from './dw-slider-marks.component';
import { DwSliderStepComponent } from './dw-slider-step.component';
import { DwSliderTrackComponent } from './dw-slider-track.component';
import { DwSliderComponent } from './dw-slider.component';
import { DwSliderService } from './dw-slider.service';

@NgModule({
  exports: [ DwSliderComponent, DwSliderTrackComponent, DwSliderHandleComponent, DwSliderStepComponent, DwSliderMarksComponent ],
  declarations: [ DwSliderComponent, DwSliderTrackComponent, DwSliderHandleComponent, DwSliderStepComponent, DwSliderMarksComponent ],
  imports: [ CommonModule, DwToolTipModule ],
  providers: [ DwSliderService ]
})
export class DwSliderModule { }
