import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DwAffixModule } from '../affix/dw-affix.module';
import { SCROLL_SERVICE_PROVIDER } from '../core/scroll/dw-scroll.service';

import { DwAnchorLinkComponent } from './dw-anchor-link.component';
import { DwAnchorComponent } from './dw-anchor.component';

@NgModule({
  declarations: [ DwAnchorComponent, DwAnchorLinkComponent ],
  exports     : [ DwAnchorComponent, DwAnchorLinkComponent ],
  imports     : [ CommonModule, DwAffixModule ],
  providers   : [ SCROLL_SERVICE_PROVIDER ]
})
export class DwAnchorModule {
}
