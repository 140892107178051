import { InjectionToken } from '@angular/core';

export const DW_MODAL_DEFAULT_CONFIG: DwModalConfig = {
  autoBodyPadding: true
};

export const DW_MODAL_CONFIG = new InjectionToken<DwModalConfig>('DwModalConfig', {
  providedIn: 'root',
  factory: () => DW_MODAL_DEFAULT_CONFIG // Default config
});

////////////

export interface DwModalConfig {
  autoBodyPadding: boolean; // Whether add the padding-right and overflow to body automatically to play smoothly
}
