import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DwI18nModule } from '../i18n/dw-i18n.module';
import { DwRadioModule } from '../radio/dw-radio.module';
import { DwSelectModule } from '../select/dw-select.module';
import { DwDateCellDirective, DwDateFullCellDirective, DwMonthCellDirective, DwMonthFullCellDirective } from './dw-calendar-cells';
import { DwCalendarHeaderComponent } from './dw-calendar-header.component';
import { DwCalendarComponent } from './dw-calendar.component';

@NgModule({
  declarations: [
    DwCalendarHeaderComponent,
    DwCalendarComponent,
    DwDateCellDirective,
    DwDateFullCellDirective,
    DwMonthCellDirective,
    DwMonthFullCellDirective
  ],
  exports     : [
    DwCalendarComponent,
    DwDateCellDirective,
    DwDateFullCellDirective,
    DwMonthCellDirective,
    DwMonthFullCellDirective
  ],
  imports     : [ CommonModule, FormsModule, DwI18nModule, DwRadioModule, DwSelectModule ]
})
export class DwCalendarModule { }
