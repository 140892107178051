import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DwAvatarModule } from '../avatar/dw-avatar.module';
import { DwGridModule } from '../grid/dw-grid.module';
import { DwSpinModule } from '../spin/dw-spin.module';

import { DwListItemMetaComponent } from './dw-list-item-meta.component';
import { DwListItemComponent } from './dw-list-item.component';
import { DwListComponent } from './dw-list.component';

@NgModule({
    imports:        [ CommonModule, DwSpinModule, DwGridModule, DwAvatarModule ],
    declarations:   [ DwListComponent, DwListItemComponent, DwListItemMetaComponent ],
    exports:        [ DwListComponent, DwListItemComponent, DwListItemMetaComponent ]
})
export class DwListModule {
}
