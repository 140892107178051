import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DwStepComponent } from './dw-step.component';
import { DwStepsComponent } from './dw-steps.component';

@NgModule({
  imports     : [ CommonModule ],
  exports     : [ DwStepsComponent, DwStepComponent ],
  declarations: [ DwStepsComponent, DwStepComponent ]
})
export class DwStepsModule {
}
