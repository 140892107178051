import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DwAlertComponent } from './dw-alert.component';

@NgModule({
  declarations: [ DwAlertComponent ],
  exports     : [ DwAlertComponent ],
  imports     : [ CommonModule ]
})
export class DwAlertModule {
}
