import {
  Component,
  Input
} from '@angular/core';

import { toBoolean } from '../core/util/convert';

import { DwCollapsePanelComponent } from './dw-collapse-panel.component';

@Component({
  selector   : 'dw-collapse',
  templateUrl: './dw-collapse.component.html',
  styles     : [
    `:host {
      display: block;
    }`
  ]
})
export class DwCollapseComponent {
  private _accordion = false;
  private _bordered = true;
  private listOfPanel: DwCollapsePanelComponent[] = [];

  @Input()
  set dwAccordion(value: boolean) {
    this._accordion = toBoolean(value);
  }

  get dwAccordion(): boolean {
    return this._accordion;
  }

  @Input()
  set dwBordered(value: boolean) {
    this._bordered = toBoolean(value);
  }

  get dwBordered(): boolean {
    return this._bordered;
  }

  click(collapse: DwCollapsePanelComponent): void {
    if (this.dwAccordion) {
      this.listOfPanel.forEach(item => {
        const active = collapse === item;
        if (item.dwActive !== active) {
          item.dwActive = active;
          item.dwActiveChange.emit(item.dwActive);
        }
      });
    } else {
      collapse.dwActive = !collapse.dwActive;
      collapse.dwActiveChange.emit(collapse.dwActive);
    }
  }

  addCollapse(collapse: DwCollapsePanelComponent): void {
    this.listOfPanel.push(collapse);
  }

  removeCollapse(collapse: DwCollapsePanelComponent): void {
    this.listOfPanel.splice(this.listOfPanel.indexOf(collapse), 1);
  }
}
