import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DwI18nModule } from '../i18n/dw-i18n.module';
import { DwOptionContainerComponent } from './dw-option-container.component';
import { DwOptionGroupComponent } from './dw-option-group.component';
import { DwOptionLiComponent } from './dw-option-li.component';
import { DwOptionComponent } from './dw-option.component';
import { DwOptionPipe, DwSubOptionPipe } from './dw-option.pipe';
import { DwSelectTopControlComponent } from './dw-select-top-control.component';
import { DwSelectUnselectableDirective } from './dw-select-unselectable.directive';
import { DwSelectComponent } from './dw-select.component';

@NgModule({
  imports     : [ CommonModule, FormsModule, OverlayModule, DwI18nModule ],
  declarations: [ DwOptionPipe, DwSubOptionPipe, DwOptionComponent, DwSelectComponent, DwOptionContainerComponent, DwOptionGroupComponent, DwOptionLiComponent, DwSelectTopControlComponent, DwSelectUnselectableDirective ],
  exports     : [ DwOptionComponent, DwSelectComponent, DwOptionContainerComponent, DwOptionGroupComponent, DwSelectTopControlComponent ]
})
export class DwSelectModule {
}
