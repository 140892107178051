import { Component, Input, TemplateRef, ViewChild } from '@angular/core';

import { toBoolean } from '../core/util/convert';

@Component({
  selector   : 'dw-option',
  templateUrl: './dw-option.component.html'
})
export class DwOptionComponent {
  private _disabled = false;
  private _customContent = false;
  @ViewChild(TemplateRef) template: TemplateRef<void>;
  @Input() dwLabel: string;
  // tslint:disable-next-line:no-any
  @Input() dwValue: any;

  @Input()
  set dwDisabled(value: boolean) {
    this._disabled = toBoolean(value);
  }

  get dwDisabled(): boolean {
    return this._disabled;
  }

  @Input()
  set dwCustomContent(value: boolean) {
    this._customContent = toBoolean(value);
  }

  get dwCustomContent(): boolean {
    return this._customContent;
  }
}
