import { PlatformModule } from '@angular/cdk/platform';
import { NgModule } from '@angular/core';
import { DwWaveDirective } from './dw-wave.directive';

@NgModule({
  imports     : [ PlatformModule ],
  exports     : [ DwWaveDirective ],
  declarations: [ DwWaveDirective ]
})
export class DwWaveModule { }
