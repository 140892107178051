import { CdkConnectedOverlay, ConnectedOverlayPositionChange, ConnectionPositionPair } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';

import { combineLatest, merge, BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, mapTo, takeUntil } from 'rxjs/operators';

import { dropDownAnimation } from '../core/animation/dropdown-animations';
import { DEFAULT_DROPDOWN_POSITIONS, POSITION_MAP } from '../core/overlay/overlay-position-map';
import { toBoolean } from '../core/util/convert';
import { DwMenuDirective } from '../menu/dw-menu.directive';

import { DwDropDownDirective } from './dw-dropdown.directive';

export type DwPlacement = 'bottomLeft' | 'bottomCenter' | 'bottomRight' | 'topLeft' | 'topCenter' | 'topRight';

@Component({
  selector           : 'dw-dropdown',
  preserveWhitespaces: false,
  animations         : [
    dropDownAnimation
  ],
  templateUrl        : './dw-dropdown.component.html',
  styles             : [
    `
      :host {
        position: relative;
        display: inline-block;
      }

      .ant-dropdown {
        top: 100%;
        left: 0;
        position: relative;
        width: 100%;
        margin-top: 4px;
        margin-bottom: 4px;
      }
    `
  ]
})

export class DwDropDownComponent implements OnInit, OnDestroy, AfterViewInit {
  private _clickHide = true;
  private _visible = false;
  private _disabled = false;
  private unsubscribe$ = new Subject<void>();

  @Input() hasFilterButton = false;
  triggerWidth = 0;
  placement: DwPlacement = 'bottomLeft';
  dropDownPosition: 'top' | 'center' | 'bottom' = 'bottom';
  positions: ConnectionPositionPair[] = [ ...DEFAULT_DROPDOWN_POSITIONS ];
  $subOpen = new BehaviorSubject<boolean>(false);
  $visibleChange = new Subject<boolean>();
  @ContentChild(DwDropDownDirective) dwOrigin: DwDropDownDirective;
  @ContentChild(DwMenuDirective) dwMenu: DwMenuDirective;
  @Input() dwTrigger: 'click' | 'hover' = 'hover';
  @Output() dwVisibleChange: EventEmitter<boolean> = new EventEmitter();
  @ViewChild(CdkConnectedOverlay) cdkOverlay: CdkConnectedOverlay;

  @Input()
  set dwClickHide(value: boolean) {
    this._clickHide = toBoolean(value);
  }

  get dwClickHide(): boolean {
    return this._clickHide;
  }

  @Input()
  set dwDisabled(value: boolean) {
    this._disabled = toBoolean(value);
    if (this._disabled) {
      this.renderer.setAttribute(this.dwOrigin.elementRef.nativeElement, 'disabled', '');
    } else {
      this.renderer.removeAttribute(this.dwOrigin.elementRef.nativeElement, 'disabled');
    }
  }

  get dwDisabled(): boolean {
    return this._disabled;
  }

  @Input()
  set dwVisible(value: boolean) {
    this._visible = toBoolean(value);
    /** handle dwVisible change with mouse event **/
    this.$visibleChange.next(this._visible);
  }

  get dwVisible(): boolean {
    return this._visible;
  }

  @Input()
  set dwPlacement(value: DwPlacement) {
    this.placement = value;
    this.dropDownPosition = (this.dwPlacement.indexOf('top') !== -1) ? 'top' : 'bottom';
    this.positions.unshift(POSITION_MAP[ this.placement ] as ConnectionPositionPair);
  }

  get dwPlacement(): DwPlacement {
    return this.placement;
  }

  onClickEvent(): void {
    if (this.dwTrigger === 'click') {
      this.show();
    }
  }

  onMouseEnterEvent(): void {
    if (this.dwTrigger === 'hover') {
      this.show();
    }
  }

  onMouseLeaveEvent(): void {
    if (this.dwTrigger === 'hover') {
      this.hide();
    }
  }

  hide(): void {
    this.$visibleChange.next(false);
  }

  show(): void {
    this.$visibleChange.next(true);
  }

  onPositionChange(position: ConnectedOverlayPositionChange): void {
    this.dropDownPosition = position.connectionPair.originY;
  }

  setTriggerWidth(): void {
    this.triggerWidth = this.dwOrigin.elementRef.nativeElement.getBoundingClientRect().width;
    /** should remove after https://github.com/angular/material2/pull/8765 merged **/
    if (this.cdkOverlay && this.cdkOverlay.overlayRef) {
      this.cdkOverlay.overlayRef.updateSize({
        minWidth: this.triggerWidth
      });
    }
  }

  startSubscribe(observable$: Observable<boolean>): void {
    let $pre = observable$;
    if (this.dwClickHide && this.dwMenu) {
      const $menuItemClick = this.dwMenu.dwClick.asObservable().pipe(mapTo(false));
      $pre = merge($pre, $menuItemClick);
    }
    const final$ = combineLatest($pre, this.$subOpen).pipe(map(value => value[ 0 ] || value[ 1 ]), debounceTime(50), distinctUntilChanged());
    final$.pipe(takeUntil(this.unsubscribe$)).subscribe(this.onVisibleChange);
  }

  onVisibleChange = (visible: boolean) => {
    if (visible) {
      this.setTriggerWidth();
    }
    if (this.dwVisible !== visible) {
      this.dwVisible = visible;
      this.dwVisibleChange.emit(this.dwVisible);
    }
    this.changeDetector.markForCheck();
  }

  ngOnInit(): void {
    if (this.dwMenu) {
      this.dwMenu.dwInDropDown = true;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit(): void {
    let mouse$: Observable<boolean>;
    if (this.dwTrigger === 'hover') {
      const mouseEnterOrigin$ = this.dwOrigin.$mouseenter.pipe(mapTo(true));
      const mouseLeaveOrigin$ = this.dwOrigin.$mouseleave.pipe(mapTo(false));
      mouse$ = merge(mouseLeaveOrigin$, mouseEnterOrigin$);
    }
    if (this.dwTrigger === 'click') {
      mouse$ = this.dwOrigin.$click.pipe(mapTo(true));
    }
    const observable$ = merge(this.$visibleChange, mouse$);
    this.startSubscribe(observable$);
  }

  get hasBackdrop(): boolean {
    return this.dwTrigger === 'click';
  }

  constructor(private renderer: Renderer2, protected changeDetector: ChangeDetectorRef) {
  }
}
