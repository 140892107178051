import { ObserversModule } from '@angular/cdk/observers';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DwBadgeComponent } from './dw-badge.component';

@NgModule({
  declarations: [ DwBadgeComponent ],
  exports     : [ DwBadgeComponent ],
  imports     : [ CommonModule, ObserversModule ]
})
export class DwBadgeModule {
}
