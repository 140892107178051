import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { DwButtonModule } from '../button/dw-button.module';
import { DwCheckboxModule } from '../checkbox/dw-checkbox.module';
import { DwI18nModule } from '../i18n/dw-i18n.module';
import { DwInputModule } from '../input/dw-input.module';

import { DwTransferListComponent } from './dw-transfer-list.component';
import { DwTransferSearchComponent } from './dw-transfer-search.component';
import { DwTransferComponent } from './dw-transfer.component';

@NgModule({
  imports:      [CommonModule, FormsModule, DwCheckboxModule, DwButtonModule, DwInputModule, DwI18nModule],
  declarations: [DwTransferComponent, DwTransferListComponent, DwTransferSearchComponent],
  exports:      [DwTransferComponent]
})
export class DwTransferModule { }
