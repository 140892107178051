import { Component } from '@angular/core';

@Component({
  selector   : 'dw-form-text',
  templateUrl: './dw-form-text.component.html',
  host       : {
    '[class.ant-form-text]': 'true'
  }
})
export class DwFormTextComponent {
}
