import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { DOWN_ARROW, SPACE, TAB } from '@angular/cdk/keycodes';
import { CdkConnectedOverlay, CdkOverlayOrigin, ConnectedOverlayPositionChange } from '@angular/cdk/overlay';
import {
  forwardRef,
  AfterViewInit,
  Component,
  ContentChildren,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  SimpleChange,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isNotNil } from '../core/util/check';
import { toBoolean } from '../core/util/convert';
import { DwOptionContainerComponent } from './dw-option-container.component';
import { DwOptionGroupComponent } from './dw-option-group.component';
import { DwOptionComponent } from './dw-option.component';
import { defaultFilterOption, TFilterOption } from './dw-option.pipe';
import { DwSelectTopControlComponent } from './dw-select-top-control.component';

@Component({
  selector           : 'dw-select',
  preserveWhitespaces: false,
  providers          : [
    {
      provide    : NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DwSelectComponent),
      multi      : true
    }
  ],
  animations         : [
    trigger('dropDownAnimation', [
      state('hidden', style({
        opacity: 0,
        display: 'none'
      })),
      state('bottom', style({
        opacity        : 1,
        transform      : 'scaleY(1)',
        transformOrigin: '0% 0%'
      })),
      state('top', style({
        opacity        : 1,
        transform      : 'scaleY(1)',
        transformOrigin: '0% 100%'
      })),
      transition('hidden => bottom', [
        style({
          opacity        : 0,
          transform      : 'scaleY(0.8)',
          transformOrigin: '0% 0%'
        }),
        animate('100ms cubic-bezier(0.755, 0.05, 0.855, 0.06)')
      ]),
      transition('bottom => hidden', [
        animate('100ms cubic-bezier(0.755, 0.05, 0.855, 0.06)', style({
          opacity        : 0,
          transform      : 'scaleY(0.8)',
          transformOrigin: '0% 0%'
        }))
      ]),
      transition('hidden => top', [
        style({
          opacity        : 0,
          transform      : 'scaleY(0.8)',
          transformOrigin: '0% 100%'
        }),
        animate('100ms cubic-bezier(0.755, 0.05, 0.855, 0.06)')
      ]),
      transition('top => hidden', [
        animate('100ms cubic-bezier(0.755, 0.05, 0.855, 0.06)', style({
          opacity        : 0,
          transform      : 'scaleY(0.8)',
          transformOrigin: '0% 100%'
        }))
      ])
    ])
  ],
  templateUrl        : './dw-select.component.html',
  host               : {
    '[class.ant-select]'            : 'true',
    '[class.ant-select-lg]'         : 'dwSize==="large"',
    '[class.ant-select-sm]'         : 'dwSize==="small"',
    '[class.ant-select-enabled]'    : '!dwDisabled',
    '[class.ant-select-disabled]'   : 'dwDisabled',
    '[class.ant-select-allow-clear]': 'dwAllowClear',
    '[class.ant-select-open]'       : 'dwOpen'
  },
  styles             : [ `
    .ant-select-dropdown {
      top: 100%;
      left: 0;
      position: relative;
      width: 100%;
      margin-top: 4px;
      margin-bottom: 4px;
    }
  ` ]
})
export class DwSelectComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy {
  private _disabled = false;
  private _allowClear = false;
  private _showSearch = false;
  private _open = false;
  private _placeholder: string;
  private _autoFocus = false;
  private _dropdownClassName: string;
  onChange: (value: string | string[]) => void = () => null;
  onTouched: () => void = () => null;
  dropDownPosition: 'top' | 'center' | 'bottom' = 'bottom';
  // tslint:disable-next-line:no-any
  listOfSelectedValue: any[] = [];
  listOfTemplateOption: DwOptionComponent[] = [];
  // tslint:disable-next-line:no-any
  value: any | any[];
  overlayWidth: number;
  overlayMinWidth: number;
  searchValue: string = '';
  isDestroy = true;
  isInit = false;
  dropDownClassMap;
  @ViewChild(CdkOverlayOrigin) cdkOverlayOrigin: CdkOverlayOrigin;
  @ViewChild(CdkConnectedOverlay) cdkConnectedOverlay: CdkConnectedOverlay;
  @ViewChild(DwSelectTopControlComponent) dwSelectTopControlComponent: DwSelectTopControlComponent;
  @ViewChild(DwOptionContainerComponent) dwOptionContainerComponent: DwOptionContainerComponent;
  /** should move to dw-option-container when https://github.com/angular/angular/issues/20810 resolved **/
  @ContentChildren(DwOptionComponent) listOfDwOptionComponent: QueryList<DwOptionComponent>;
  @ContentChildren(DwOptionGroupComponent) listOfDwOptionGroupComponent: QueryList<DwOptionGroupComponent>;
  @Output() dwOnSearch = new EventEmitter<string>();
  @Output() dwScrollToBottom = new EventEmitter<void>();
  @Output() dwOpenChange = new EventEmitter<boolean>();
  @Input() dwSize = 'default';
  @Input() dwServerSearch = false;
  @Input() dwMode: 'default' | 'multiple' | 'tags' = 'default';
  @Input() dwDropdownMatchSelectWidth = true;
  @Input() dwFilterOption: TFilterOption = defaultFilterOption;
  @Input() dwMaxMultipleCount = Infinity;
  @Input() dwDropdownStyle: { [ key: string ]: string; };
  @Input() dwNotFoundContent: string;
  /** https://github.com/angular/angular/pull/13349/files **/
    // tslint:disable-next-line:no-any
  @Input() compareWith = (o1: any, o2: any) => o1 === o2;

  @Input()
  set dwDropdownClassName(value: string) {
    this._dropdownClassName = value;
    this.updateDropDownClassMap();
  }

  get dwDropdownClassName(): string {
    return this._dropdownClassName;
  }

  @Input()
  set dwAutoFocus(value: boolean) {
    this._autoFocus = toBoolean(value);
    this.updateAutoFocus();
  }

  get dwAutoFocus(): boolean {
    return this._autoFocus;
  }

  @Input()
  set dwOpen(value: boolean) {
    this._open = value;
    this.handleEscBug();
    this.updateCdkConnectedOverlayStatus();
    this.updateDropDownClassMap();
    if (this.dwOpen) {
      if (this.dwSelectTopControlComponent) {
        this.dwSelectTopControlComponent.focusOnInput();
        this.dwSelectTopControlComponent.setInputValue('', true);
      }
      if (this.dwOptionContainerComponent) {
        this.dwOptionContainerComponent.scrollIntoView();
      }
      if (this.cdkConnectedOverlay && this.cdkConnectedOverlay.overlayRef) {
        this.cdkConnectedOverlay.overlayRef.updatePosition();
        const backdropElement = this.cdkConnectedOverlay.overlayRef.backdropElement;
        const parentNode = this.renderer.parentNode(backdropElement);
        const hostElement = this.cdkConnectedOverlay.overlayRef.hostElement;
        this.renderer.appendChild(parentNode, backdropElement);
        this.renderer.appendChild(parentNode, hostElement);
      }
    } else {
      if (this.dwSelectTopControlComponent) {
        this.dwSelectTopControlComponent.setInputValue('', false);
      }
      if (this.dwOptionContainerComponent) {
        this.dwOptionContainerComponent.resetActiveOption();
      }
    }
  }

  get dwOpen(): boolean {
    return this._open;
  }

  @Input()
  set dwDisabled(value: boolean) {
    this._disabled = toBoolean(value);
    if (this.dwDisabled) {
      this.closeDropDown();
    }
  }

  get dwDisabled(): boolean {
    return this._disabled;
  }

  @Input()
  set dwAllowClear(value: boolean) {
    this._allowClear = toBoolean(value);
  }

  get dwAllowClear(): boolean {
    return this._allowClear;
  }

  @Input()
  set dwShowSearch(value: boolean) {
    this._showSearch = toBoolean(value);
  }

  get dwShowSearch(): boolean {
    return this._showSearch;
  }

  @Input()
  set dwPlaceHolder(value: string) {
    this._placeholder = value;
  }

  get dwPlaceHolder(): string {
    return this._placeholder;
  }

  @HostListener('click')
  onClick(): void {
    if (!this.dwDisabled) {
      this.dwOpen = !this.dwOpen;
      this.dwOpenChange.emit(this.dwOpen);
    }
  }

  @HostListener('keydown', [ '$event' ])
  _handleKeydown(event: KeyboardEvent): void {
    if (this._disabled) { return; }

    const keyCode = event.keyCode;

    if (!this._open) {
      if (keyCode === SPACE || keyCode === DOWN_ARROW) {
        this.dwOpen = true;
        this.dwOpenChange.emit(this.dwOpen);
        event.preventDefault();
      }
    } else {
      if (keyCode === SPACE || keyCode === TAB) {
        this.dwOpen = false;
        this.dwOpenChange.emit(this.dwOpen);
        event.preventDefault();
      }
    }
  }

  updateAutoFocus(): void {
    if (this.isInit && this.dwSelectTopControlComponent.inputElement) {
      if (this.dwAutoFocus) {
        this.renderer.setAttribute(this.dwSelectTopControlComponent.inputElement.nativeElement, 'autofocus', 'autofocus');
      } else {
        this.renderer.removeAttribute(this.dwSelectTopControlComponent.inputElement.nativeElement, 'autofocus');
      }
    }
  }

  focus(): void {
    if (this.dwSelectTopControlComponent.inputElement) {
      this.dwSelectTopControlComponent.inputElement.nativeElement.focus();
    }
  }

  blur(): void {
    if (this.dwSelectTopControlComponent.inputElement) {
      this.dwSelectTopControlComponent.inputElement.nativeElement.blur();
    }
  }

  /** overlay can not be always open , reopen overlay after press esc **/
  handleEscBug(): void {
    if (this.dwOpen && this.cdkConnectedOverlay && this.cdkConnectedOverlay.overlayRef && !this.cdkConnectedOverlay.overlayRef.backdropElement) {
      this.cdkConnectedOverlay.open = true;
      this.cdkConnectedOverlay.ngOnChanges({ open: new SimpleChange(false, true, false) });
    }
  }

  onKeyDownCdkOverlayOrigin(e: KeyboardEvent): void {
    if (this.dwOptionContainerComponent) {
      this.dwOptionContainerComponent.onKeyDownUl(e);
    }
  }

  closeDropDown(): void {
    if (this.dwOpen) {
      this.onTouched();
      this.dwOpen = false;
      this.dwOpenChange.emit(this.dwOpen);
      this.blur();
    }
  }

  onPositionChange(position: ConnectedOverlayPositionChange): void {
    this.dropDownPosition = position.connectionPair.originY;
    this.updateDropDownClassMap();
  }

  onClickOptionFromOptionContainer(): void {
    if (this.isSingleMode) {
      this.closeDropDown();
    } else if (this.dwMode === 'tags') {
      this.onSearch('', true);
    }
  }

  updateCdkConnectedOverlayStatus(): void {
    if (this.isInit && this.dwOpen && this.cdkOverlayOrigin) {
      if (this.dwDropdownMatchSelectWidth) {
        this.overlayWidth = this.cdkOverlayOrigin.elementRef.nativeElement.getBoundingClientRect().width;
        this.cdkConnectedOverlay.overlayRef.updateSize({ width: this.overlayWidth });
      } else {
        this.overlayMinWidth = this.cdkOverlayOrigin.elementRef.nativeElement.getBoundingClientRect().width;
        this.cdkConnectedOverlay.overlayRef.updateSize({ minWidth: this.overlayMinWidth });
      }

    }
    this.updateCdkConnectedOverlayPositions();
    if (this.cdkConnectedOverlay && this.cdkConnectedOverlay.overlayRef && this.cdkConnectedOverlay.overlayRef.backdropElement) {
      if (this.dwOpen) {
        this.renderer.removeStyle(this.cdkConnectedOverlay.overlayRef.backdropElement, 'display');
      } else {
        this.renderer.setStyle(this.cdkConnectedOverlay.overlayRef.backdropElement, 'display', 'none');
      }
    }
  }

  updateCdkConnectedOverlayPositions(): void {
    /** wait for input size change **/
    setTimeout(() => this.cdkConnectedOverlay.overlayRef.updatePosition(), 160);
  }

  get isSingleMode(): boolean {
    return this.dwMode === 'default';
  }

  get isMultipleOrTags(): boolean {
    return this.dwMode === 'tags' || this.dwMode === 'multiple';
  }

  /** option container dwListOfSelectedValueChange -> update ngModel **/
  // tslint:disable-next-line:no-any
  updateListOfSelectedValueFromOptionContainer(value: any[]): void {
    this.clearSearchValue();
    this.updateFromSelectedList(value);
  }

  /** option container dwListOfSelectedValueChange -> update ngModel **/
  // tslint:disable-next-line:no-any
  updateListOfSelectedValueFromTopControl(value: any[]): void {
    this.clearSearchValue();
    this.updateFromSelectedList(value);
  }

  // tslint:disable-next-line:no-any
  updateFromSelectedList(value: any[]): void {
    let modelValue;
    if (this.isSingleMode) {
      if (value.length) {
        modelValue = value[ 0 ];
      }
    } else {
      modelValue = value;
      this.updateCdkConnectedOverlayPositions();
    }
    this.updateNgModel(value, modelValue);
  }

  onSearch(value: string, emit: boolean): void {
    if (emit && (this.searchValue !== value)) {
      this.dwOnSearch.emit(value);
      this.searchValue = value;
    }
  }

  clearNgModel(): void {
    if (this.isSingleMode) {
      this.updateNgModel([], null);
    } else {
      this.updateNgModel([], []);
    }
  }

  // tslint:disable-next-line:no-any
  updateNgModel(list: any[], value: string | string[]): void {
    this.listOfSelectedValue = list;
    if (value !== this.value) {
      this.value = value;
      this.onChange(this.value);
    }
  }

  listOfTemplateOptionChange(value: DwOptionComponent[]): void {
    this.listOfTemplateOption = value;
  }

  updateDropDownClassMap(): void {
    this.dropDownClassMap = {
      [ 'ant-select-dropdown' ]                     : true,
      [ `ant-select-dropdown--single` ]             : this.isSingleMode,
      [ `ant-select-dropdown--multiple` ]           : this.isMultipleOrTags,
      [ `ant-select-dropdown-placement-bottomLeft` ]: this.dropDownPosition === 'bottom',
      [ `ant-select-dropdown-placement-topLeft` ]   : this.dropDownPosition === 'top',
      [ `${this.dwDropdownClassName}` ]             : !!this.dwDropdownClassName
    };
  }

  onClearSelection(e: MouseEvent): void {
    // TODO: should not clear disabled option ?
    e.stopPropagation();
    this.clearNgModel();
  }

  clearSearchValue(): void {
    if (this.isSingleMode) {
      this.dwSelectTopControlComponent.setInputValue('', false);
    } else {
      this.dwSelectTopControlComponent.setInputValue('', false);
    }
  }

  constructor(private renderer: Renderer2) {
  }

  /** update ngModel -> update listOfSelectedValue **/
  // tslint:disable-next-line:no-any
  writeValue(value: any | any[]): void {
    this.value = value;
    if (isNotNil(value)) {
      if (Array.isArray(value)) {
        this.listOfSelectedValue = value;
      } else {
        this.listOfSelectedValue = [ value ];
      }
    } else {
      this.listOfSelectedValue = [];
    }
  }

  registerOnChange(fn: (value: string | string[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.dwDisabled = isDisabled;
  }

  ngOnInit(): void {
    this.isDestroy = false;
    this.updateDropDownClassMap();
  }

  ngAfterViewInit(): void {
    this.isInit = true;
    Promise.resolve().then(() => this.updateCdkConnectedOverlayStatus());
  }

  ngOnDestroy(): void {
    this.isDestroy = true;
  }
}
