import {
  AnimationEvent
} from '@angular/animations';
import {
  CdkConnectedOverlay,
  CdkOverlayOrigin,
  ConnectedOverlayPositionChange,
  ConnectionPositionPair
} from '@angular/cdk/overlay';
import {
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { fadeAnimation } from '../core/animation/fade-animations';
import { DEFAULT_4_POSITIONS, POSITION_MAP } from '../core/overlay/overlay-position-map';
import { isNotNil } from '../core/util/check';
import { toBoolean } from '../core/util/convert';

@Component({
  selector           : 'dw-tooltip',
  animations         : [ fadeAnimation ],
  templateUrl        : './dw-tooltip.component.html',
  preserveWhitespaces: false,
  styles             : [ `
    .ant-tooltip {
      position: relative;
    }
  ` ]
})
export class DwToolTipComponent {
  _hasBackdrop = false;
  _prefix = 'ant-tooltip-placement';
  _positions: ConnectionPositionPair[] = [ ...DEFAULT_4_POSITIONS ];
  _classMap = {};
  _placement = 'top';
  _trigger = 'hover';
  _content: string | TemplateRef<void>;
  overlayOrigin: CdkOverlayOrigin;
  isContentString: boolean;
  isTitleString: boolean;
  visibleSource = new BehaviorSubject<boolean>(false);
  visible$: Observable<boolean> = this.visibleSource.asObservable();
  @ContentChild('dwTemplate') _title: string | TemplateRef<void>;
  @ViewChild('overlay') overlay: CdkConnectedOverlay;
  @Output() dwVisibleChange: EventEmitter<boolean> = new EventEmitter();

  @Input() dwOverlayClassName = '';
  @Input() dwOverlayStyle: { [ key: string ]: string } = {};
  @Input() dwMouseEnterDelay = 0.15; // Unit: second
  @Input() dwMouseLeaveDelay = 0.1; // Unit: second
  @Input()
  set dwContent(value: string | TemplateRef<void>) {
    this.isContentString = !(value instanceof TemplateRef);
    this._content = value;
  }

  get dwContent(): string | TemplateRef<void> {
    return this._content;
  }

  @Input()
  set dwTitle(value: string | TemplateRef<void>) {
    this.isTitleString = !(value instanceof TemplateRef);
    this._title = value;
  }

  get dwTitle(): string | TemplateRef<void> {
    return this._title;
  }

  @Input()
  set dwVisible(value: boolean) {
    const visible = toBoolean(value);
    if (this.visibleSource.value !== visible) {
      this.visibleSource.next(visible);
      this.dwVisibleChange.emit(visible);
    }
  }

  get dwVisible(): boolean {
    return this.visibleSource.value;
  }

  @Input()
  set dwTrigger(value: string) {
    this._trigger = value;
    this._hasBackdrop = this._trigger === 'click';
  }

  get dwTrigger(): string {
    return this._trigger;
  }

  @Input()
  set dwPlacement(value: string) {
    if (value !== this._placement) {
      this._placement = value;
      this._positions.unshift(POSITION_MAP[ this.dwPlacement ] as ConnectionPositionPair);
    }
  }

  get dwPlacement(): string {
    return this._placement;
  }

  // Manually force updating current overlay's position
  updatePosition(): void {
    if (this.overlay && this.overlay.overlayRef) {
      this.overlay.overlayRef.updatePosition();
    }
  }

  onPositionChange($event: ConnectedOverlayPositionChange): void {
    for (const key in POSITION_MAP) {
      if (JSON.stringify($event.connectionPair) === JSON.stringify(POSITION_MAP[ key ])) {
        this.dwPlacement = key;
        break;
      }
    }
    this.setClassMap();
    /** TODO may cause performance problem */
    this.cdr.detectChanges();
  }

  show(): void {
    if (!this.isContentEmpty()) {
      this.dwVisible = true;
    }
  }

  hide(): void {
    this.dwVisible = false;
  }

  _afterVisibilityAnimation(e: AnimationEvent): void {
    if (e.toState === 'false' && !this.dwVisible) {
      this.dwVisibleChange.emit(false);
    }
    if (e.toState === 'true' && this.dwVisible) {
      this.dwVisibleChange.emit(true);
    }
  }

  setClassMap(): void {
    this._classMap = {
      [ this.dwOverlayClassName ]             : true,
      [ `${this._prefix}-${this._placement}` ]: true
    };
  }

  setOverlayOrigin(origin: CdkOverlayOrigin): void {
    this.overlayOrigin = origin;
  }

  constructor(public cdr: ChangeDetectorRef) {
  }

  isContentEmpty(): boolean {
    return this.isTitleString ? (this.dwTitle === '' || !isNotNil(this.dwTitle)) : false; // Pity, can't detect whether dwTemplate is empty due to can't get it's content before shown up
  }
}
