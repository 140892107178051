import { Component } from '@angular/core';

@Component({
  selector   : 'dw-form-split',
  templateUrl: './dw-form-split.component.html',
  host       : {
    '[class.ant-form-split]': 'true'
  }
})
export class DwFormSplitComponent {
}
