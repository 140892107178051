import {
  Component,
  ContentChild,
  HostBinding,
  Input,
  TemplateRef
} from '@angular/core';

import { toBoolean } from '../core/util/convert';

import { DwCardTabComponent } from './dw-card-tab.component';

@Component({
  selector           : 'dw-card',
  preserveWhitespaces: false,
  templateUrl        : './dw-card.component.html',
  styles             : [ `
    :host {
      display: block;
      position: relative;
    }
  ` ],
  host               : {
    '[class.ant-card]'        : 'true',
    '[class.ant-card-loading]': 'dwLoading'
  }
})
export class DwCardComponent {
  private _bordered = true;
  private _loading = false;
  private _hoverable = false;
  private _title: string | TemplateRef<void>;
  private _extra: string | TemplateRef<void>;
  isTitleString: boolean;
  isExtraString: boolean;
  @ContentChild(DwCardTabComponent) tab: DwCardTabComponent;
  @Input() dwBodyStyle: { [ key: string ]: string };
  @Input() dwCover: TemplateRef<void>;
  @Input() dwActions: Array<TemplateRef<void>> = [];
  @Input() dwType: string;

  @Input()
  set dwTitle(value: string | TemplateRef<void>) {
    this.isTitleString = !(value instanceof TemplateRef);
    this._title = value;
  }

  get dwTitle(): string | TemplateRef<void> {
    return this._title;
  }

  @Input()
  set dwExtra(value: string | TemplateRef<void>) {
    this.isExtraString = !(value instanceof TemplateRef);
    this._extra = value;
  }

  get dwExtra(): string | TemplateRef<void> {
    return this._extra;
  }

  @HostBinding('class.ant-card-type-inner')
  get isInner(): boolean {
    return this.dwType === 'inner';
  }

  @HostBinding('class.ant-card-contain-tabs')
  get isTabs(): boolean {
    return !!this.tab;
  }

  @Input()
  @HostBinding('class.ant-card-bordered')
  set dwBordered(value: boolean) {
    this._bordered = toBoolean(value);
  }

  get dwBordered(): boolean {
    return this._bordered;
  }

  @Input()
  set dwLoading(value: boolean) {
    this._loading = toBoolean(value);
  }

  get dwLoading(): boolean {
    return this._loading;
  }

  @Input()
  @HostBinding('class.ant-card-hoverable')
  set dwHoverable(value: boolean) {
    this._hoverable = toBoolean(value);
  }

  get dwHoverable(): boolean {
    return this._hoverable;
  }
}
