import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { distinctUntilChanged, throttleTime } from 'rxjs/operators';
import { DwScrollService } from '../core/scroll/dw-scroll.service';
import { toBoolean, toNumber } from '../core/util/convert';

import { DwAnchorLinkComponent } from './dw-anchor-link.component';

interface Section {
  comp: DwAnchorLinkComponent;
  top: number;
}

const sharpMatcherRegx = /#([^#]+)$/;

@Component({
  selector           : 'dw-anchor',
  preserveWhitespaces: false,
  templateUrl        : './dw-anchor.component.html',
  changeDetection    : ChangeDetectionStrategy.OnPush
})
export class DwAnchorComponent implements OnDestroy, AfterViewInit {

  private links: DwAnchorLinkComponent[] = [];
  private animating = false;
  private target: Element = null;
  scroll$: Subscription = null;
  visible = false;
  wrapperStyle: {} = { 'max-height': '100vh' };
  @ViewChild('wrap') private wrap: ElementRef;
  @ViewChild('ink') private ink: ElementRef;

  // region: fields

  private _affix: boolean = true;

  @Input()
  set dwAffix(value: boolean) {
    this._affix = toBoolean(value);
  }

  get dwAffix(): boolean {
    return this._affix;
  }

  private _bounds: number = 5;

  @Input()
  set dwBounds(value: number) {
    this._bounds = toNumber(value, 5);
  }

  get dwBounds(): number {
    return this._bounds;
  }

  private _offsetTop: number;

  @Input()
  set dwOffsetTop(value: number) {
    this._offsetTop = toNumber(value, 0);
    this.wrapperStyle = {
      'max-height': `calc(100vh - ${this._offsetTop}px)`
    };
  }

  get dwOffsetTop(): number {
    return this._offsetTop;
  }

  private _showInkInFixed: boolean = false;

  @Input()
  set dwShowInkInFixed(value: boolean) {
    this._showInkInFixed = toBoolean(value);
  }

  get dwShowInkInFixed(): boolean {
    return this._showInkInFixed;
  }

  @Input()
  set dwTarget(el: Element) {
    this.target = el;
    this.registerScrollEvent();
  }

  @Output() dwClick: EventEmitter<string> = new EventEmitter();

  @Output() dwScroll: EventEmitter<DwAnchorLinkComponent> = new EventEmitter();

  // endregion

  /* tslint:disable-next-line:no-any */
  constructor(private scrollSrv: DwScrollService, @Inject(DOCUMENT) private doc: any, private cd: ChangeDetectorRef) {
  }

  registerLink(link: DwAnchorLinkComponent): void {
    this.links.push(link);
  }

  unregisterLink(link: DwAnchorLinkComponent): void {
    this.links.splice(this.links.indexOf(link), 1);
  }

  private getTarget(): Element | Window {
    return this.target || window;
  }

  ngAfterViewInit(): void {
    this.registerScrollEvent();
  }

  ngOnDestroy(): void {
    this.removeListen();
  }

  private registerScrollEvent(): void {
    this.removeListen();
    this.scroll$ = fromEvent(this.getTarget(), 'scroll').pipe(throttleTime(50), distinctUntilChanged())
    .subscribe(e => this.handleScroll());
    // ç±äºé¡µé¢å·æ°æ¶æ»å¨æ¡ä½ç½®çè®°å¿
    // åç½®å¨domæªæ¸²æå®æï¼å¯¼è´è®¡ç®ä¸æ­£ç¡®
    setTimeout(() => this.handleScroll());
  }

  private removeListen(): void {
    if (this.scroll$) {
      this.scroll$.unsubscribe();
    }
  }

  private getOffsetTop(element: HTMLElement): number {
    if (!element || !element.getClientRects().length) {
      return 0;
    }
    const rect = element.getBoundingClientRect();
    if (!rect.width && !rect.height) {
      return rect.top;
    }
    return rect.top - element.ownerDocument.documentElement.clientTop;
  }

  handleScroll(): void {
    if (this.animating) {
      return;
    }

    const sections: Section[] = [];
    const scope = (this.dwOffsetTop || 0) + this.dwBounds;
    this.links.forEach(comp => {
      const sharpLinkMatch = sharpMatcherRegx.exec(comp.dwHref.toString());
      if (!sharpLinkMatch) {
        return;
      }
      const target = this.doc.getElementById(sharpLinkMatch[ 1 ]);
      if (target && this.getOffsetTop(target) < scope) {
        const top = this.getOffsetTop(target);
        sections.push({
          top,
          comp
        });
      }
    });

    this.visible = !!sections.length;
    if (!this.visible) {
      this.clearActive();
      this.cd.detectChanges();
    } else {
      const maxSection = sections.reduce((prev, curr) => curr.top > prev.top ? curr : prev);
      this.handleActive(maxSection.comp);
    }
  }

  private clearActive(): void {
    this.links.forEach(i => i.active = false);
  }

  private handleActive(comp: DwAnchorLinkComponent): void {
    this.clearActive();

    comp.active = true;
    this.cd.detectChanges();

    const linkNode = (comp.el.nativeElement as HTMLDivElement).querySelector('.ant-anchor-link-title') as HTMLElement;
    this.ink.nativeElement.style.top = `${linkNode.offsetTop + linkNode.clientHeight / 2 - 4.5}px`;

    this.dwScroll.emit(comp);
  }

  handleScrollTo(linkComp: DwAnchorLinkComponent): void {
    const el = this.doc.querySelector(linkComp.dwHref);
    if (!el) {
      return;
    }

    this.animating = true;
    const containerScrollTop = this.scrollSrv.getScroll(this.getTarget());
    const elOffsetTop = this.scrollSrv.getOffset(el).top;
    const targetScrollTop = containerScrollTop + elOffsetTop - (this.dwOffsetTop || 0);
    this.scrollSrv.scrollTo(this.getTarget(), targetScrollTop, null, () => {
      this.animating = false;
      this.handleActive(linkComp);
    });
    this.dwClick.emit(linkComp.dwHref);
  }

}
