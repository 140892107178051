import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DwDrawerComponent } from './dw-drawer.component';
import { DwDrawerService } from './dw-drawer.service';

@NgModule({
  imports        : [ CommonModule, OverlayModule, PortalModule ],
  exports        : [ DwDrawerComponent ],
  declarations   : [ DwDrawerComponent ],
  entryComponents: [ DwDrawerComponent ],
  providers      : [ DwDrawerService ]
})
export class DwDrawerModule {
}
