import { ObserversModule } from '@angular/cdk/observers';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { DwCheckboxGroupComponent } from './dw-checkbox-group.component';
import { DwCheckboxWrapperComponent } from './dw-checkbox-wrapper.component';
import { DwCheckboxComponent } from './dw-checkbox.component';

@NgModule({
  imports     : [ CommonModule, FormsModule, ObserversModule ],
  declarations: [
    DwCheckboxComponent,
    DwCheckboxGroupComponent,
    DwCheckboxWrapperComponent
  ],
  exports     : [
    DwCheckboxComponent,
    DwCheckboxGroupComponent,
    DwCheckboxWrapperComponent
  ]
})
export class DwCheckboxModule {
}
