import {
  Directive
} from '@angular/core';

import { DwUpdateHostClassService } from '../core/services/update-host-class.service';

import { DwRowComponent } from './dw-row.component';

@Directive({
  selector : '[dw-row]',
  providers: [ DwUpdateHostClassService ]
})
export class DwRowDirective extends DwRowComponent {
}
