import { Directive, Host, Optional } from '@angular/core';
import { DwTableComponent } from './dw-table.component';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'tbody',
  host    : {
    '[class.ant-table-tbody]': 'dwTableComponent'
  }
})

export class DwTbodyDirective {
  constructor(@Host() @Optional() public dwTableComponent: DwTableComponent) {
  }
}
