/** get some code from https://github.com/angular/material2 */

import { DOCUMENT } from '@angular/common';
import {
  AfterContentChecked,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DwUpdateHostClassService } from '../core/services/update-host-class.service';
import { isNotNil } from '../core/util/check';
import { toNumber } from '../core/util/convert';

import { DwTabComponent } from './dw-tab.component';
import { DwTabsNavComponent } from './dw-tabs-nav.component';

export interface DwAnimatedInterface {
  inkBar: boolean;
  tabPane: boolean;
}

export class DwTabChangeEvent {
  index: number;
  tab: DwTabComponent;
}

export type DwTabPosition = 'top' | 'bottom' | 'left' | 'right';
export type DwTabPositionMode = 'horizontal' | 'vertical';
export type DwTabType = 'line' | 'card';

@Component({
  selector           : 'dw-tabset',
  preserveWhitespaces: false,
  providers          : [ DwUpdateHostClassService ],
  templateUrl        : './dw-tabset.component.html',
  host               : {
    '(scroll)': 'onScroll($event)'
  },
  styles             : [ `
    :host {
      display: block;
    }
  ` ]
})
export class DwTabSetComponent implements AfterContentChecked, OnInit, AfterViewInit {
  private _tabPosition: DwTabPosition = 'top';
  private _indexToSelect: number | null = 0;
  private _selectedIndex: number | null = null;
  private _type: DwTabType = 'line';
  private _size = 'default';
  private _animated: DwAnimatedInterface | boolean = true;
  el: HTMLElement;
  prefixCls = 'ant-tabs';
  tabPositionMode: DwTabPositionMode = 'horizontal';
  inkBarAnimated = true;
  tabPaneAnimated = true;
  isViewInit = false;
  listOfDwTabComponent: DwTabComponent[] = [];
  @Input() dwTabBarExtraContent: TemplateRef<void>;
  @ViewChild(DwTabsNavComponent) dwTabsNavComponent: DwTabsNavComponent;
  @ViewChild('tabContent') tabContent: ElementRef;
  @Input() dwShowPagination = true;
  @Input() dwHideAll = false;
  @Input() dwTabBarGutter: number;
  @Input() dwTabBarStyle: { [ key: string ]: string };
  @Output() dwOnNextClick = new EventEmitter<void>();
  @Output() dwOnPrevClick = new EventEmitter<void>();

  @Input()
  set dwAnimated(value: DwAnimatedInterface | boolean) {
    this._animated = value;
    this.setClassMap();
    this.inkBarAnimated = (this.dwAnimated === true) || ((this.dwAnimated as DwAnimatedInterface).inkBar === true);
    this.tabPaneAnimated = (this.dwAnimated === true) || ((this.dwAnimated as DwAnimatedInterface).tabPane === true);
  }

  get dwAnimated(): DwAnimatedInterface | boolean {
    return this._animated;
  }

  @Input()
  set dwSelectedIndex(value: number | null) {
    this._indexToSelect = toNumber(value, null);
  }

  get dwSelectedIndex(): number | null {
    return this._selectedIndex;
  }

  @Output()
  get dwSelectedIndexChange(): Observable<number> {
    return this.dwSelectChange.pipe(map(event => event.index));
  }

  @Output() dwSelectChange: EventEmitter<DwTabChangeEvent> = new EventEmitter<DwTabChangeEvent>(true);

  @Input() set dwSize(value: string) {
    this._size = value;
    this.setClassMap();
  }

  get dwSize(): string {
    return this._size;
  }

  @Input()
  set dwTabPosition(value: DwTabPosition) {
    if (this._tabPosition === value) {
      return;
    }
    this._tabPosition = value;
    if ((this._tabPosition === 'top') || (this._tabPosition === 'bottom')) {
      this.tabPositionMode = 'horizontal';
    } else {
      this.tabPositionMode = 'vertical';
    }
    this.setPosition(value);
    this.setClassMap();
  }

  get dwTabPosition(): DwTabPosition {
    return this._tabPosition;
  }

  @Input()
  set dwType(value: DwTabType) {
    if (this._type === value) {
      return;
    }
    this._type = value;
    if (this._type === 'card') {
      this.dwAnimated = false;
    }
    this.setClassMap();
  }

  get dwType(): DwTabType {
    return this._type;
  }

  setPosition(value: DwTabPosition): void {
    if (this.isViewInit) {
      if (value === 'bottom') {
        this.renderer.insertBefore(this.el, this.tabContent.nativeElement, this.dwTabsNavComponent.elementRef.nativeElement);
      } else {
        this.renderer.insertBefore(this.el, this.dwTabsNavComponent.elementRef.nativeElement, this.tabContent.nativeElement);
      }
    }

  }

  setClassMap(): void {
    const classMap = {
      [ this.prefixCls ]                           : true,
      [ `${this.prefixCls}-vertical` ]             : (this.dwTabPosition === 'left') || (this.dwTabPosition === 'right'),
      [ `${this.prefixCls}-${this.dwTabPosition}` ]: this.dwTabPosition,
      [ `${this.prefixCls}-no-animation` ]         : (this.dwAnimated === false) || ((this.dwAnimated as DwAnimatedInterface).tabPane === false),
      [ `${this.prefixCls}-${this.dwType}` ]       : this.dwType,
      [ `${this.prefixCls}-large` ]                : this.dwSize === 'large',
      [ `${this.prefixCls}-small` ]                : this.dwSize === 'small'
    };
    this.dwUpdateHostClassService.updateHostClass(this.el, classMap);
  }

  clickLabel(index: number, disabled: boolean): void {
    if (!disabled) {
      this.dwSelectedIndex = index;
      this.listOfDwTabComponent[ index ].dwClick.emit();
    }
  }

  ngOnInit(): void {
    this.setClassMap();
  }

  ngAfterContentChecked(): void {
    // Clamp the next selected index to the bounds of 0 and the tabs length. Note the `|| 0`, which
    // ensures that values like NaN can't get through and which would otherwise throw the
    // component into an infinite loop (since Math.max(NaN, 0) === NaN).
    const indexToSelect = this._indexToSelect =
      Math.min(this.listOfDwTabComponent.length - 1, Math.max(this._indexToSelect || 0, 0));

    // If there is a change in selected index, emit a change event. Should not trigger if
    // the selected index has not yet been initialized.
    if (this._selectedIndex !== indexToSelect && isNotNil(this._selectedIndex)) {
      this.dwSelectChange.emit(this.createChangeEvent(indexToSelect));
    }

    // Setup the position for each tab and optionally setup an origin on the next selected tab.
    this.listOfDwTabComponent.forEach((tab: DwTabComponent, index: number) => {
      tab.position = index - indexToSelect;
      // If there is already a selected tab, then set up an origin for the next selected tab
      // if it doesn't have one already.
      if (isNotNil(this._selectedIndex) && tab.position === 0 && !tab.origin) {
        tab.origin = indexToSelect - this._selectedIndex;
      }
    });
    this._selectedIndex = indexToSelect;
  }

  createChangeEvent(index: number): DwTabChangeEvent {
    const event = new DwTabChangeEvent();
    event.index = index;
    if (this.listOfDwTabComponent && this.listOfDwTabComponent.length) {
      event.tab = this.listOfDwTabComponent[ index ];
      this.listOfDwTabComponent.forEach((item, i) => {
        if (i !== index) {
          item.dwDeselect.emit();
        }
      });
      event.tab.dwSelect.emit();
    }
    return event;
  }

  addTab(value: DwTabComponent): void {
    this.listOfDwTabComponent.push(value);
  }

  removeTab(value: DwTabComponent): void {
    this.listOfDwTabComponent.splice(this.listOfDwTabComponent.indexOf(value), 1);
  }

  // From https://github.com/react-component/tabs/blob/master/src/Tabs.js
  // Prevent focus to make the Tabs scroll offset
  onScroll($event: Event): void {
    const target: Element = $event.target as Element;
    if (target.scrollLeft > 0) {
      target.scrollLeft = 0;
      if (this.document && this.document.activeElement) {
        (this.document.activeElement as HTMLElement).blur();
      }
    }
  }

  // tslint:disable-next-line:no-any
  constructor(private renderer: Renderer2, private dwUpdateHostClassService: DwUpdateHostClassService, private elementRef: ElementRef, @Optional() @Inject(DOCUMENT) private document: any) {
    this.el = this.elementRef.nativeElement;
  }

  ngAfterViewInit(): void {
    this.isViewInit = true;
    this.setPosition(this.dwTabPosition);
  }

}
