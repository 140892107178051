import { Component, Input } from '@angular/core';
import { DwUpdateHostClassService } from '../core/services/update-host-class.service';
import { toBoolean } from '../core/util/convert';
import { DwColComponent } from '../grid/dw-col.component';

@Component({
  selector           : 'dw-form-label',
  providers          : [ DwUpdateHostClassService ],
  preserveWhitespaces: false,
  templateUrl        : './dw-form-label.component.html',
  host               : {
    '[class.ant-form-item-label]': 'true'
  }
})
export class DwFormLabelComponent extends DwColComponent {
  @Input() dwFor: string;
  private _required = false;

  @Input()
  set dwRequired(value: boolean) {
    this._required = toBoolean(value);
  }

  get dwRequired(): boolean {
    return this._required;
  }
}
