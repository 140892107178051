import { ObserversModule } from '@angular/cdk/observers';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DwSpinComponent } from './dw-spin.component';

@NgModule({
  exports     : [ DwSpinComponent ],
  declarations: [ DwSpinComponent ],
  imports     : [ CommonModule, ObserversModule ]
})
export class DwSpinModule {
}
