import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DwDividerComponent } from './dw-divider.component';

@NgModule({
  imports     : [ CommonModule ],
  declarations: [ DwDividerComponent ],
  exports     : [ DwDividerComponent ]
})
export class DwDividerModule {
}
