import { Component } from '@angular/core';

@Component({
  selector   : 'dw-form-extra',
  templateUrl: './dw-form-extra.component.html',
  host       : {
    '[class.ant-form-extra]': 'true'
  },
  styles     : [ `:host {
    display: block;
  }` ]
})
export class DwFormExtraComponent {
}
