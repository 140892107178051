import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DwTimelineItemComponent } from './dw-timeline-item.component';
import { DwTimelineComponent } from './dw-timeline.component';

@NgModule({
  declarations: [ DwTimelineItemComponent, DwTimelineComponent ],
  exports     : [ DwTimelineItemComponent, DwTimelineComponent ],
  imports     : [ CommonModule ]
})
export class DwTimelineModule {
}
