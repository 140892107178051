import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { DwCheckboxModule } from '../checkbox/dw-checkbox.module';
import { DwDropDownModule } from '../dropdown/dw-dropdown.module';
import { DwI18nModule } from '../i18n/dw-i18n.module';
import { DwMenuModule } from '../menu/dw-menu.module';
import { DwPaginationModule } from '../pagination/dw-pagination.module';
import { DwRadioModule } from '../radio/dw-radio.module';
import { DwSpinModule } from '../spin/dw-spin.module';

import { DwTableComponent } from './dw-table.component';
import { DwTbodyDirective } from './dw-tbody.directive';
import { DwTdComponent } from './dw-td.component';
import { DwThComponent } from './dw-th.component';
import { DwTheadComponent } from './dw-thead.component';
import { DwTrDirective } from './dw-tr.directive';

@NgModule({
  declarations: [ DwTableComponent, DwThComponent, DwTdComponent, DwTheadComponent, DwTbodyDirective, DwTrDirective ],
  exports     : [ DwTableComponent, DwThComponent, DwTdComponent, DwTheadComponent, DwTbodyDirective, DwTrDirective ],
  imports     : [ DwMenuModule, FormsModule, DwRadioModule, DwCheckboxModule, DwDropDownModule, CommonModule, DwPaginationModule, DwSpinModule, DwI18nModule ]
})
export class DwTableModule {
}
