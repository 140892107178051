import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DwSkeletonComponent } from './dw-skeleton.component';

@NgModule({
    declarations: [ DwSkeletonComponent ],
    imports: [ CommonModule ],
    exports: [ DwSkeletonComponent ]
})
export class DwSkeletonModule {}
