import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Host,
  Input,
  OnDestroy,
  Optional,
  Output,
  QueryList,
  TemplateRef,
  ViewChild
} from '@angular/core';

import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { toBoolean } from '../core/util/convert';
import { DwThComponent } from './dw-th.component';

import { DwTableComponent } from './dw-table.component';

@Component({
  // tslint:disable-next-line:component-selector
  selector   : 'thead:not(.ant-table-thead)',
  templateUrl: './dw-thead.component.html'
})
export class DwTheadComponent implements AfterContentInit, OnDestroy {
  private _singleSort = false;
  private unsubscribe$ = new Subject<void>();

  @ViewChild('contentTemplate') template: TemplateRef<void>;
  @ContentChildren(DwThComponent, { descendants: true }) listOfDwThComponent: QueryList<DwThComponent>;
  @Output() dwSortChange = new EventEmitter<{ key: string, value: string }>();

  @Input()
  set dwSingleSort(value: boolean) {
    this._singleSort = toBoolean(value);
  }

  get dwSingleSort(): boolean {
    return this._singleSort;
  }

  constructor(@Host() @Optional() public dwTableComponent: DwTableComponent) {
    if (this.dwTableComponent) {
      this.dwTableComponent.dwTheadComponent = this;
    }
  }

  ngAfterContentInit(): void {
    let sortChange = new Subject<{ key: string, value: string }>().asObservable();
    const listOfTh = this.listOfDwThComponent.toArray();
    const sortChangeArray = listOfTh.map(th => th.dwSortChangeWithKey);
    if (sortChangeArray.length) {
      sortChangeArray.forEach(sort => {
        sortChange = merge(sort.asObservable(), sortChange);
      });
    }
    sortChange.pipe(takeUntil(this.unsubscribe$)).subscribe(data => {
      this.dwSortChange.emit(data);
      if (this.dwSingleSort) {
        listOfTh.forEach(th => th.dwSort = (th.dwSortKey === data.key ? th.dwSort : null));
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
