import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  Component, ElementRef, EventEmitter, HostListener,
  Input, NgZone,
  OnChanges,
  OnInit, Output, Renderer2,
  SimpleChanges,
  TemplateRef, ViewChild
} from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { InputBoolean } from '../core/util/convert';
import { DwFormatBeforeDropEvent, DwFormatEmitEvent } from '../tree/interface';
import { DwTreeNode } from './dw-tree-node';
import { isCheckDisabled } from './dw-tree-util';
import { DwTreeService } from './dw-tree.service';

@Component({
  selector           : 'dw-tree-node',
  templateUrl        : './dw-tree-node.component.html',
  preserveWhitespaces: false,
  animations         : [
    trigger('nodeState', [
      state('inactive', style({
        opacity: '0',
        height : '0',
        display: 'none'
      })),
      state('active', style({
        opacity: '1',
        height : '*'
      })),
      transition('inactive => active', animate('100ms ease-in')),
      transition('active => inactive', animate('100ms ease-out'))
    ])
  ]
})

export class DwTreeNodeComponent implements OnInit, OnChanges {
  @ViewChild('dragElement') dragElement: ElementRef;

  @Input() @InputBoolean() dwShowLine: boolean;
  @Input() @InputBoolean() dwShowExpand: boolean;
  @Input() @InputBoolean() dwDraggable: boolean;
  @Input() @InputBoolean() dwMultiple: boolean;
  @Input() @InputBoolean() dwCheckable: boolean;
  @Input() @InputBoolean() dwAsyncData: boolean;
  @Input() @InputBoolean() dwCheckStrictly: boolean;
  @Input() dwTreeTemplate: TemplateRef<void>;
  @Input() dwBeforeDrop: (confirm: DwFormatBeforeDropEvent) => Observable<boolean>;

  @Input()
  set dwTreeNode(value: DwTreeNode) {
    // add to checked list & selected list
    if (value.isChecked) {
      this.dwTreeService.setCheckedNodeList(value);
    }
    // add select list
    if (value.isSelected) {
      this.dwTreeService.setSelectedNodeList(value, this.dwMultiple);
    }
    if (!value.isLeaf) {
      this.dwTreeService.setExpandedNodeList(value);
    }
    this._dwTreeNode = value;
  }

  get dwTreeNode(): DwTreeNode {
    return this._dwTreeNode;
  }

  /**
   * @deprecated use
   * dwExpandAll instead
   */
  @Input()
  set dwDefaultExpandAll(value: boolean) {
    this._dwExpandAll = value;
    if (value && this.dwTreeNode && !this.dwTreeNode.isLeaf) {
      this.dwTreeNode.setExpanded(true);
      this.dwTreeService.setExpandedNodeList(this.dwTreeNode);
    }
  }

  get dwDefaultExpandAll(): boolean {
    return this._dwExpandAll;
  }

  // default set
  @Input()
  set dwExpandAll(value: boolean) {
    this._dwExpandAll = value;
    if (value && this.dwTreeNode && !this.dwTreeNode.isLeaf) {
      this.dwTreeNode.setExpanded(true);
      this.dwTreeService.setExpandedNodeList(this.dwTreeNode);
    }
  }

  get dwExpandAll(): boolean {
    return this._dwExpandAll;
  }

  @Input()
  set dwSearchValue(value: string) {
    this.highlightKeys = [];
    if (value && this.dwTreeNode.title.includes(value)) {
      this.dwTreeNode.isMatched = true;
      // match the search value
      const index = this.dwTreeNode.title.indexOf(value);
      this.highlightKeys.push(this.dwTreeNode.title.slice(0, index));
      this.highlightKeys.push(this.dwTreeNode.title.slice(index + value.length, this.dwTreeNode.title.length));
    } else {
      // close the node if title does't contain search value
      this.dwTreeNode.isMatched = false;
    }
    this._searchValue = value;
  }

  get dwSearchValue(): string {
    return this._searchValue;
  }

  // Output
  @Output() clickNode: EventEmitter<DwFormatEmitEvent> = new EventEmitter();
  @Output() dblClick: EventEmitter<DwFormatEmitEvent> = new EventEmitter();
  @Output() contextMenu: EventEmitter<DwFormatEmitEvent> = new EventEmitter();
  @Output() clickCheckBox: EventEmitter<DwFormatEmitEvent> = new EventEmitter();
  @Output() clickExpand: EventEmitter<DwFormatEmitEvent> = new EventEmitter();
  @Output() dwDragStart: EventEmitter<DwFormatEmitEvent> = new EventEmitter();
  @Output() dwDragEnter: EventEmitter<DwFormatEmitEvent> = new EventEmitter();
  @Output() dwDragOver: EventEmitter<DwFormatEmitEvent> = new EventEmitter();
  @Output() dwDragLeave: EventEmitter<DwFormatEmitEvent> = new EventEmitter();
  @Output() dwDrop: EventEmitter<DwFormatEmitEvent> = new EventEmitter();
  @Output() dwDragEnd: EventEmitter<DwFormatEmitEvent> = new EventEmitter();

  // default var
  prefixCls = 'ant-tree';
  highlightKeys = [];
  dwNodeClass = {};
  dwNodeSwitcherClass = {};
  dwNodeContentClass = {};
  dwNodeContentIconClass = {};
  dwNodeContentLoadingClass = {};
  dwNodeChildrenClass = {};

  /**
   * drag var
   */
  dragPos = 2;
  dragPosClass: object = {
    '0' : 'drag-over',
    '1' : 'drag-over-gap-bottom',
    '-1': 'drag-over-gap-top'
  };

  /**
   * default set
   */
  _dwTreeNode: DwTreeNode;
  _searchValue = '';
  _dwExpandAll = false;

  get canDraggable(): boolean | null {
    return (this.dwDraggable && this.dwTreeNode && !this.dwTreeNode.isDisabled) ? true : null;
  }

  get isSwitcherOpen(): boolean {
    return (this.dwTreeNode.isExpanded && !this.dwTreeNode.isLeaf);
  }

  get isSwitcherClose(): boolean {
    return (!this.dwTreeNode.isExpanded && !this.dwTreeNode.isLeaf);
  }

  /**
   * reset node class
   */
  setClassMap(): void {
    this.dwNodeClass = {
      [ `${this.prefixCls}-treenode-disabled` ]: this.dwTreeNode.isDisabled
    };
    this.dwNodeSwitcherClass = {
      [ `${this.prefixCls}-switcher` ]     : true,
      [ `${this.prefixCls}-switcher-noop` ]: this.dwTreeNode.isLeaf
    };
    this.dwNodeContentClass = {
      [ `${this.prefixCls}-node-content-wrapper` ]: true
    };
    this.dwNodeContentIconClass = {
      [ `${this.prefixCls}-iconEle` ]        : true,
      [ `${this.prefixCls}-icon__customize` ]: true
    };
    this.dwNodeContentLoadingClass = {
      [ `${this.prefixCls}-iconEle` ]: true
    };
    this.dwNodeChildrenClass = {
      [ `${this.prefixCls}-child-tree` ]     : true,
      [ `${this.prefixCls}-child-tree-open` ]: true
    };
  }

  /**
   * click node to select, 200ms to dbl click
   */
  @HostListener('click', [ '$event' ])
  dwClick(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    if (this.dwTreeNode.isSelectable) {
      this.dwTreeService.setNodeActive(this.dwTreeNode, this.dwMultiple);
    }
    this.clickNode.emit(this.dwTreeService.formatEvent('click', this.dwTreeNode, event));
  }

  @HostListener('dblclick', [ '$event' ])
  dwDblClick(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.dblClick.emit(this.dwTreeService.formatEvent('dblclick', this.dwTreeNode, event));
  }

  /**
   * @param event
   */
  @HostListener('contextmenu', [ '$event' ])
  dwContextMenu(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.contextMenu.emit(this.dwTreeService.formatEvent('contextmenu', this.dwTreeNode, event));
  }

  /**
   * collapse node
   * @param event
   */
  _clickExpand(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    if (!this.dwTreeNode.isLoading && !this.dwTreeNode.isLeaf) {
      // set async state
      if (this.dwAsyncData && this.dwTreeNode.getChildren().length === 0 && !this.dwTreeNode.isExpanded) {
        this.dwTreeNode.isLoading = true;
      }
      this.dwTreeNode.setExpanded(!this.dwTreeNode.isExpanded);
      this.dwTreeService.setExpandedNodeList(this.dwTreeNode);
      this.clickExpand.emit(this.dwTreeService.formatEvent('expand', this.dwTreeNode, event));
    }
  }

  /**
   * check node
   * @param event
   */
  _clickCheckBox(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    // return if node is disabled
    if (isCheckDisabled(this.dwTreeNode)) {
      return;
    }
    this.dwTreeNode.setChecked(!this.dwTreeNode.isChecked);
    this.dwTreeService.setCheckedNodeList(this.dwTreeNode);
    if (!this.dwCheckStrictly) {
      this.dwTreeService.conduct(this.dwTreeNode);
    }
    this.clickCheckBox.emit(this.dwTreeService.formatEvent('check', this.dwTreeNode, event));
  }

  /**
   * drag event
   * @param e
   */
  clearDragClass(): void {
    const dragClass = [ 'drag-over-gap-top', 'drag-over-gap-bottom', 'drag-over' ];
    dragClass.forEach(e => {
      this.renderer.removeClass(this.dragElement.nativeElement, e);
    });
  }

  handleDragStart(e: DragEvent): void {
    e.stopPropagation();
    try {
      // ie throw error
      // firefox-need-it
      e.dataTransfer.setData('text/plain', '');
    } catch (error) {
      // empty
    }
    this.dwTreeService.setSelectedNode(this.dwTreeNode);
    this.dwTreeNode.setExpanded(false);
    this.dwDragStart.emit(this.dwTreeService.formatEvent('dragstart', null, e));
  }

  handleDragEnter(e: DragEvent): void {
    e.preventDefault();
    e.stopPropagation();
    // reset position
    this.dragPos = 2;
    this.ngZone.run(() => {
      if ((this.dwTreeNode !== this.dwTreeService.getSelectedNode()) && !this.dwTreeNode.isLeaf) {
        this.dwTreeNode.setExpanded(true);
      }
    });
    this.dwDragEnter.emit(this.dwTreeService.formatEvent('dragenter', this.dwTreeNode, e));
  }

  handleDragOver(e: DragEvent): void {
    e.preventDefault();
    e.stopPropagation();
    const dropPosition = this.dwTreeService.calcDropPosition(e);
    if (this.dragPos !== dropPosition) {
      this.clearDragClass();
      this.dragPos = dropPosition;
      // leaf node will pass
      if (!(this.dragPos === 0 && this.dwTreeNode.isLeaf)) {
        this.renderer.addClass(this.dragElement.nativeElement, this.dragPosClass[ this.dragPos ]);
      }
    }
    this.dwDragOver.emit(this.dwTreeService.formatEvent('dragover', this.dwTreeNode, e));
  }

  handleDragLeave(e: DragEvent): void {
    e.stopPropagation();
    this.ngZone.run(() => {
      this.clearDragClass();
    });
    this.dwDragLeave.emit(this.dwTreeService.formatEvent('dragleave', this.dwTreeNode, e));
  }

  handleDragDrop(e: DragEvent): void {
    e.preventDefault();
    e.stopPropagation();
    this.ngZone.run(() => {
      this.clearDragClass();
      if (this.dwTreeService.getSelectedNode() === this.dwTreeNode) {
        return;
      } else if (this.dragPos === 0 && this.dwTreeNode.isLeaf) {
        return;
      }
      // pass if node is leafNo
      if (this.dwBeforeDrop) {
        this.dwBeforeDrop({
          dragNode: this.dwTreeService.getSelectedNode(),
          node    : this.dwTreeNode,
          pos     : this.dragPos
        }).subscribe((canDrop: boolean) => {
          if (canDrop) {
            this.dwTreeService.dropAndApply(this.dwTreeNode, this.dragPos);
          }
          this.dwDrop.emit(this.dwTreeService.formatEvent('drop', this.dwTreeNode, e));
          this.dwDragEnd.emit(this.dwTreeService.formatEvent('dragend', this.dwTreeNode, e));
        });
      } else if (this.dwTreeNode) {
        this.dwTreeService.dropAndApply(this.dwTreeNode, this.dragPos);
        this.dwDrop.emit(this.dwTreeService.formatEvent('drop', this.dwTreeNode, e));
      }
    });
  }

  handleDragEnd(e: DragEvent): void {
    e.stopPropagation();
    this.ngZone.run(() => {
      // if user do not custom beforeDrop
      if (!this.dwBeforeDrop) {
        this.dwTreeService.setSelectedNode(null);
        this.dwDragEnd.emit(this.dwTreeService.formatEvent('dragend', this.dwTreeNode, e));
      }
    });
  }

  constructor(private dwTreeService: DwTreeService, private ngZone: NgZone, private renderer: Renderer2, private elRef: ElementRef) {
    ngZone.runOutsideAngular(() => {
      fromEvent(this.elRef.nativeElement, 'dragstart').subscribe((e: DragEvent) => this.handleDragStart(e));
      fromEvent(this.elRef.nativeElement, 'dragenter').subscribe((e: DragEvent) => this.handleDragEnter(e));
      fromEvent(this.elRef.nativeElement, 'dragover').subscribe((e: DragEvent) => this.handleDragOver(e));
      fromEvent(this.elRef.nativeElement, 'dragleave').subscribe((e: DragEvent) => this.handleDragLeave(e));
      fromEvent(this.elRef.nativeElement, 'drop').subscribe((e: DragEvent) => this.handleDragDrop(e));
      fromEvent(this.elRef.nativeElement, 'dragend').subscribe((e: DragEvent) => this.handleDragEnd(e));
    });
  }

  ngOnInit(): void {
    this.setClassMap();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setClassMap();
  }
}
