import { Component, EventEmitter, Output } from '@angular/core';

import { DwCheckboxComponent } from './dw-checkbox.component';

@Component({
  selector           : 'dw-checkbox-wrapper',
  preserveWhitespaces: false,
  templateUrl        : './dw-checkbox-wrapper.component.html',
  host               : {
    '[class.ant-checkbox-group]': 'true'
  }
})
export class DwCheckboxWrapperComponent {
  @Output() dwOnChange = new EventEmitter<string[]>();
  private checkboxList: DwCheckboxComponent[] = [];

  addCheckbox(value: DwCheckboxComponent): void {
    this.checkboxList.push(value);
  }

  removeCheckbox(value: DwCheckboxComponent): void {
    this.checkboxList.splice(this.checkboxList.indexOf(value), 1);
  }

  outputValue(): string[] {
    const checkedList = this.checkboxList.filter(item => item.dwChecked);
    return checkedList.map(item => item.dwValue);
  }

  onChange(): void {
    this.dwOnChange.emit(this.outputValue());
  }
}
