import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { DwRadioButtonComponent } from './dw-radio-button.component';
import { DwRadioGroupComponent } from './dw-radio-group.component';
import { DwRadioComponent } from './dw-radio.component';

@NgModule({
  imports     : [ CommonModule, FormsModule ],
  exports     : [ DwRadioComponent, DwRadioButtonComponent, DwRadioGroupComponent ],
  declarations: [ DwRadioComponent, DwRadioButtonComponent, DwRadioGroupComponent ]
})
export class DwRadioModule {
}
