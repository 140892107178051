import { ScrollStrategy } from '@angular/cdk/overlay';
import { Renderer2 } from '@angular/core';
import { DwMeasureScrollbarService } from '../../services/dw-measure-scrollbar.service'

export class DwBlockScrollStrategy implements ScrollStrategy {

  constructor(private document: Document, private renderer: Renderer2, private dwMeasureScrollbarService: DwMeasureScrollbarService) {
  }

  attach(): void {}

  enable(): void {
    this.renderer.setStyle(document.body, 'overflow', 'hidden');
    this.renderer.setStyle(this.document.body, 'padding-right', `${this.dwMeasureScrollbarService.scrollBarWidth}px`);

  }

  disable(): void {
    this.renderer.removeStyle(document.body, 'overflow');
    this.renderer.removeStyle(document.body, 'padding-right');
  }

}
