import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2
} from '@angular/core';

import { isNotNil } from '../core/util/check';
import { toBoolean } from '../core/util/convert';

@Component({
  // tslint:disable-next-line:component-selector
  selector   : 'td:not(.dw-disable-td)',
  templateUrl: './dw-td.component.html'
})
export class DwTdComponent {
  private _showExpand = false;
  private _indentSize: number;
  private _expand = false;
  private _showCheckbox = false;
  isIndentSizeSet = false;
  el: HTMLElement;
  @Input() dwChecked = false;
  @Input() dwDisabled = false;
  @Input() dwIndeterminate = false;
  @Output() dwCheckedChange = new EventEmitter<boolean>();
  @Output() dwExpandChange = new EventEmitter<boolean>();

  @Input()
  set dwIndentSize(value: number) {
    this._indentSize = value;
    this.isIndentSizeSet = isNotNil(value);
    this.updateExpandIconClass();
  }

  get dwIndentSize(): number {
    return this._indentSize;
  }

  @Input()
  set dwExpand(value: boolean) {
    this._expand = toBoolean(value);
  }

  get dwExpand(): boolean {
    return this._expand;
  }

  @Input()
  set dwShowExpand(value: boolean) {
    this._showExpand = toBoolean(value);
    this.updateExpandIconClass();
  }

  get dwShowExpand(): boolean {
    return this._showExpand;
  }

  @Input()
  set dwShowCheckbox(value: boolean) {
    this._showCheckbox = toBoolean(value);
    if (this._showCheckbox) {
      this.renderer.addClass(this.el, 'ant-table-selection-column');
    } else {
      this.renderer.removeClass(this.el, 'ant-table-selection-column');
    }
  }

  get dwShowCheckbox(): boolean {
    return this._showCheckbox;
  }

  @Input()
  set dwLeft(value: string) {
    if (isNotNil(value)) {
      this.renderer.addClass(this.el, 'ant-table-td-left-sticky');
      this.renderer.setStyle(this.el, 'left', value);
    } else {
      this.renderer.removeClass(this.el, 'ant-table-td-left-sticky');
      this.renderer.removeStyle(this.el, 'left');
    }
  }

  @Input()
  set dwRight(value: string) {
    if (isNotNil(value)) {
      this.renderer.addClass(this.el, 'ant-table-td-right-sticky');
      this.renderer.setStyle(this.el, 'right', value);
    } else {
      this.renderer.removeClass(this.el, 'ant-table-td-right-sticky');
      this.renderer.removeStyle(this.el, 'right');
    }
  }

  updateExpandIconClass(): void {
    if (this.dwShowExpand && !this.isIndentSizeSet) {
      this.renderer.addClass(this.el, 'ant-table-row-expand-icon-cell');
    } else {
      this.renderer.removeClass(this.el, 'ant-table-row-expand-icon-cell');
    }
  }

  expandChange(): void {
    this.dwExpand = !this.dwExpand;
    this.dwExpandChange.emit(this.dwExpand);
  }

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
    this.el = this.elementRef.nativeElement;
  }
}
