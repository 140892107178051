import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SCROLL_SERVICE_PROVIDER } from '../core/scroll/dw-scroll.service';

import { DwAffixComponent } from './dw-affix.component';

@NgModule({
  declarations: [ DwAffixComponent ],
  exports     : [ DwAffixComponent ],
  imports     : [ CommonModule ],
  providers   : [ SCROLL_SERVICE_PROVIDER ]
})
export class DwAffixModule {
}
