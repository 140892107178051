import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DwCollapsePanelComponent } from './dw-collapse-panel.component';
import { DwCollapseComponent } from './dw-collapse.component';

@NgModule({
  declarations: [ DwCollapsePanelComponent, DwCollapseComponent ],
  exports     : [ DwCollapsePanelComponent, DwCollapseComponent ],
  imports     : [ CommonModule ]
})

export class DwCollapseModule {
}
