/* tslint:disable:no-any */
import { Pipe, PipeTransform, QueryList } from '@angular/core';
import { DwOptionGroupComponent } from './dw-option-group.component';
import { DwOptionComponent } from './dw-option.component';

export type TFilterOption = (input?: string, option?: DwOptionComponent) => boolean;

// TODO: can not dynamic change pipe pure yet
@Pipe({ name: 'dwFilterOptionPipe' })
export class DwOptionPipe implements PipeTransform {
  transform(options: DwOptionComponent[] | QueryList<DwOptionComponent>, input: string, filterOption: TFilterOption, serverSearch: boolean): DwOptionComponent[] | QueryList<DwOptionComponent> {
    if (serverSearch || !input) {
      return options;
    } else {
      return (options as DwOptionComponent[]).filter(o => filterOption(input, o));
    }
  }
}

@Pipe({ name: 'dwSubFilterOptionPipe' })
export class DwSubOptionPipe implements PipeTransform {
  transform(groups: DwOptionGroupComponent[] | QueryList<DwOptionGroupComponent>, input: string, filterOption: TFilterOption, serverSearch: boolean): DwOptionGroupComponent[] | QueryList<DwOptionGroupComponent> {
    if (serverSearch || !input) {
      return groups;
    } else {
      return (groups as DwOptionGroupComponent[]).filter(g => {
        return g.listOfDwOptionComponent.some(o => filterOption(input, o));
      });
    }
  }
}

export function defaultFilterOption(input: string, option: DwOptionComponent): boolean {
  if (option && option.dwLabel) {
    return option.dwLabel.toLowerCase().indexOf(input.toLowerCase()) > -1;
  } else {
    return false;
  }
}
