import { Component, Inject, Optional } from '@angular/core';

import { DwMessageConfig, DW_MESSAGE_CONFIG, DW_MESSAGE_DEFAULT_CONFIG } from './dw-message-config';
import { DwMessageDataFilled, DwMessageDataOptions } from './dw-message.definitions';

@Component({
  selector           : 'dw-message-container',
  preserveWhitespaces: false,
  templateUrl        : './dw-message-container.component.html'
})
export class DwMessageContainerComponent {
  messages: DwMessageDataFilled[] = [];
  config: DwMessageConfig = {};

  constructor(@Optional() @Inject(DW_MESSAGE_DEFAULT_CONFIG) defaultConfig: DwMessageConfig,
              @Optional() @Inject(DW_MESSAGE_CONFIG) config: DwMessageConfig) {
    this.setConfig({ ...defaultConfig, ...config });
  }

  setConfig(config: DwMessageConfig): void {
    this.config = { ...this.config, ...config };
  }

  // Create a new message
  createMessage(message: DwMessageDataFilled): void {
    if (this.messages.length >= this.config.dwMaxStack) {
      this.messages.splice(0, 1);
    }
    message.options = this._mergeMessageOptions(message.options);
    this.messages.push(message);
  }

  // Remove a message by messageId
  removeMessage(messageId: string): void {
    this.messages.some((message, index) => {
      if (message.messageId === messageId) {
        this.messages.splice(index, 1);
        return true;
      }
    });
  }

  // Remove all messages
  removeMessageAll(): void {
    this.messages = [];
  }

  // Merge default options and cutom message options
  protected _mergeMessageOptions(options: DwMessageDataOptions): DwMessageDataOptions {
    const defaultOptions: DwMessageDataOptions = {
      dwDuration    : this.config.dwDuration,
      dwAnimate     : this.config.dwAnimate,
      dwPauseOnHover: this.config.dwPauseOnHover
    };
    return { ...defaultOptions, ...options };
  }
}
