import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DW_MESSAGE_DEFAULT_CONFIG_PROVIDER } from './dw-message-config';
import { DwMessageContainerComponent } from './dw-message-container.component';
import { DwMessageComponent } from './dw-message.component';
import { DwMessageService } from './dw-message.service';

@NgModule({
  imports: [ CommonModule, OverlayModule ],
  declarations: [ DwMessageContainerComponent, DwMessageComponent ],
  providers: [ DW_MESSAGE_DEFAULT_CONFIG_PROVIDER, DwMessageService ],
  entryComponents: [ DwMessageContainerComponent ]
})
export class DwMessageModule { }
