import { Component, ElementRef, Input, ViewChild } from '@angular/core';

export type DwButtonGroupSize = 'small' | 'large' | 'default' ;

@Component({
  selector           : 'dw-button-group',
  preserveWhitespaces: false,
  templateUrl        : './dw-button-group.component.html'
})
export class DwButtonGroupComponent {
  private _size: DwButtonGroupSize;
  private prefixCls = 'ant-btn-group';
  private sizeMap = { large: 'lg', small: 'sm' };
  classMap = {
    [ this.prefixCls ]                                    : true,
    [ `${this.prefixCls}-${this.sizeMap[ this.dwSize ]}` ]: this.sizeMap[ this.dwSize ]
  };
  @ViewChild('groupWrapper') groupWrapper: ElementRef;

  @Input()
  get dwSize(): DwButtonGroupSize {
    return this._size;
  }

  set dwSize(value: DwButtonGroupSize) {
    this._size = value;
    this.classMap = {
      [ this.prefixCls ]                                    : true,
      [ `${this.prefixCls}-${this.sizeMap[ this.dwSize ]}` ]: this.sizeMap[ this.dwSize ]
    };
  }
}
