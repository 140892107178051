import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DwAvatarComponent } from './dw-avatar.component';

@NgModule({
  declarations: [ DwAvatarComponent ],
  exports     : [ DwAvatarComponent ],
  imports     : [ CommonModule ]
})
export class DwAvatarModule {
}
