import { Component, ContentChildren, Input, QueryList, TemplateRef } from '@angular/core';

import { DwListItemMetaComponent } from './dw-list-item-meta.component';

@Component({
  selector           : 'dw-list-item',
  templateUrl        : './dw-list-item.component.html',
  preserveWhitespaces: false,
  host               : {
    '[class.ant-list-item]': 'true'
  }
})
export class DwListItemComponent {
  @Input() dwActions: Array<TemplateRef<void>> = [];
  @ContentChildren(DwListItemMetaComponent) metas: QueryList<DwListItemMetaComponent>;

  isCon = false;
  conStr = '';
  conTpl: TemplateRef<void>;

  @Input()
  set dwContent(value: string | TemplateRef<void>) {
    if (value instanceof TemplateRef) {
      this.conStr = null;
      this.conTpl = value;
    } else {
      this.conStr = value;
    }

    this.isCon = !!value;
  }

  @Input() dwExtra: TemplateRef<void>;
}
