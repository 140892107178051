import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DwTreeNodeComponent } from './dw-tree-node.component';
import { DwTreeComponent } from './dw-tree.component';

@NgModule({
  imports     : [
    CommonModule
  ],
  declarations: [
    DwTreeComponent,
    DwTreeNodeComponent
  ],
  exports     : [
    DwTreeComponent,
    DwTreeNodeComponent
  ]
})

export class DwTreeModule {

}
