import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild
} from '@angular/core';

import { DwUpdateHostClassService } from '../core/services/update-host-class.service';

export type DwAvatarShape = 'square' | 'circle';
export type DwAvatarSize = 'small' | 'large' | 'default';

@Component({
  selector           : 'dw-avatar',
  templateUrl        : './dw-avatar.component.html',
  providers          : [ DwUpdateHostClassService ],
  preserveWhitespaces: false,
  changeDetection    : ChangeDetectionStrategy.OnPush
})
export class DwAvatarComponent implements OnChanges {
  private el: HTMLElement;
  private prefixCls = 'ant-avatar';
  private sizeMap = { large: 'lg', small: 'sm' };
  hasText: boolean = false;
  hasSrc: boolean = true;
  hasIcon: boolean = false;
  textStyles: {};

  @ViewChild('textEl') textEl: ElementRef;

  @Input() dwShape: DwAvatarShape = 'circle';

  @Input() dwSize: DwAvatarSize = 'default';

  @Input() dwText: string;

  @Input() dwSrc: string;

  @Input() dwIcon: string;

  setClass(): this {
    const classMap = {
      [ this.prefixCls ]                                    : true,
      [ `${this.prefixCls}-${this.sizeMap[ this.dwSize ]}` ]: this.sizeMap[ this.dwSize ],
      [ `${this.prefixCls}-${this.dwShape}` ]               : this.dwShape,
      [ `${this.prefixCls}-icon` ]                          : this.dwIcon,
      [ `${this.prefixCls}-image` ]                         : this.dwSrc
    };
    this.updateHostClassService.updateHostClass(this.el, classMap);
    this.cd.detectChanges();
    return this;
  }

  imgError(): void {
    this.hasSrc = false;
    this.hasIcon = false;
    this.hasText = false;
    if (this.dwIcon) {
      this.hasIcon = true;
    } else if (this.dwText) {
      this.hasText = true;
    }
    this.setClass().notifyCalc();
  }

  private calcStringSize(): void {
    if (!this.hasText) {
      return;
    }

    const childrenWidth = this.textEl.nativeElement.offsetWidth;
    const avatarWidth = this.el.getBoundingClientRect().width;
    const scale = avatarWidth - 8 < childrenWidth ? (avatarWidth - 8) / childrenWidth : 1;
    if (scale === 1) {
      this.textStyles = {};
    } else {
      this.textStyles = {
        transform: `scale(${scale})`,
        position : 'absolute',
        display  : 'inline-block',
        left     : `calc(50% - ${Math.round(childrenWidth / 2)}px)`
      };
    }
    this.cd.detectChanges();
  }

  private notifyCalc(): this {
    // If use ngAfterViewChecked, always demands more computations, so......
    setTimeout(() => {
      this.calcStringSize();
    });
    return this;
  }

  constructor(elementRef: ElementRef, private cd: ChangeDetectorRef, private updateHostClassService: DwUpdateHostClassService) {
    this.el = elementRef.nativeElement;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.hasText = !this.dwSrc && !!this.dwText;
    this.hasIcon = !this.dwSrc && !!this.dwIcon;
    this.hasSrc = !!this.dwSrc;

    this.setClass().notifyCalc();
  }
}
