import {
  Directive
} from '@angular/core';

import { DwUpdateHostClassService } from '../core/services/update-host-class.service';

import { DwColComponent } from './dw-col.component';

@Directive({
  selector : '[dw-col]',
  providers: [ DwUpdateHostClassService ]
})
export class DwColDirective extends DwColComponent {
}
