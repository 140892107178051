import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { DwAutocompleteOptgroupComponent } from './dw-autocomplete-optgroup.component';
import { DwAutocompleteOptionComponent } from './dw-autocomplete-option.component';
import { DwAutocompleteTriggerDirective } from './dw-autocomplete-trigger.directive';
import { DwAutocompleteComponent } from './dw-autocomplete.component';

@NgModule({
  declarations: [DwAutocompleteComponent, DwAutocompleteOptionComponent, DwAutocompleteTriggerDirective, DwAutocompleteOptgroupComponent],
  exports: [DwAutocompleteComponent, DwAutocompleteOptionComponent, DwAutocompleteTriggerDirective, DwAutocompleteOptgroupComponent],
  imports: [CommonModule, OverlayModule, FormsModule]
})
export class DwAutocompleteModule {
}
