import {
  AfterContentInit,
  Component,
  ContentChildren,
  ElementRef,
  HostBinding,
  Input,
  QueryList,
  TemplateRef
} from '@angular/core';
import { toBoolean } from '../core/util/convert';

import { DwInputDirective } from './dw-input.directive';
// tslint:disable-next-line:no-any
export type TInputGroupIconClass = string | string[] | Set<string> | { [ klass: string ]: any; };
export type DwInputGroupSizeType = 'large' | 'default' | 'small';

@Component({
  selector           : 'dw-input-group',
  preserveWhitespaces: false,
  templateUrl        : './dw-input-group.component.html'
})

export class DwInputGroupComponent implements AfterContentInit {
  private _addOnBefore: string | TemplateRef<void> = '';
  private _addOnAfter: string | TemplateRef<void> = '';
  private _prefix: string | TemplateRef<void> = '';
  private _suffix: string | TemplateRef<void> = '';
  private _size: DwInputGroupSizeType = 'default';
  private _compact = false;
  private _search = false;
  isAddOnBeforeString: boolean;
  isAddOnAfterString: boolean;
  isPrefixString: boolean;
  isSuffixString: boolean;
  @ContentChildren(DwInputDirective) dwInputDirectiveQueryList: QueryList<DwInputDirective>;
  @Input() dwAddOnBeforeIcon: TInputGroupIconClass;
  @Input() dwAddOnAfterIcon: TInputGroupIconClass;
  @Input() dwPrefixIcon: TInputGroupIconClass;
  @Input() dwSuffixIcon: TInputGroupIconClass;

  @Input() set dwSize(value: DwInputGroupSizeType) {
    this._size = value;
    this.updateChildrenInputSize();
  }

  get dwSize(): DwInputGroupSizeType {
    return this._size;
  }

  @Input()
  @HostBinding(`class.ant-input-group-compact`)
  set dwCompact(value: boolean) {
    this._compact = toBoolean(value);
  }

  get dwCompact(): boolean {
    return this._compact;
  }

  @Input()
  set dwAddOnBefore(value: string | TemplateRef<void>) {
    this.isAddOnBeforeString = !(value instanceof TemplateRef);
    this._addOnBefore = value;
  }

  get dwAddOnBefore(): string | TemplateRef<void> {
    return this._addOnBefore;
  }

  @Input()
  set dwAddOnAfter(value: string | TemplateRef<void>) {
    this.isAddOnAfterString = !(value instanceof TemplateRef);
    this._addOnAfter = value;
  }

  get dwAddOnAfter(): string | TemplateRef<void> {
    return this._addOnAfter;
  }

  @Input()
  set dwPrefix(value: string | TemplateRef<void>) {
    this.isPrefixString = !(value instanceof TemplateRef);
    this._prefix = value;
  }

  get dwPrefix(): string | TemplateRef<void> {
    return this._prefix;
  }

  @Input()
  set dwSuffix(value: string | TemplateRef<void>) {
    this.isSuffixString = !(value instanceof TemplateRef);
    this._suffix = value;
  }

  get dwSuffix(): string | TemplateRef<void> {
    return this._suffix;
  }

  @Input()
  @HostBinding(`class.ant-input-search-enter-button`)
  @HostBinding(`class.ant-input-search`)
  set dwSearch(value: boolean) {
    this._search = toBoolean(value);
  }

  get dwSearch(): boolean {
    return this._search;
  }

  get isLarge(): boolean {
    return this.dwSize === 'large';
  }

  get isSmall(): boolean {
    return this.dwSize === 'small';
  }

  get isAffix(): boolean {
    return (!!(this.dwSuffix || this.dwPrefix || this.dwPrefixIcon || this.dwSuffixIcon));
  }

  @HostBinding('class.ant-input-affix-wrapper')
  get isAffixWrapper(): boolean {
    return (!!(this.dwSuffix || this.dwPrefix || this.dwPrefixIcon || this.dwSuffixIcon)) && !this.isAddOn;
  }

  @HostBinding('class.ant-input-group-wrapper')
  get isAddOn(): boolean {
    return !!(this.dwAddOnAfter || this.dwAddOnBefore || this.dwAddOnAfterIcon || this.dwAddOnBeforeIcon);
  }

  @HostBinding('class.ant-input-group')
  get isGroup(): boolean {
    return (!this.isAffix) && (!this.isAddOn);
  }

  @HostBinding(`class.ant-input-group-lg`)
  get isLargeGroup(): boolean {
    return this.isGroup && this.isLarge;
  }

  @HostBinding(`class.ant-input-group-wrapper-lg`)
  get isLargeGroupWrapper(): boolean {
    return this.isAddOn && this.isLarge;
  }

  @HostBinding(`class.ant-input-affix-wrapper-lg`)
  get isLargeAffix(): boolean {
    return this.isAffixWrapper && this.isLarge;
  }

  @HostBinding(`class.ant-input-search-lg`)
  get isLargeSearch(): boolean {
    return this.dwSearch && this.isLarge;
  }

  @HostBinding(`class.ant-input-group-sm`)
  get isSmallGroup(): boolean {
    return this.isGroup && this.isSmall;
  }

  @HostBinding(`class.ant-input-affix-wrapper-sm`)
  get isSmallAffix(): boolean {
    return this.isAffixWrapper && this.isSmall;
  }

  @HostBinding(`class.ant-input-group-wrapper-sm`)
  get isSmallGroupWrapper(): boolean {
    return this.isAddOn && this.isSmall;
  }

  @HostBinding(`class.ant-input-search-sm`)
  get isSmallSearch(): boolean {
    return this.dwSearch && this.isSmall;
  }

  updateChildrenInputSize(): void {
    if (this.dwInputDirectiveQueryList) {
      this.dwInputDirectiveQueryList.forEach(item => item.dwSize = this.dwSize);
    }
  }

  constructor(private el: ElementRef) {

  }

  ngAfterContentInit(): void {
    this.updateChildrenInputSize();
  }
}
