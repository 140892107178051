import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DwBreadCrumbItemComponent } from './dw-breadcrumb-item.component';
import { DwBreadCrumbComponent } from './dw-breadcrumb.component';

@NgModule({
  imports     : [ CommonModule ],
  declarations: [ DwBreadCrumbComponent, DwBreadCrumbItemComponent ],
  exports     : [ DwBreadCrumbComponent, DwBreadCrumbItemComponent ]
})
export class DwBreadCrumbModule {}
