import { Pipe, PipeTransform } from '@angular/core';

import { DwI18nService } from './dw-i18n.service';

@Pipe({
  name: 'dwI18n'
})
export class DwI18nPipe implements PipeTransform {
  constructor(private _locale: DwI18nService) {
  }

  transform(path: string, keyValue?: object): string {
    return this._locale.translate(path, keyValue);
  }
}
