import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

import { dropDownAnimation } from '../core/animation/dropdown-animations';

import { DwDropDownComponent } from './dw-dropdown.component';
import { DwDropDownDirective } from './dw-dropdown.directive';

@Component({
  selector           : 'dw-dropdown-button',
  preserveWhitespaces: false,
  animations         : [
    dropDownAnimation
  ],
  templateUrl        : './dw-dropdown-button.component.html',
  styles             : [ `
    :host {
      position: relative;
      display: inline-block;
    }

    .ant-dropdown {
      top: 100%;
      left: 0;
      position: relative;
      width: 100%;
      margin-top: 4px;
      margin-bottom: 4px;
    }
  ` ]
})

export class DwDropDownButtonComponent extends DwDropDownComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() dwSize = 'default';
  @Input() dwType = 'default';
  @ViewChild('content') content;
  @Output() dwClick = new EventEmitter<MouseEvent>();
  @ViewChild(DwDropDownDirective) dwOrigin;

  onVisibleChange = (visible: boolean) => {
    if (this.dwDisabled) {
      return;
    }
    if (visible) {
      this.setTriggerWidth();
    }
    if (this.dwVisible !== visible) {
      this.dwVisible = visible;
      this.dwVisibleChange.emit(this.dwVisible);
    }
    this.changeDetector.markForCheck();
  }

  /** rewrite afterViewInit hook */
  ngAfterViewInit(): void {
    this.startSubscribe(this.$visibleChange);
  }
}
