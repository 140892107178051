import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';

import { FunctionProp } from '../core/types/common-wrap';
import { toBoolean, valueFunctionProp, InputBoolean } from '../core/util/convert';
import { LoggerService } from '../core/util/logger/logger.service';
import { DwI18nService } from '../i18n/dw-i18n.service';
import { CandyDate } from './lib/candy-date';

import { AbstractPickerComponent, CompatibleDate } from './abstract-picker.component';
import { DisabledTimeFn, PanelMode, PresetRanges } from './standard-types';

@Component({
  template: `` // Just for rollup
})

export class DateRangePickerComponent extends AbstractPickerComponent implements OnInit, OnChanges {
  showWeek: boolean = false; // Should show as week picker

  @Input() dwDateRender: FunctionProp<TemplateRef<Date> | string>;
  @Input() dwDisabledTime: DisabledTimeFn;
  @Input() dwRenderExtraFooter: FunctionProp<TemplateRef<void> | string>;
  @Input() @InputBoolean() dwShowToday: boolean = true;
  @Input() dwMode: PanelMode | PanelMode[];
  @Input() dwRanges: FunctionProp<PresetRanges>;
  @Output() dwOnPanelChange = new EventEmitter<PanelMode | PanelMode[]>();

  private _showTime: object | boolean;
  @Input() get dwShowTime(): object | boolean { return this._showTime; }
  set dwShowTime(value: object | boolean) {
    this._showTime = typeof value === 'object' ? value : toBoolean(value);
  }

  @Output() dwOnOk = new EventEmitter<CompatibleDate>();

  get realShowToday(): boolean { // Range not support dwShowToday currently
    return !this.isRange && this.dwShowToday;
  }

  extraFooter: TemplateRef<void> | string;

  constructor(i18n: DwI18nService, private logger: LoggerService) {
    super(i18n);
  }

  ngOnInit(): void {
    super.ngOnInit();

    // Default format when it's empty
    if (!this.dwFormat) {
      if (this.showWeek) {
        this.dwFormat = 'yyyy-ww'; // Format for week
      } else {
        this.dwFormat = this.dwShowTime ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd';
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (changes.dwRenderExtraFooter) {
      this.extraFooter = valueFunctionProp(this.dwRenderExtraFooter);
    }
  }

  // If has no timepicker and the user select a date by date panel, then close picker
  onValueChange(value: CandyDate): void {
    super.onValueChange(value);

    if (!this.dwShowTime) {
      this.closeOverlay();
    }
  }

  // Emitted when done with date selecting
  onResultOk(): void {
    if (this.isRange) {
      if ((this.dwValue as CandyDate[]).length) {
        this.dwOnOk.emit([ this.dwValue[ 0 ].nativeDate, this.dwValue[ 1 ].nativeDate ]);
      } else {
        this.dwOnOk.emit([]);
      }
    } else {
      if (this.dwValue) {
        this.dwOnOk.emit((this.dwValue as CandyDate).nativeDate);
      } else {
        this.dwOnOk.emit(null);
      }
    }
    this.closeOverlay();
  }

  onOpenChange(open: boolean): void {
    this.dwOnOpenChange.emit(open);
  }
}
