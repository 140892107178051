import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DwCardGridDirective } from './dw-card-grid.directive';
import { DwCardLoadingComponent } from './dw-card-loading.component';
import { DwCardMetaComponent } from './dw-card-meta.component';
import { DwCardTabComponent } from './dw-card-tab.component';
import { DwCardComponent } from './dw-card.component';

@NgModule({
  imports     : [ CommonModule ],
  declarations: [ DwCardComponent, DwCardGridDirective, DwCardMetaComponent, DwCardLoadingComponent, DwCardTabComponent ],
  exports     : [ DwCardComponent, DwCardGridDirective, DwCardMetaComponent, DwCardLoadingComponent, DwCardTabComponent ]
})
export class DwCardModule {
}
