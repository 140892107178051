import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewChild
} from '@angular/core';

import { fromEvent, merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DwMeasureScrollbarService } from '../core/services/dw-measure-scrollbar.service';
import { isNotNil } from '../core/util/check';
import { toBoolean } from '../core/util/convert';
import { DwI18nService } from '../i18n/dw-i18n.service';

import { DwThComponent } from './dw-th.component';
import { DwTheadComponent } from './dw-thead.component';

@Component({
  selector           : 'dw-table',
  preserveWhitespaces: false,
  templateUrl        : './dw-table.component.html'
})
export class DwTableComponent implements OnInit, AfterViewInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  private _bordered = false;
  private _showPagination = true;
  private _loading = false;
  private _showSizeChanger = false;
  private _showQuickJumper = false;
  private _hideOnSinglePage = false;
  private _scroll: { x: string; y: string } = { x: null, y: null };
  private _footer: string | TemplateRef<void>;
  private _title: string | TemplateRef<void>;
  private _noResult: string | TemplateRef<void>;
  private _pageIndex = 1;
  private _pageSize = 10;
  private _widthConfig: string[] = [];
  private _frontPagination = true;
  private _simple = false;
  /* tslint:disable-next-line:no-any */
  locale: any = {};
  dwTheadComponent: DwTheadComponent;
  isFooterString: boolean;
  isTitleString: boolean;
  isNoResultString: boolean;
  el: HTMLElement;
  lastScrollLeft = 0;
  /* tslint:disable-next-line:no-any */
  rawData: any[] = [];
  /* tslint:disable-next-line:no-any */
  syncData: any[] = [];
  /** public data for ngFor tr */
  /* tslint:disable-next-line:no-any */
  data: any[] = [];
  headerBottomStyle;
  isWidthConfigSet = false;
  @ViewChild('tableHeaderElement') tableHeaderElement: ElementRef;
  @ViewChild('tableBodyElement') tableBodyElement: ElementRef;
  @ViewChild('tableMainElement') tableMainElement: ElementRef;
  @ContentChildren(DwThComponent, { descendants: true }) listOfDwThComponent: QueryList<DwThComponent>;

  @Output() dwPageSizeChange: EventEmitter<number> = new EventEmitter();
  @Output() dwPageIndexChange: EventEmitter<number> = new EventEmitter();
  @Input() dwShowTotal: TemplateRef<{ $implicit: number, range: [ number, number ] }>;

  /* tslint:disable-next-line:no-any */
  @Output() dwCurrentPageDataChange: EventEmitter<any[]> = new EventEmitter();
  @Input() dwSize: string = 'default';
  /** page size changer select values */
  @Input() dwPageSizeOptions = [ 10, 20, 30, 40, 50 ];
  @Input() dwLoadingDelay = 0;
  @Input() dwTotal: number;

  @Input()
  set dwSimple(value: boolean) {
    this._simple = toBoolean(value);
  }

  get dwSimple(): boolean {
    return this._simple;
  }

  @Input()
  set dwFrontPagination(value: boolean) {
    this._frontPagination = toBoolean(value);
    this.parseInputData();
  }

  get dwFrontPagination(): boolean {
    return this._frontPagination;
  }

  @Input()
  set dwWidthConfig(value: string[]) {
    this.isWidthConfigSet = true;
    this._widthConfig = value;
  }

  get dwWidthConfig(): string[] {
    return this._widthConfig;
  }

  @Input()
  set dwTitle(value: string | TemplateRef<void>) {
    this.isTitleString = !(value instanceof TemplateRef);
    this._title = value;
  }

  get dwTitle(): string | TemplateRef<void> {
    return this._title;
  }

  @Input()
  set dwFooter(value: string | TemplateRef<void>) {
    this.isFooterString = !(value instanceof TemplateRef);
    this._footer = value;
  }

  get dwFooter(): string | TemplateRef<void> {
    return this._footer;
  }

  @Input()
  set dwNoResult(value: string | TemplateRef<void>) {
    this.isNoResultString = !(value instanceof TemplateRef);
    this._noResult = value;
  }

  get dwNoResult(): string | TemplateRef<void> {
    return this._noResult;
  }

  @Input()
  set dwBordered(value: boolean) {
    this._bordered = toBoolean(value);
  }

  get dwBordered(): boolean {
    return this._bordered;
  }

  @Input()
  set dwShowPagination(value: boolean) {
    this._showPagination = toBoolean(value);
  }

  get dwShowPagination(): boolean {
    return this._showPagination;
  }

  @Input()
  set dwLoading(value: boolean) {
    this._loading = toBoolean(value);
  }

  get dwLoading(): boolean {
    return this._loading;
  }

  @Input()
  set dwShowSizeChanger(value: boolean) {
    this._showSizeChanger = toBoolean(value);
  }

  get dwShowSizeChanger(): boolean {
    return this._showSizeChanger;
  }

  @Input()
  set dwHideOnSinglePage(value: boolean) {
    this._hideOnSinglePage = toBoolean(value);
  }

  get dwHideOnSinglePage(): boolean {
    return this._hideOnSinglePage;
  }

  @Input()
  set dwShowQuickJumper(value: boolean) {
    this._showQuickJumper = toBoolean(value);
  }

  get dwShowQuickJumper(): boolean {
    return this._showQuickJumper;
  }

  @Input()
  set dwScroll(value: { x: string; y: string }) {
    if (isNotNil(value)) {
      this._scroll = value;
    } else {
      this._scroll = { x: null, y: null };
    }
    this.cdr.detectChanges();
    this.setScrollPositionClassName();
  }

  get dwScroll(): { x: string; y: string } {
    return this._scroll;
  }

  @Input()
  /* tslint:disable-next-line:no-any */
  set dwData(data: any[]) {
    if (Array.isArray(data)) {
      this.rawData = data;
      this.parseInputData();
    } else {
      console.warn('dwData only accept array');
    }
  }

  parseInputData(): void {
    if (this.dwFrontPagination) {
      this.syncData = this.rawData;
      this.dwTotal = this.syncData.length;
      this.checkPageIndexBounding();
      this.generateSyncDisplayData();
    } else {
      this.data = this.rawData;
      this.dwCurrentPageDataChange.emit(this.data);
    }
  }

  @Input()
  set dwPageIndex(value: number) {
    if (this._pageIndex === value) {
      return;
    }
    this._pageIndex = value;
    if (this.dwFrontPagination) {
      this.generateSyncDisplayData();
    }
  }

  get dwPageIndex(): number {
    return this._pageIndex;
  }

  emitPageIndex(index: number): void {
    this.dwPageIndex = index;
    this.dwPageIndexChange.emit(this.dwPageIndex);
  }

  emitPageSize(size: number): void {
    this.dwPageSize = size;
    this.dwPageSizeChange.emit(this.dwPageSize);
  }

  @Input()
  set dwPageSize(value: number) {
    if (this._pageSize === value) {
      return;
    }
    this._pageSize = value;
    if (this.dwFrontPagination) {
      this.checkPageIndexBounding();
      this.generateSyncDisplayData();
    }
  }

  get dwPageSize(): number {
    return this._pageSize;
  }

  checkPageIndexBounding(): void {
    if (this.dwFrontPagination) {
      const maxPageIndex = Math.ceil(this.syncData.length / this.dwPageSize);
      const pageIndex = !this.dwPageIndex ? 1 : (this.dwPageIndex > maxPageIndex ? maxPageIndex : this.dwPageIndex);
      if (pageIndex !== this.dwPageIndex) {
        this._pageIndex = pageIndex;
        Promise.resolve().then(() => this.dwPageIndexChange.emit(pageIndex));
      }
    }
  }

  generateSyncDisplayData(): void {
    this.data = this.syncData.slice((this.dwPageIndex - 1) * this.dwPageSize, this.dwPageIndex * this.dwPageSize);
    this.dwCurrentPageDataChange.emit(this.data);
  }

  syncScrollTable(e: MouseEvent): void {
    if (e.currentTarget === e.target) {
      const target = e.target as HTMLElement;
      if (target.scrollLeft !== this.lastScrollLeft && this.dwScroll && this.dwScroll.x) {
        if (target === this.tableBodyElement.nativeElement && this.tableHeaderElement) {
          this.tableHeaderElement.nativeElement.scrollLeft = target.scrollLeft;
        } else if (target === this.tableHeaderElement.nativeElement && this.tableBodyElement) {
          this.tableBodyElement.nativeElement.scrollLeft = target.scrollLeft;
        }
        this.setScrollPositionClassName();
      }
      this.lastScrollLeft = target.scrollLeft;
    }
  }

  setScrollPositionClassName(): void {
    if (this.tableBodyElement && this.dwScroll && this.dwScroll.x) {
      if ((this.tableBodyElement.nativeElement.scrollWidth === this.tableBodyElement.nativeElement.clientWidth) && (this.tableBodyElement.nativeElement.scrollWidth !== 0)) {
        this.setScrollName();
      } else if (this.tableBodyElement.nativeElement.scrollLeft === 0) {
        this.setScrollName('left');
      } else if (this.tableBodyElement.nativeElement.scrollWidth === (this.tableBodyElement.nativeElement.scrollLeft + this.tableBodyElement.nativeElement.clientWidth)) {
        this.setScrollName('right');
      } else {
        this.setScrollName('middle');
      }
    }
  }

  setScrollName(position?: string): void {
    const prefix = 'ant-table-scroll-position';
    const classList = [ 'left', 'right', 'middle' ];
    classList.forEach(name => {
      this.renderer.removeClass(this.tableMainElement.nativeElement, `${prefix}-${name}`);
    });
    if (position) {
      this.renderer.addClass(this.tableMainElement.nativeElement, `${prefix}-${position}`);
    }
  }

  fitScrollBar(): void {
    const scrollbarWidth = this.dwMeasureScrollbarService.scrollBarWidth;
    if (scrollbarWidth) {
      this.headerBottomStyle = {
        marginBottom : `-${scrollbarWidth}px`,
        paddingBottom: `0px`
      };
    }
  }

  @HostListener('window:resize')
  onWindowResize(): void {
    this.fitScrollBar();
    this.setScrollPositionClassName();
  }

  ngOnInit(): void {
    this.i18n.localeChange.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.locale = this.i18n.getLocaleData('Table'));
    this.fitScrollBar();
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.setScrollPositionClassName());
    this.ngZone.runOutsideAngular(() => {
      if (this.tableHeaderElement
        && this.tableHeaderElement.nativeElement
        && this.tableBodyElement
        && this.tableBodyElement.nativeElement) {
        merge(
          fromEvent(this.tableHeaderElement.nativeElement, 'scroll'),
          fromEvent(this.tableBodyElement.nativeElement, 'scroll')
        ).pipe(takeUntil(this.unsubscribe$)).subscribe((data: MouseEvent) => {
          this.syncScrollTable(data);
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  constructor(private renderer: Renderer2, private ngZone: NgZone, private elementRef: ElementRef, private cdr: ChangeDetectorRef, private dwMeasureScrollbarService: DwMeasureScrollbarService, private i18n: DwI18nService) {
    this.el = this.elementRef.nativeElement;
  }
}
