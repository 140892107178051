import { Component, ContentChildren, Input, QueryList, TemplateRef } from '@angular/core';
import { DwOptionComponent } from './dw-option.component';

@Component({
  selector   : 'dw-option-group',
  templateUrl: './dw-option-group.component.html'
})
export class DwOptionGroupComponent {
  _label: string | TemplateRef<void>;
  isLabelString: boolean;
  @ContentChildren(DwOptionComponent) listOfDwOptionComponent: QueryList<DwOptionComponent>;

  @Input()
  set dwLabel(value: string | TemplateRef<void>) {
    this.isLabelString = !(value instanceof TemplateRef);
    this._label = value;
  }

  get dwLabel(): string | TemplateRef<void> {
    return this._label;
  }

}
