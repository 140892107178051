import { InjectionToken } from '@angular/core';

import { DwMessageConfig } from '../message/dw-message-config';

export interface DwNotificationConfig extends DwMessageConfig {
  dwTop?: string;
  dwBottom?: string;
  dwPlacement?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight' | string;
}

export const DW_NOTIFICATION_DEFAULT_CONFIG = new InjectionToken<DwNotificationConfig>('DW_NOTIFICATION_DEFAULT_CONFIG');

export const DW_NOTIFICATION_CONFIG = new InjectionToken<DwNotificationConfig>('DW_NOTIFICATION_CONFIG');

export const DW_NOTIFICATION_DEFAULT_CONFIG_PROVIDER = {
  provide : DW_NOTIFICATION_DEFAULT_CONFIG,
  useValue: {
    dwTop         : '24px',
    dwBottom      : '24px',
    dwPlacement   : 'topRight',
    dwDuration    : 4500,
    dwMaxStack    : 7,
    dwPauseOnHover: true,
    dwAnimate     : true
  }
};
