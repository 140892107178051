import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef
} from '@angular/core';

import { DwUpdateHostClassService } from '../core/services/update-host-class.service';
import { toBoolean } from '../core/util/convert';

@Component({
  selector           : 'dw-divider',
  templateUrl        : './dw-divider.component.html',
  providers          : [ DwUpdateHostClassService ],
  preserveWhitespaces: false,
  changeDetection    : ChangeDetectionStrategy.OnPush
})
export class DwDividerComponent implements OnChanges, OnInit {
  // region fields

  isText = false;
  textStr = '';
  textTpl: TemplateRef<void>;

  @Input()
  set dwText(value: string | TemplateRef<void>) {
    if (value instanceof TemplateRef) {
      this.textStr = null;
      this.textTpl = value;
    } else {
      this.textStr = value;
    }
    this.isText = !!value;
  }

  @Input() dwType: 'horizontal' | 'vertical' = 'horizontal';

  @Input() dwOrientation: 'left' | 'right' | '' = '';

  private _dashed = false;

  @Input()
  set dwDashed(value: boolean) {
    this._dashed = toBoolean(value);
  }

  get dwDashed(): boolean {
    return this._dashed;
  }

  // endregion
  private setClass(): void {
    const orientationPrefix = (this.dwOrientation.length > 0) ? '-' + this.dwOrientation : this.dwOrientation;
    const classMap = {
      [ 'ant-divider' ]                              : true,
      [ `ant-divider-${this.dwType}` ]               : true,
      [ `ant-divider-with-text${orientationPrefix}` ]: this.isText,
      [ `ant-divider-dashed` ]                       : this.dwDashed
    };
    this.updateHostClassService.updateHostClass(this.el.nativeElement, classMap);
    this.cd.detectChanges();
  }

  constructor(private el: ElementRef, private cd: ChangeDetectorRef, private updateHostClassService: DwUpdateHostClassService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setClass();
  }

  ngOnInit(): void {
    this.setClass();
  }
}
