import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { DwInputNumberComponent } from './dw-input-number.component';

@NgModule({
  imports     : [ CommonModule, FormsModule ],
  declarations: [ DwInputNumberComponent ],
  exports     : [ DwInputNumberComponent ]
})
export class DwInputNumberModule {
}
