import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { Component, Input } from '@angular/core';

import { DwMessageComponent } from '../message/dw-message.component';

import { DwNotificationContainerComponent } from './dw-notification-container.component';
import { DwNotificationDataFilled } from './dw-notification.definitions';

@Component({
  selector           : 'dw-notification',
  preserveWhitespaces: false,
  animations         : [
    trigger('enterLeave', [
      state('enterRight', style({ opacity: 1, transform: 'translateX(0)' })),
      transition('* => enterRight', [
        style({ opacity: 0, transform: 'translateX(5%)' }),
        animate('100ms linear')
      ]),
      state('enterLeft', style({ opacity: 1, transform: 'translateX(0)' })),
      transition('* => enterLeft', [
        style({ opacity: 0, transform: 'translateX(-5%)' }),
        animate('100ms linear')
      ]),
      state('leave', style({
        opacity        : 0,
        transform      : 'scaleY(0.8)',
        transformOrigin: '0% 0%'
      })),
      transition('* => leave', [
        style({
          opacity        : 1,
          transform      : 'scaleY(1)',
          transformOrigin: '0% 0%'
        }),
        animate('100ms linear')
      ])
    ])
  ],
  templateUrl         : './dw-notification.component.html'
})
export class DwNotificationComponent extends DwMessageComponent {
  @Input() dwMessage: DwNotificationDataFilled;

  constructor(private container: DwNotificationContainerComponent) {
    super(container);
  }

  close(): void {
    this._destroy();
  }

  get state(): string {
    if (this.dwMessage.state === 'enter') {
      if ((this.container.config.dwPlacement === 'topLeft') || (this.container.config.dwPlacement === 'bottomLeft')) {
        return 'enterLeft';
      } else {
        return 'enterRight';
      }
    } else {
      return this.dwMessage.state;
    }

  }
}
