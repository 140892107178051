import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DwMatchMediaService } from '../core/services/dw-match-media.service';

import { DwContentComponent } from './dw-content.component';
import { DwFooterComponent } from './dw-footer.component';
import { DwHeaderComponent } from './dw-header.component';
import { DwLayoutComponent } from './dw-layout.component';
import { DwSiderComponent } from './dw-sider.component';

@NgModule({
  declarations: [ DwLayoutComponent, DwHeaderComponent, DwContentComponent, DwFooterComponent, DwSiderComponent ],
  exports     : [ DwLayoutComponent, DwHeaderComponent, DwContentComponent, DwFooterComponent, DwSiderComponent ],
  providers   : [ DwMatchMediaService ],
  imports     : [ CommonModule ]
})
export class DwLayoutModule {
}
