import {
  forwardRef,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { toBoolean } from '../core/util/convert';

@Component({
  selector           : 'dw-rate',
  preserveWhitespaces: false,
  templateUrl        : './dw-rate.component.html',
  providers          : [
    {
      provide    : NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DwRateComponent),
      multi      : true
    }
  ]
})
export class DwRateComponent implements OnInit, ControlValueAccessor, AfterViewInit {
  private _allowClear = true;
  private _allowHalf = false;
  private _disabled = false;
  private _count = 5;
  private _value = 0;
  private _autoFocus = false;
  @Input() dwCharacter: TemplateRef<void>;
  @Output() dwOnBlur = new EventEmitter<FocusEvent>();
  @Output() dwOnFocus = new EventEmitter<FocusEvent>();
  @Output() dwOnKeyDown = new EventEmitter<KeyboardEvent>();
  @Output() dwOnHoverChange = new EventEmitter<number>();
  @ViewChild('ulElement') private ulElement: ElementRef;
  prefixCls = 'ant-rate';
  isInit = false;
  hasHalf = false;
  innerPrefixCls = `${this.prefixCls}-star`;
  classMap;
  starArray: number[] = [];
  hoverValue = 0;
  isFocused = false;
  floatReg: RegExp = /^\d+(\.\d+)?$/;

  onChange: (value: number) => void = () => null;
  onTouched: () => void = () => null;

  @Input()
  set dwAutoFocus(value: boolean) {
    this._autoFocus = toBoolean(value);
    this.updateAutoFocus();
  }

  get dwAutoFocus(): boolean {
    return this._autoFocus;
  }

  @Input()
  set dwCount(value: number) {
    if (this._count === value) {
      return;
    }
    this._count = value;
    this.updateStarArray();
  }

  get dwCount(): number {
    return this._count;
  }

  @Input()
  set dwAllowHalf(value: boolean) {
    this._allowHalf = toBoolean(value);
  }

  get dwAllowHalf(): boolean {
    return this._allowHalf;
  }

  @Input()
  set dwAllowClear(value: boolean) {
    this._allowClear = toBoolean(value);
  }

  get dwAllowClear(): boolean {
    return this._allowClear;
  }

  get dwValue(): number {
    return this._value;
  }

  set dwValue(input: number) {
    let value = input;
    if (this._value === value) {
      return;
    }
    this._value = value;
    if (this.floatReg.test(value.toString())) {
      value += 0.5;
      this.hasHalf = true;
    }
    this.hoverValue = value;
  }

  @Input()
  set dwDisabled(value: boolean) {
    this._disabled = toBoolean(value);
    this.setClassMap();
  }

  get dwDisabled(): boolean {
    return this._disabled;
  }

  setClassMap(): void {
    this.classMap = {
      [ this.prefixCls ]              : true,
      [ `${this.prefixCls}-disabled` ]: this.dwDisabled
    };
  }

  updateAutoFocus(): void {
    if (this.isInit && !this.dwDisabled) {
      if (this.dwAutoFocus) {
        this.renderer.setAttribute(this.ulElement.nativeElement, 'autofocus', 'autofocus');
      } else {
        this.renderer.removeAttribute(this.ulElement.nativeElement, 'autofocus');
      }
    }
  }

  clickRate(e: MouseEvent, index: number, isFull: boolean): void {
    e.stopPropagation();
    if (this.dwDisabled) {
      return;
    }
    this.hasHalf = !isFull && this.dwAllowHalf;

    let actualValue = index + 1;
    this.hoverValue = actualValue;

    if (this.hasHalf) {
      actualValue -= 0.5;
    }

    if (this.dwValue === actualValue) {
      if (this.dwAllowClear) {
        this.dwValue = 0;
        this.onChange(this.dwValue);
      }
    } else {
      this.dwValue = actualValue;
      this.onChange(this.dwValue);
    }
  }

  hoverRate(e: MouseEvent, index: number, isFull: boolean): void {
    e.stopPropagation();
    if (this.dwDisabled) {
      return;
    }
    const isHalf: boolean = !isFull && this.dwAllowHalf;
    if (this.hoverValue === index + 1 && isHalf === this.hasHalf) {
      return;
    }

    this.hoverValue = index + 1;
    this.dwOnHoverChange.emit(this.hoverValue);
    this.hasHalf = isHalf;
  }

  leaveRate(e: MouseEvent): void {
    e.stopPropagation();
    let oldVal = this.dwValue;
    if (this.floatReg.test(oldVal.toString())) {
      oldVal += 0.5;
      this.hasHalf = true;
    }
    this.hoverValue = oldVal;
  }

  onFocus(e: FocusEvent): void {
    this.isFocused = true;
    this.dwOnFocus.emit(e);
  }

  onBlur(e: FocusEvent): void {
    this.isFocused = false;
    this.dwOnBlur.emit(e);
  }

  focus(): void {
    this.ulElement.nativeElement.focus();
  }

  blur(): void {
    this.ulElement.nativeElement.blur();
  }

  onKeyDown(e: KeyboardEvent): void {
    const code = e.code;
    if ((code === 'ArrowRight' || e.keyCode === 39) && (this.dwValue < this.dwCount)) {
      if (this.dwAllowHalf) {
        this.dwValue += 0.5;
      } else {
        this.dwValue += 1;
      }
      this.onChange(this.dwValue);
    } else if ((code === 'ArrowLeft' || e.keyCode === 37) && (this.dwValue > 0)) {
      if (this.dwAllowHalf) {
        this.dwValue -= 0.5;
      } else {
        this.dwValue -= 1;
      }
      this.onChange(this.dwValue);
    }
    this.dwOnKeyDown.emit(e);
    e.preventDefault();
  }

  setClasses(i: number): object {
    return {
      [ this.innerPrefixCls ]             : true,
      [ `${this.innerPrefixCls}-full` ]   : (i + 1 < this.hoverValue) || (!this.hasHalf) && (i + 1 === this.hoverValue),
      [ `${this.innerPrefixCls}-half` ]   : (this.hasHalf) && (i + 1 === this.hoverValue),
      [ `${this.innerPrefixCls}-active` ] : (this.hasHalf) && (i + 1 === this.hoverValue),
      [ `${this.innerPrefixCls}-zero` ]   : (i + 1 > this.hoverValue),
      [ `${this.innerPrefixCls}-focused` ]: (this.hasHalf) && (i + 1 === this.hoverValue) && this.isFocused
    };
  }

  updateStarArray(): void {
    let index = 0;
    this.starArray = [];
    while (index < this.dwCount) {
      this.starArray.push(index++);
    }
  }

  writeValue(value: number | null): void {
    this.dwValue = value || 0;
  }

  registerOnChange(fn: (_: number) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.dwDisabled = isDisabled;
  }

  constructor(private renderer: Renderer2) {
  }

  ngOnInit(): void {
    this.setClassMap();
    this.updateStarArray();
  }

  ngAfterViewInit(): void {
    this.isInit = true;
  }
}
