import {
  forwardRef,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit, Output, TemplateRef
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, Subject, Subscription } from 'rxjs';
import { isNotNil } from '../core/util/check';
import { InputBoolean } from '../core/util/convert';
import { DwFormatBeforeDropEvent, DwFormatEmitEvent } from '../tree/interface';
import { DwTreeNode } from './dw-tree-node';
import { DwTreeService } from './dw-tree.service';

@Component({
  selector   : 'dw-tree',
  templateUrl: './dw-tree.component.html',
  providers  : [
    DwTreeService,
    {
      provide    : NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DwTreeComponent),
      multi      : true
    }
  ]
})

export class DwTreeComponent implements OnInit, OnDestroy {
  @Input() @InputBoolean() dwShowIcon = false;
  @Input() @InputBoolean() dwShowLine = false;
  @Input() @InputBoolean() dwCheckStrictly = false;
  @Input() @InputBoolean() dwCheckable = false;
  @Input() @InputBoolean() dwShowExpand = true;
  @Input() @InputBoolean() dwAsyncData = false;
  @Input() @InputBoolean() dwDraggable = false;
  @Input() @InputBoolean() dwMultiple = false;
  @Input() @InputBoolean() dwExpandAll: boolean = false;
  /**
   * @deprecated use
   * dwExpandAll instead
   */
  @Input() @InputBoolean() dwDefaultExpandAll: boolean = false;
  @Input() dwBeforeDrop: (confirm: DwFormatBeforeDropEvent) => Observable<boolean>;

  @Input()
  // tslint:disable-next-line:no-any
  set dwData(value: any[]) {
    if (Array.isArray(value) && value.length > 0) {
      if (!this.dwTreeService.isArrayOfDwTreeNode(value)) {
        // has not been new DwTreeNode
        this.dwNodes = value.map(item => (new DwTreeNode(item)));
      } else {
        this.dwNodes = value;
      }
      this.dwTreeService.conductOption.isCheckStrictly = this.dwCheckStrictly;
      this.dwTreeService.initTree(this.dwNodes);
    } else {
      if (value !== null) {
        console.warn('ngModel only accepts an array and should be not empty');
      }
    }
  }

  /**
   * @deprecated use
   * dwExpandedKeys instead
   */
  @Input()
  set dwDefaultExpandedKeys(value: string[]) {
    setTimeout(() => {
      this.dwDefaultSubject.next({ type: 'dwExpandedKeys', keys: value });
    });
  }

  /**
   * @deprecated use
   * dwSelectedKeys instead
   */
  @Input()
  set dwDefaultSelectedKeys(value: string[]) {
    setTimeout(() => {
      this.dwDefaultSubject.next({ type: 'dwSelectedKeys', keys: value });
    });
  }

  /**
   * @deprecated use
   * dwCheckedKeys instead
   */
  @Input()
  set dwDefaultCheckedKeys(value: string[]) {
    setTimeout(() => {
      this.dwDefaultSubject.next({ type: 'dwCheckedKeys', keys: value });
    });
  }

  @Input()
  set dwExpandedKeys(value: string[]) {
    setTimeout(() => {
      this.dwDefaultSubject.next({ type: 'dwExpandedKeys', keys: value });
    });
  }

  @Input()
  set dwSelectedKeys(value: string[]) {
    setTimeout(() => {
      this.dwDefaultSubject.next({ type: 'dwSelectedKeys', keys: value });
    });
  }

  @Input()
  set dwCheckedKeys(value: string[]) {
    setTimeout(() => {
      this.dwDefaultSubject.next({ type: 'dwCheckedKeys', keys: value });
    });
  }

  @Input()
  set dwSearchValue(value: string) {
    this._searchValue = value;
    this.dwTreeService.searchExpand(value);
    if (isNotNil(value)) {
      this.dwSearchValueChange.emit(this.dwTreeService.formatEvent('search', null, null));
      this.dwOnSearchNode.emit(this.dwTreeService.formatEvent('search', null, null));
    }
  }

  get dwSearchValue(): string {
    return this._searchValue;
  }

  // model bind
  @Output() dwExpandedKeysChange: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() dwSelectedKeysChange: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() dwCheckedKeysChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  @Output() dwSearchValueChange: EventEmitter<DwFormatEmitEvent> = new EventEmitter();
  /**
   * @deprecated use
   * dwSearchValueChange instead
   */
  @Output() dwOnSearchNode: EventEmitter<DwFormatEmitEvent> = new EventEmitter();

  @Output() dwClick: EventEmitter<DwFormatEmitEvent> = new EventEmitter();
  @Output() dwDblClick: EventEmitter<DwFormatEmitEvent> = new EventEmitter();
  @Output() dwContextMenu: EventEmitter<DwFormatEmitEvent> = new EventEmitter();
  @Output() dwCheckBoxChange: EventEmitter<DwFormatEmitEvent> = new EventEmitter();
  @Output() dwExpandChange: EventEmitter<DwFormatEmitEvent> = new EventEmitter();

  @Output() dwOnDragStart: EventEmitter<DwFormatEmitEvent> = new EventEmitter();
  @Output() dwOnDragEnter: EventEmitter<DwFormatEmitEvent> = new EventEmitter();
  @Output() dwOnDragOver: EventEmitter<DwFormatEmitEvent> = new EventEmitter();
  @Output() dwOnDragLeave: EventEmitter<DwFormatEmitEvent> = new EventEmitter();
  @Output() dwOnDrop: EventEmitter<DwFormatEmitEvent> = new EventEmitter();
  @Output() dwOnDragEnd: EventEmitter<DwFormatEmitEvent> = new EventEmitter();

  // tslint:disable-next-line:no-any
  @ContentChild('dwTreeTemplate') dwTreeTemplate: TemplateRef<any>;
  _searchValue = '';
  // tslint:disable-next-line:no-any
  dwDefaultSubject = new Subject();
  dwDefaultSubscription: Subscription;
  dwNodes: DwTreeNode[] = [];
  prefixCls = 'ant-tree';
  dwTreeClass = {};

  onChange: (value: DwTreeNode[]) => void = () => null;
  onTouched: () => void = () => null;

  getTreeNodes(): DwTreeNode[] {
    return this.dwNodes;
  }

  /**
   * public function
   */
  getCheckedNodeList(): DwTreeNode[] {
    return this.dwTreeService.getCheckedNodeList();
  }

  getSelectedNodeList(): DwTreeNode[] {
    return this.dwTreeService.getSelectedNodeList();
  }

  getHalfCheckedNodeList(): DwTreeNode[] {
    return this.dwTreeService.getHalfCheckedNodeList();
  }

  getExpandedNodeList(): DwTreeNode[] {
    return this.dwTreeService.getExpandedNodeList();
  }

  getMatchedNodeList(): DwTreeNode[] {
    return this.dwTreeService.getMatchedNodeList();
  }

  setClassMap(): void {
    this.dwTreeClass = {
      [ this.prefixCls ]               : true,
      [ this.prefixCls + '-show-line' ]: this.dwShowLine,
      [ `${this.prefixCls}-icon-hide` ]: !this.dwShowIcon,
      [ 'draggable-tree' ]             : this.dwDraggable
    };
  }

  writeValue(value: DwTreeNode[]): void {
    if (Array.isArray(value) && value.length > 0) {
      this.dwNodes = value;
      this.dwTreeService.conductOption.isCheckStrictly = this.dwCheckStrictly;
      this.dwTreeService.initTree(this.dwNodes);
    } else {
      if (value !== null) {
        console.warn('ngModel only accepts an array and should be not empty');
      }
    }
  }

  registerOnChange(fn: (_: DwTreeNode[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  constructor(public dwTreeService: DwTreeService) {
  }

  ngOnInit(): void {
    this.setClassMap();
    this.dwDefaultSubscription = this.dwDefaultSubject.subscribe((data: { type: string, keys: string[] }) => {
      if (data.keys.length === 0) {
        return;
      }
      switch (data.type) {
        case 'dwExpandedKeys':
          this.dwTreeService.calcExpandedKeys(data.keys, this.dwNodes);
          this.dwExpandedKeysChange.emit(data.keys);
          break;
        case 'dwSelectedKeys':
          this.dwTreeService.calcSelectedKeys(data.keys, this.dwNodes, this.dwMultiple);
          this.dwSelectedKeysChange.emit(data.keys);
          break;
        case 'dwCheckedKeys':
          this.dwTreeService.calcCheckedKeys(data.keys, this.dwNodes, this.dwCheckStrictly);
          this.dwCheckedKeysChange.emit(data.keys);
          break;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.dwDefaultSubscription) {
      this.dwDefaultSubscription.unsubscribe();
      this.dwDefaultSubscription = null;
    }
  }
}
