import { Directive, ElementRef, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { DwWaveRenderer } from './dw-wave-renderer';

@Directive({
  selector: '[dw-wave]'
})
export class DwWaveDirective implements OnInit, OnDestroy {

  private waveRenderer: DwWaveRenderer;

  @Input() dwWaveExtraNode = false;

  constructor(private ngZone: NgZone, private elementRef: ElementRef) {
  }

  ngOnDestroy(): void {
    if (this.waveRenderer) {
      this.waveRenderer.destroy();
    }
  }

  ngOnInit(): void {
    if (this.elementRef.nativeElement) {
      this.waveRenderer = new DwWaveRenderer(this.elementRef.nativeElement, this.ngZone, this.dwWaveExtraNode);
    }
  }
}
