import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { fadeAnimation } from '../core/animation/fade-animations';
import { toBoolean } from '../core/util/convert';
import { DwToolTipComponent } from '../tooltip/dw-tooltip.component';

@Component({
  selector           : 'dw-popconfirm',
  preserveWhitespaces: false,
  animations         : [ fadeAnimation ],
  templateUrl        : './dw-popconfirm.component.html',
  styles             : [ `
    .ant-popover {
      position: relative;
    }
  ` ]
})
export class DwPopconfirmComponent extends DwToolTipComponent {
  _condition = false;
  _prefix = 'ant-popover-placement';
  _trigger = 'click';
  _hasBackdrop = true;
  @Output() dwOnCancel: EventEmitter<void> = new EventEmitter();
  @Output() dwOnConfirm: EventEmitter<void> = new EventEmitter();

  @Input() dwOkText: string;
  @Input() dwOkType: string = 'primary';
  @Input() dwCancelText: string;

  @Input()
  set dwCondition(value: boolean) {
    this._condition = toBoolean(value);
  }

  get dwCondition(): boolean {
    return this._condition;
  }

  show(): void {
    if (!this.dwCondition) {
      this.dwVisible = true;
    } else {
      this.onConfirm();
    }
  }

  onCancel(): void {
    this.dwOnCancel.emit();
    this.dwVisible = false;
  }

  onConfirm(): void {
    this.dwOnConfirm.emit();
    this.dwVisible = false;
  }

  constructor(cdr: ChangeDetectorRef) {
    super(cdr);
  }

}
