import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { DwUpdateHostClassService } from '../core/services/update-host-class.service';

@Directive({
  selector : '[dw-form]',
  providers: [ DwUpdateHostClassService ]
})
export class DwFormDirective implements OnInit {
  el: HTMLElement;
  prefixCls = 'ant-form';
  private _layout = 'horizontal';

  @Input()
  set dwLayout(value: string) {
    this._layout = value;
    this.setClassMap();
  }

  get dwLayout(): string {
    return this._layout;
  }

  setClassMap(): void {
    const classMap = {
      [ `${this.prefixCls}` ]                 : true,
      [ `${this.prefixCls}-${this.dwLayout}` ]: this.dwLayout
    };
    this.dwUpdateHostClassService.updateHostClass(this.el, classMap);
  }

  constructor(private elementRef: ElementRef, private renderer: Renderer2, private dwUpdateHostClassService: DwUpdateHostClassService) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit(): void {
    this.setClassMap();
  }
}
