import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LibPackerModule } from './lib/lib-packer.module';

import { DwDatePickerComponent } from './date-picker.component';
import { DateRangePickerComponent } from './date-range-picker.component';
import { HeaderPickerComponent } from './header-picker.component';
import { DwMonthPickerComponent } from './month-picker.component';
import { DwPickerComponent } from './picker.component';
import { DwRangePickerComponent } from './range-picker.component';
import { DwWeekPickerComponent } from './week-picker.component';
import { DwYearPickerComponent } from './year-picker.component';

@NgModule({
  imports: [
    CommonModule,
    OverlayModule,

    LibPackerModule
  ],
  exports: [
    DwDatePickerComponent,
    DwRangePickerComponent,
    DwMonthPickerComponent,
    DwYearPickerComponent,
    DwWeekPickerComponent
  ],
  declarations: [
    HeaderPickerComponent,
    DateRangePickerComponent,
    DwPickerComponent,

    DwDatePickerComponent,
    DwMonthPickerComponent,
    DwYearPickerComponent,
    DwWeekPickerComponent,
    DwRangePickerComponent
  ],
  providers: []
})
export class DwDatePickerModule { }
