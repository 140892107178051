import { Directive } from '@angular/core';

@Directive({
  selector: '[dwDateCell]'
})
export class DwDateCellDirective { }

@Directive({
  selector: '[dwMonthCell]'
})
export class DwMonthCellDirective { }

@Directive({
  selector: '[dwDateFullCell]'
})
export class DwDateFullCellDirective { }

@Directive({
  selector: '[dwMonthFullCell]'
})
export class DwMonthFullCellDirective { }
