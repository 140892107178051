import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { DwInputModule } from '../input/dw-input.module';

import { DwCascaderComponent } from './dw-cascader.component';

@NgModule({
  imports     : [ CommonModule, FormsModule, OverlayModule, DwInputModule ],
  declarations: [
    DwCascaderComponent
  ],
  exports     : [
    DwCascaderComponent
  ]
})
export class DwCascaderModule {
}
