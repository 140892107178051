import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DwToolTipComponent } from './dw-tooltip.component';
import { DwTooltipDirective } from './dw-tooltip.directive';

@NgModule({
  declarations   : [ DwToolTipComponent, DwTooltipDirective ],
  exports        : [ DwToolTipComponent, DwTooltipDirective ],
  imports        : [ CommonModule, OverlayModule ],
  entryComponents: [ DwToolTipComponent ]
})
export class DwToolTipModule {
}
