import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { DwI18nModule } from '../i18n/dw-i18n.module';
import { DwToolTipModule } from '../tooltip/dw-tooltip.module';

import { DwProgressModule } from './../progress/dw-progress.module';
import { DwUploadBtnComponent } from './dw-upload-btn.component';
import { DwUploadListComponent } from './dw-upload-list.component';
import { DwUploadComponent } from './dw-upload.component';

@NgModule({
  imports:      [CommonModule, FormsModule, DwToolTipModule, DwProgressModule, DwI18nModule],
  declarations: [DwUploadComponent, DwUploadBtnComponent, DwUploadListComponent],
  exports:      [DwUploadComponent]
})
export class DwUploadModule { }
