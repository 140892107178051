import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DW_NOTIFICATION_DEFAULT_CONFIG_PROVIDER } from './dw-notification-config';
import { DwNotificationContainerComponent } from './dw-notification-container.component';
import { DwNotificationComponent } from './dw-notification.component';
import { DwNotificationService } from './dw-notification.service';

@NgModule({
  imports: [ CommonModule, OverlayModule ],
  declarations: [ DwNotificationComponent, DwNotificationContainerComponent ],
  providers: [ DW_NOTIFICATION_DEFAULT_CONFIG_PROVIDER, DwNotificationService ],
  entryComponents: [ DwNotificationContainerComponent ]
})
export class DwNotificationModule { }
