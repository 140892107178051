import { Component, HostListener, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';

import { toBoolean } from '../core/util/convert';
import { DwToolTipComponent } from '../tooltip/dw-tooltip.component';

import { DwSliderComponent } from './dw-slider.component';

@Component({
  selector           : 'dw-slider-handle',
  preserveWhitespaces: false,
  templateUrl        : './dw-slider-handle.component.html'
})
export class DwSliderHandleComponent implements OnChanges {

  // Static properties
  @Input() dwClassName: string;
  @Input() dwVertical: string;
  @Input() dwOffset: number;
  @Input() dwValue: number; // [For tooltip]
  @Input() dwTipFormatter: (value: number) => string; // [For tooltip]
  @Input() set dwActive(value: boolean) { // [For tooltip]
    const show = toBoolean(value);
    if (this.tooltip) {
      if (show) {
        this.tooltip.show();
      } else {
        this.tooltip.hide();
      }
    }
  }

  // Locals
  @ViewChild('tooltip') tooltip: DwToolTipComponent; // [For tooltip]
  tooltipTitle: string; // [For tooltip]
  style: object = {};

  constructor(private _slider: DwSliderComponent) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dwOffset) {
      this._updateStyle();
    }
    if (changes.dwValue) {
      this._updateTooltipTitle(); // [For tooltip]
      this._updateTooltipPosition(); // [For tooltip]
    }
  }

  // Hover to toggle tooltip when not dragging
  @HostListener('mouseenter', [ '$event' ])
  onMouseEnter($event: MouseEvent): void {
    if (!this._slider.isDragging) {
      this.dwActive = true;
    }
  }

  @HostListener('mouseleave', [ '$event' ])
  onMouseLeave($event: MouseEvent): void {
    if (!this._slider.isDragging) {
      this.dwActive = false;
    }
  }

  private _updateTooltipTitle(): void { // [For tooltip]
    this.tooltipTitle = this.dwTipFormatter ? this.dwTipFormatter(this.dwValue) : `${this.dwValue}`;
  }

  private _updateTooltipPosition(): void { // [For tooltip]
    if (this.tooltip) {
      window.setTimeout(() => this.tooltip.updatePosition(), 0); // MAY use ngAfterViewChecked? but this will be called so many times.
    }
  }

  private _updateStyle(): void {
    this.style[ this.dwVertical ? 'bottom' : 'left' ] = `${this.dwOffset}%`;
  }
}
