import {Component, ElementRef, OnDestroy, OnInit, Optional, ViewChild} from '@angular/core';
import {ICellEditorAngularComp} from 'ag-grid-angular';
import {ICellEditorParams} from 'ag-grid-community';
import {ConnectableObservable, Observable, of, Subscription} from 'rxjs';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {FxDapHttpClient} from '../../../fx-http-client';
import {FxModalService} from '../../../components/fx-modal/fx-modal.service';
import {DOWN_ARROW, ENTER, TAB, UP_ARROW} from '@angular/cdk/keycodes';
import {concatMap, publishLast} from 'rxjs/operators';
import {CfcValidationEditorComponent} from '../cfc-validation-editor/cfc-validation-editor.component';
import { FxAgGridValidationEditorComponent } from 'app/implementation/shared/ag-grid/fx-ag-grid-validation-editor';

@Component({
  selector: 'app-cfc-button-editor',
  templateUrl: './cfc-button-editor.component.html',
  styleUrls: ['./cfc-button-editor.component.less'],

})
export class CfcButtonEditorComponent extends CfcValidationEditorComponent implements ICellEditorAngularComp, OnDestroy {
// export class CfcButtonEditorComponent extends FxAgGridValidationEditorComponent implements ICellEditorAngularComp, OnDestroy {
  // 行编辑下，当前行的 formGroup
  rowFormGroup: FormGroup;
  // 本栏位的 formControlName
  formControlName: string;
  // 栏位名字
  columnName: string;
  // 当前行 (rowData) 的 id 值
  // private rowId: number;

  // 控件 disable 表达式
  disabledExpr: Function;

  // 模态框工厂方法
  modalService;
  // 模态框可以多选
  multiSelection = false;

  @ViewChild('cellInput') cellInput: ElementRef;

  // cell editorParams
  editorParams: any;  // 存放cellEditorParams   Added By David Lee 18Feb2019

  message: string;

  dwDisabled: boolean = false;  // add by canon

  matOptionShow: boolean = false;  // add by quwj
  options: Observable<any>;

  isBackendPaging: Boolean = false; // 是否后端分页，默认非后端分页

  isReGetDropDownData: boolean = true; // 是否重新获取开窗下拉的值，默认true
  activeId: any; // 记录鼠标移动到的数据id

  constructor(public http: FxDapHttpClient, public fxModalService: FxModalService) {
    super();
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.keyCode === 13 || event.keyCode === 27 || event.keyCode === 37 || event.keyCode === 38
      || event.keyCode === 39 || event.keyCode === 40) {
      event.stopPropagation();
    }

    if (event.keyCode === ENTER || event.keyCode === TAB) {
      if (this.activeId && this.activeId !== this.rowFormGroup.get(this.formControlName).value && this.matOptionShow && !(this.editorParams['param'](this.rowFormGroup))['disableCheck'] && !this.isBackendPaging) {
        this.rowFormGroup.get(this.formControlName).patchValue(this.activeId);
      }
      this.cellInput.nativeElement.onchange();
    }
    // 绑定上下键事件
    if (event.keyCode === DOWN_ARROW || event.keyCode === UP_ARROW) {
      if (!this.activeId) {
        this.options.subscribe(data => {
          if (data.length) {
            this.onMouseEnter(data[0]);
          }
        });
      } else {
        this.options.subscribe(data => {
          if (data.length) {
            const idx = data.findIndex(item => {
              return item.id === this.activeId;
            });
            if (idx !== -1) {
              const newIdx = event.keyCode === DOWN_ARROW ? (idx + 1) : (idx - 1);
              if (newIdx <= (data.length - 1) && newIdx >= 0) {
                this.onMouseEnter(data[newIdx]);
                this.scrollIntoView(newIdx);
              } else if (newIdx <= (data.length - 1) && newIdx < 0) {
                this.onMouseEnter(data[data.length - 1]);
                this.scrollIntoView(data.length - 1);
              } else {
                this.onMouseEnter(data[0]);
                this.scrollIntoView(0);
              }
            } else {
              this.onMouseEnter(data[0]);
              this.scrollIntoView(0);
            }
          }
        });
      }
    }
  }

  // 滚动条位置
  scrollIntoView = (idx): void => {
    const height = 32; // 下拉每条数据的高度
    if (document.querySelector('.mat-autocomplete-panel')) {
      document.querySelector('.mat-autocomplete-panel').scrollTop = (height * idx);
    }
  }

  // gets called once after the editor is created
  agInit(params: ICellEditorParams | any): void {
    this.columnName = params.column.colDef.headerName;
    this.formControlName = params.column.colId;
    this.rowId = params.node.id;
    // const rowFromGroup = params.context.componentParent.rowCachedFormGroup;
    // todo 这里改为 params.context.rowCachedFormGroup;
    this.rowFormGroup = params.context.serviceObj.rowCachedFormGroup;

    this.disabledExpr = params.disabledExpr
      ? ((param: any): string => (params.disabledExpr(param) ? 'disabled' : null))
      : ((param: any): string => null);

    this.editorParams = params;

    // 如果 this.editorParams.params 不是 Function ，包装成 Function
    if (this.editorParams && this.editorParams.param && (typeof this.editorParams.param !== 'function')) {
        const result = this.editorParams.param;
        this.editorParams.param = (param: any): any => result;
    }

    // 目前只支持行编辑模式
    this.editType = params.api ? params.api.gridOptionsWrapper.gridOptions.editType || '' : 'fullRow';


    const currentValue = this.valueTransfer(params.value);
    this.modalService = params['modalService'];

    this.dwDisabled = params.dwDisabled;

    // if (this.cellInput) {
      // 通过订阅消息回传onFocus事件
      this.cellInput.nativeElement.onfocus = (e: any): void => {
        this.getDropDownList();
        this.matOptionShow = true;
        this.onFocus();
      };

      // 通过订阅消息回传onChange事件
      this.cellInput.nativeElement.onchange = (event): void => {
        this.getDropDownList();
      };

      // 通过订阅消息回传onBlur事件
      this.cellInput.nativeElement.onblur = (e: any): void => {
        this.matOptionShow = false;
        this.onBlur();
      };
    // }

    super.init();
  }

  openModal(): void {
    try {
      (this.editorParams as any).openModelFn({
        value: this.rowFormGroup.get(this.formControlName).value,
        id: this.rowId
      });
    } catch (ex) {
      console.error(ex);
    }
  }

  openModal2(): void {
    try {
      (this.editorParams as any).openModelFn2({
        value: this.rowFormGroup.get(this.formControlName).value,
        id: this.rowId
      });
    } catch (ex) {
      console.error(ex);
    }
  }

  onFocus(): void {
    try {
      if ((this.editorParams as any).onFocusFn) {
        (this.editorParams as any).onFocusFn({
          value: this.rowFormGroup.get(this.formControlName).value,
          id: this.rowId
        });
      }
    } catch (ex) {
      console.error(ex);
    }
  }
  onBlur(): void {
    try {
      if ((this.editorParams as any).onBlurFn) {
        (this.editorParams as any).onBlurFn({
          value: this.rowFormGroup.get(this.formControlName).value,
          id: this.rowId
        });
      }
    } catch (ex) {
      console.error(ex);
    }
  }

  // Gets called once after GUI is attached to DOM.
  // Useful if you want to focus or highlight a component
  // (this is not possible when the element is not attached)
  afterGuiAttached(): void {
    if (this.cellInput && !this.isFullRowEdit()) {
      this.cellInput.nativeElement.focus();
    }
  }

  private valueTransfer(value: any): any {
    let currentValue = value;
    if (this.multiSelection) {
      if (typeof value === 'string') {
        currentValue = value.split(',').map(
          v => v.trim()
        );
      }
    } else {
      if (Array.isArray(value)) {
        currentValue = value.join();
      }
    }
    return currentValue;
  }

  getSelectedValue(): any {
    return this.rowFormGroup.get(this.formControlName).value;
  }

  setSelectedValue(value: any): any {
    this.rowFormGroup.get(this.formControlName).patchValue(value);
  }

  // If doing full row edit, then gets called when tabbing into the cell.
  focusIn(): void {
    this.cellInput.nativeElement.focus();
  }

  // If doing full row edit, then gets called when tabbing out of the cell.
  focusOut(): void {
    this.cellInput.nativeElement.blur();
  }

  // Should return the final value to the grid, the result of the editing
  getValue(): any {
    return this.getSelectedValue();
  }

  // Gets called once before editing starts, to give editor a chance to
  // cancel the editing before it even starts.
  isCancelBeforeStart(): boolean {
    return false;
  }

  // Gets called once when editing is finished (eg if enter is pressed).
  // If you return true, then the result of the edit will be ignored.
  isCancelAfterEnd(): boolean {
    return this.validationMessages.length > 0;
  }

  // Gets called once after initialised.
  // If you return true, the editor will appear in a popup
  isPopup(): boolean {
    return false;
  }

  ngOnDestroy(): void {
    super.destroy();
  }

  // 获取下拉获取数据
  getData(): Observable<any> {
    return Observable.create(observer => {
      const dropDownParam = (this.editorParams['param'](this.rowFormGroup))['dropDownParam'];
      this.fxModalService.searchFx(dropDownParam).subscribe(
        (data) => {
          const result = data.map(item => {
            item.id = item[(this.editorParams['param'](this.rowFormGroup))['id']];
            item.name = item[(this.editorParams['param'](this.rowFormGroup))['name']];
            return item;
          });
          // 后端分页则不用下拉否则使用下拉
          if (result[0].isBackendPaging) {
            this.isBackendPaging = true;
            observer.next([]);
          } else {
            observer.next(result);
          }
          observer.complete();
        },
        (error) => {
          observer.next([]);
          observer.complete();
        }
      );
    });
  }

  // 设置开窗下拉列表数据
  getDropDownList(): void {
    // 不设置pram则不会有下拉
    if (!this.editorParams['param']) {
      return;
    }
    if (!this.isReGetDropDownData && !(this.editorParams['param'](this.rowFormGroup))['reGetData'] || (this.editorParams['param'](this.rowFormGroup))['disableCheck'] || this.isBackendPaging) {
      return;
    }
    this.isReGetDropDownData = false;
    if (!(this.editorParams['param'](this.rowFormGroup))['customMethod']) {
      this.options = this.getData().pipe(publishLast());
      (this.options as ConnectableObservable<any>).connect();
    } else {
      this.options = this.http.post((this.editorParams['param'](this.rowFormGroup))['customMethod'], (this.editorParams['param'](this.rowFormGroup))['customParam'])
        .pipe(concatMap(res => {
          let data;  // 存放服务返回的数据

          data = res.data || [];
          const result = data.map(item => {
            item.id = item[(this.editorParams['param'](this.rowFormGroup))['id']];
            item.name = item[(this.editorParams['param'](this.rowFormGroup))['name']];
            return item;
          });
          return of(result);
        })).pipe(
          publishLast()
        );
      (this.options as ConnectableObservable<any>).connect();
    }
  }

  // 鼠标移入设置activeId
  onMouseEnter(item: any, event?: any): void {
    this.activeId = item.id;
  }

  // 点击下拉框的数据给input赋值
  setData(option: any): void {
    if (this.rowFormGroup.get(this.formControlName).value !== option.id) {
      this.setSelectedValue(option.id);
      this.cellInput.nativeElement.onchange();
    }
  }

}
