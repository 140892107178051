import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DwButtonModule } from '../button/dw-button.module';
import { LoggerModule } from '../core/util/logger/logger.module';
import { DwI18nModule } from '../i18n/dw-i18n.module';

import { CssUnitPipe } from './css-unit.pipe';
import { DwModalControlService } from './dw-modal-control.service';
import { DwModalComponent } from './dw-modal.component';
import { DwModalService } from './dw-modal.service';

@NgModule({
  imports: [ CommonModule, OverlayModule, DwI18nModule, DwButtonModule, LoggerModule ],
  exports: [ DwModalComponent ],
  declarations: [ DwModalComponent, CssUnitPipe ],
  entryComponents: [ DwModalComponent ],
  providers: [ DwModalControlService, DwModalService ]
})
export class DwModalModule { }
