import { InjectionToken } from '@angular/core';

export interface DwMessageConfig {
  // For all messages as default config (can override when dynamically created)
  dwDuration?: number;
  dwPauseOnHover?: boolean;
  dwAnimate?: boolean;
  // For message container only
  dwMaxStack?: number;
  /* tslint:disable-next-line:no-any */
  [index: string]: any;
}

export const DW_MESSAGE_DEFAULT_CONFIG = new InjectionToken<DwMessageConfig>('DW_MESSAGE_DEFAULT_CONFIG');

export const DW_MESSAGE_CONFIG = new InjectionToken<DwMessageConfig>('DW_MESSAGE_CONFIG');

export const DW_MESSAGE_DEFAULT_CONFIG_PROVIDER = {
  provide : DW_MESSAGE_DEFAULT_CONFIG,
  useValue: {
    dwDuration    : 3000,
    dwAnimate     : true,
    dwPauseOnHover: true,
    dwMaxStack    : 7
  }
};
