import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DwMentionSuggestionDirective } from './mention-suggestions';
import { DwMentionTriggerDirective } from './mention-trigger';
import { DwMentionComponent } from './mention.component';

const COMPONENTS = [DwMentionComponent, DwMentionTriggerDirective, DwMentionSuggestionDirective];

@NgModule({
  imports     : [ CommonModule, FormsModule, OverlayModule ],
  declarations: [ ...COMPONENTS ],
  exports     : [ ...COMPONENTS ]
})
export class DwMentionModule {
}
