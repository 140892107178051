import { Component } from '@angular/core';

@Component({
  selector           : 'dw-header',
  preserveWhitespaces: false,
  templateUrl        : './dw-header.component.html',
  styles             : [
    `:host {
      display: block;
    }`
  ],
  host               : {
    '[class.ant-layout-header]': 'true'
  }
})
export class DwHeaderComponent {
}
