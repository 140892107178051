import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DwDrawerOptions } from './dw-drawer-options';
import { DwDrawerRef } from './dw-drawer-ref';
import { DwDrawerComponent } from './dw-drawer.component';

export class DrawerBuilderForService<R> {
  private drawerRef: ComponentRef<DwDrawerComponent>;
  private overlayRef: OverlayRef;
  private unsubscribe$ = new Subject<void>();

  constructor(private overlay: Overlay, private options: DwDrawerOptions) {
    this.createDrawer();
    this.updateOptions(options);
    this.drawerRef.instance.dwOnViewInit
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(() => {
      this.drawerRef.instance.open();
    });

    this.drawerRef.instance.dwOnClose
    .subscribe(() => {
      this.drawerRef.instance.close();
    });

    this.drawerRef.instance.afterClose
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(() => {
      this.overlayRef.dispose();
      this.drawerRef = null;
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
    });
  }

  getInstance(): DwDrawerRef<R> {
    return this.drawerRef && this.drawerRef.instance;
  }

  createDrawer(): void {
    this.overlayRef = this.overlay.create();
    this.drawerRef = this.overlayRef.attach(new ComponentPortal(DwDrawerComponent));
  }

  updateOptions(options: DwDrawerOptions): void {
    Object.assign(this.drawerRef.instance, options);
  }
}

@Injectable({ providedIn: 'root'})
export class DwDrawerService {

  constructor(private overlay: Overlay) {
  }

  // tslint:disable-next-line:no-any
  create<T = any, D = any, R = any>(options: DwDrawerOptions<T, D>): DwDrawerRef<R> {
    return new DrawerBuilderForService<R>(this.overlay, options).getInstance();
  }
}
