import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DwButtonModule } from '../button/dw-button.module';
import { DwI18nModule } from '../i18n/dw-i18n.module';

import { DwPopconfirmComponent } from './dw-popconfirm.component';
import { DwPopconfirmDirective } from './dw-popconfirm.directive';

@NgModule({
  declarations   : [ DwPopconfirmComponent, DwPopconfirmDirective ],
  exports        : [ DwPopconfirmComponent, DwPopconfirmDirective ],
  imports        : [ CommonModule, DwButtonModule, OverlayModule, DwI18nModule ],
  entryComponents: [ DwPopconfirmComponent ]

})

export class DwPopconfirmModule {
}
