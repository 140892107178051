import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DwTreeModule } from '../tree/dw-tree.module';
import { DwTreeSelectComponent } from './dw-tree-select.component';

@NgModule({
  imports     : [ CommonModule, OverlayModule, FormsModule, DwTreeModule ],
  declarations: [ DwTreeSelectComponent ],
  exports     : [ DwTreeSelectComponent ]
})
export class DwTreeSelectModule {
}
