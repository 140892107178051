import {
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InputBoolean } from '../core/util/convert';
import { DwDatePickerI18nInterface } from '../i18n/dw-i18n.interface';
import { DwI18nService } from '../i18n/dw-i18n.service';
import { CandyDate } from './lib/candy-date';
import { DwPickerComponent } from './picker.component';

const POPUP_STYLE_PATCH = { 'position': 'relative' }; // Aim to override antd's style to support overlay's position strategy (position:absolute will cause it not working beacuse the overlay can't get the height/width of it's content)

/**
 * The base picker for all common APIs
 */
export abstract class AbstractPickerComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
  // --- Common API
  @Input() @InputBoolean() dwAllowClear: boolean = true;
  @Input() @InputBoolean() dwAutoFocus: boolean = false;
  @Input() @InputBoolean() dwDisabled: boolean = false;
  @Input() @InputBoolean() dwOpen: boolean;
  @Input() dwClassName: string;
  @Input() dwDisabledDate: (d: Date) => boolean;
  @Input() dwLocale: DwDatePickerI18nInterface;
  @Input() dwPlaceHolder: string | string[];
  @Input() dwPopupStyle: object = POPUP_STYLE_PATCH;
  @Input() dwDropdownClassName: string;
  @Input() dwSize: 'large' | 'small';
  @Input() dwStyle: object;
  @Output() dwOnOpenChange = new EventEmitter<boolean>();

  @Input() dwFormat: string;

  @Input() dwValue: CompatibleValue;

  @ViewChild(DwPickerComponent) protected picker: DwPickerComponent;

  isRange: boolean = false; // Indicate whether the value is a range value

  get realOpenState(): boolean {
    return this.picker.animationOpenState;
  } // Use picker's real open state to let re-render the picker's content when shown up

  initValue(): void {
    this.dwValue = this.isRange ? [] : null;
  }

  protected destroyed$: Subject<void> = new Subject();
  protected isCustomPlaceHolder: boolean = false;

  constructor(protected i18n: DwI18nService) {
  }

  ngOnInit(): void {
    // Subscribe the every locale change if the dwLocale is not handled by user
    if (!this.dwLocale) {
      this.i18n.localeChange
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => this.setLocale());
    }

    // Default value
    this.initValue();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dwPopupStyle) { // Always assign the popup style patch
      this.dwPopupStyle = this.dwPopupStyle ? { ...this.dwPopupStyle, ...POPUP_STYLE_PATCH } : POPUP_STYLE_PATCH;
    }

    // Mark as customized placeholder by user once dwPlaceHolder assigned at the first time
    if (changes.dwPlaceHolder && changes.dwPlaceHolder.firstChange && typeof this.dwPlaceHolder !== 'undefined') {
      this.isCustomPlaceHolder = true;
    }

    if (changes.dwLocale) { // The dwLocale is currently handled by user
      this.setDefaultPlaceHolder();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  closeOverlay(): void {
    this.picker.hideOverlay();
  }

  /**
   * Common handle for value changes
   * @param value changed value
   */
  onValueChange(value: CompatibleValue): void {
    this.dwValue = value;
    if (this.isRange) {
      if ((this.dwValue as CandyDate[]).length) {
        this.onChangeFn([ this.dwValue[ 0 ].nativeDate, this.dwValue[ 1 ].nativeDate ]);
      } else {
        this.onChangeFn([]);
      }
    } else {
      if (this.dwValue) {
        this.onChangeFn((this.dwValue as CandyDate).nativeDate);
      } else {
        this.onChangeFn(null);
      }
    }
    this.onTouchedFn();
  }

  /**
   * Triggered when overlayOpen changes (different with realOpenState)
   * @param open The overlayOpen in picker component
   */
  onOpenChange(open: boolean): void {
    this.dwOnOpenChange.emit(open);
  }

  // ------------------------------------------------------------------------
  // | Control value accessor implements
  // ------------------------------------------------------------------------

  // NOTE: onChangeFn/onTouchedFn will not be assigned if user not use as ngModel
  onChangeFn: (val: CompatibleDate) => void = () => void 0;
  onTouchedFn: () => void = () => void 0;

  writeValue(value: CompatibleDate): void {
    this.setValue(value);
  }

  registerOnChange(fn: any): void { // tslint:disable-line:no-any
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any): void { // tslint:disable-line:no-any
    this.onTouchedFn = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.dwDisabled = disabled;
  }

  // ------------------------------------------------------------------------
  // | Internal methods
  // ------------------------------------------------------------------------

  // Reload locale from i18n with side effects
  private setLocale(): void {
    this.dwLocale = this.i18n.getLocaleData('DatePicker', {});
    this.setDefaultPlaceHolder();
  }

  private setDefaultPlaceHolder(): void {
    if (!this.isCustomPlaceHolder && this.dwLocale) {
      this.dwPlaceHolder = this.isRange ? this.dwLocale.lang.rangePlaceholder : this.dwLocale.lang.placeholder;
    }
  }

  private formatDate(date: CandyDate): string {
    return date ? this.i18n.formatDateCompatible(date.nativeDate, this.dwFormat) : '';
  }

  // Safe way of setting value with default
  private setValue(value: CompatibleDate): void {
    if (this.isRange) {
      this.dwValue = value ? (value as Date[]).map(val => new CandyDate(val)) : [];
    } else {
      this.dwValue = value ? new CandyDate(value as Date) : null;
    }
  }
}

export type CompatibleValue = CandyDate | CandyDate[];

export type CompatibleDate = Date | Date[];
