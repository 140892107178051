import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { toBoolean } from '../core/util/convert';

@Component({
  selector           : 'dw-slider-track',
  preserveWhitespaces: false,
  templateUrl        : './dw-slider-track.component.html'
})
export class DwSliderTrackComponent implements OnChanges {
  private _vertical = false;
  private _included = false;

  // Dynamic properties
  @Input() dwOffset;
  @Input() dwLength;

  // Static properties
  @Input() dwClassName;

  @Input()
  set dwVertical(value: boolean) { // Required
    this._vertical = toBoolean(value);
  }

  get dwVertical(): boolean {
    return this._vertical;
  }

  @Input()
  set dwIncluded(value: boolean) {
    this._included = toBoolean(value);
  }

  get dwIncluded(): boolean {
    return this._included;
  }

  style: { bottom?: string, height?: string, left?: string, width?: string, visibility?: string } = {};

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dwIncluded) {
      this.style.visibility = this.dwIncluded ? 'visible' : 'hidden';
    }
    if (changes.dwVertical || changes.dwOffset || changes.dwLength) {
      if (this.dwVertical) {
        this.style.bottom = `${this.dwOffset}%`;
        this.style.height = `${this.dwLength}%`;
      } else {
        this.style.left = `${this.dwOffset}%`;
        this.style.width = `${this.dwLength}%`;
      }
    }
  }

}
