import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { DwInputGroupComponent } from './dw-input-group.component';
import { DwInputDirective } from './dw-input.directive';

@NgModule({
  declarations: [ DwInputDirective, DwInputGroupComponent ],
  exports     : [ DwInputDirective, DwInputGroupComponent ],
  imports     : [ CommonModule, FormsModule ]
})
export class DwInputModule {
}
