import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input, NgZone, OnDestroy, OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';

import { DwUpdateHostClassService } from '../core/services/update-host-class.service';
import { isEmpty } from '../core/util/check';
import { toBoolean } from '../core/util/convert';
import { DwWaveDirective } from '../core/wave/dw-wave.directive';

export type DwButtonType = 'primary' | 'dashed' | 'danger';
export type DwButtonShape = 'circle' | null ;
export type DwButtonSize = 'small' | 'large' | 'default' ;

@Component({
  selector           : '[dw-button]',
  providers          : [ DwUpdateHostClassService ],
  preserveWhitespaces: false,
  templateUrl        : './dw-button.component.html'
})
export class DwButtonComponent implements AfterContentInit, OnInit, OnDestroy {
  private _ghost = false;
  private _search = false;
  private _type: DwButtonType;
  private _shape: DwButtonShape;
  private _size: DwButtonSize;
  private _loading = false;
  private _block = false;
  private el: HTMLElement;
  private iconElement: HTMLElement;
  private iconOnly = false;
  private prefixCls = 'ant-btn';
  private sizeMap = { large: 'lg', small: 'sm' };
  @ViewChild('contentElement') contentElement: ElementRef;

  @Input()
  set dwBlock(value: boolean) {
    this._block = toBoolean(value);
    this.setClassMap();
  }

  get dwBlock(): boolean {
    return this._block;
  }

  @Input()
  set dwGhost(value: boolean) {
    this._ghost = toBoolean(value);
    this.setClassMap();
  }

  get dwGhost(): boolean {
    return this._ghost;
  }

  @Input()
  set dwSearch(value: boolean) {
    this._search = toBoolean(value);
    this.setClassMap();
  }

  get dwSearch(): boolean {
    return this._search;
  }

  @Input()
  get dwType(): DwButtonType {
    return this._type;
  }

  set dwType(value: DwButtonType) {
    this._type = value;
    this.setClassMap();
  }

  @Input()
  get dwShape(): DwButtonShape {
    return this._shape;
  }

  set dwShape(value: DwButtonShape) {
    this._shape = value;
    this.setClassMap();
  }

  @Input()
  set dwSize(value: DwButtonSize) {
    this._size = value;
    this.setClassMap();
  }

  get dwSize(): DwButtonSize {
    return this._size;
  }

  @Input()
  set dwLoading(value: boolean) {
    this._loading = toBoolean(value);
    this.setClassMap();
    this.updateIconDisplay(value);
  }

  get dwLoading(): boolean {
    return this._loading;
  }

  @HostBinding('attr.dw-wave') dwWave = new DwWaveDirective(this.ngZone, this.elementRef);

  updateIconDisplay(value: boolean): void {
    if (this.iconElement) {
      this.renderer.setStyle(this.iconElement, 'display', value ? 'none' : 'inline-block');
    }
  }

  /** temp solution since no method add classMap to host https://github.com/angular/angular/issues/7289 */
  setClassMap(): void {
    const classMap = {
      [ `${this.prefixCls}-${this.dwType}` ]                : this.dwType,
      [ `${this.prefixCls}-${this.dwShape}` ]               : this.dwShape,
      [ `${this.prefixCls}-${this.sizeMap[ this.dwSize ]}` ]: this.sizeMap[ this.dwSize ],
      [ `${this.prefixCls}-loading` ]                       : this.dwLoading,
      [ `${this.prefixCls}-icon-only` ]                     : this.iconOnly,
      [ `${this.prefixCls}-background-ghost` ]              : this.dwGhost,
      [ `ant-input-search-button` ]                         : this.dwSearch,
      [ `ant-btn-block` ]                                   : this.dwBlock
    };
    this.dwUpdateHostClassService.updateHostClass(this.el, classMap);
  }

  checkContent(): void {
    this.moveIcon();
    this.renderer.removeStyle(this.contentElement.nativeElement, 'display');
    /** https://github.com/angular/angular/issues/12530 **/
    if (isEmpty(this.contentElement.nativeElement)) {
      this.renderer.setStyle(this.contentElement.nativeElement, 'display', 'none');
      this.iconOnly = !!this.iconElement;
    } else {
      this.renderer.removeStyle(this.contentElement.nativeElement, 'display');
      this.iconOnly = false;
    }
    this.setClassMap();
    this.updateIconDisplay(this.dwLoading);
    this.cdr.detectChanges();
  }

  moveIcon(): void {
    const firstChildElement = this.findFirstNotEmptyNode(this.contentElement.nativeElement);
    const lastChildElement = this.findLastNotEmptyNode(this.contentElement.nativeElement);
    if (firstChildElement && (firstChildElement.nodeName === 'I')) {
      this.renderer.insertBefore(this.el, firstChildElement, this.contentElement.nativeElement);
      this.iconElement = firstChildElement as HTMLElement;
    } else if (lastChildElement && (lastChildElement.nodeName === 'I')) {
      this.renderer.appendChild(this.el, lastChildElement);
      this.iconElement = lastChildElement as HTMLElement;
    } else {
      this.iconElement = null;
    }
  }

  findFirstNotEmptyNode(value: HTMLElement): Node {
    const children = value.childNodes;
    for (let i = 0; i < children.length; i++) {
      const node = children.item(i);
      if (node && (node.nodeType === 1) && ((node as HTMLElement).outerHTML.toString().trim().length !== 0)) {
        return node;
      } else if (node && (node.nodeType === 3) && ((node.textContent.toString().trim().length !== 0))) {
        return node;
      }
    }
    return null;
  }

  findLastNotEmptyNode(value: HTMLElement): Node {
    const children = value.childNodes;
    for (let i = children.length - 1; i >= 0; i--) {
      const node = children.item(i);
      if (node && (node.nodeType === 1) && ((node as HTMLElement).outerHTML.toString().trim().length !== 0)) {
        return node;
      } else if (node && (node.nodeType === 3) && ((node.textContent.toString().trim().length !== 0))) {
        return node;
      }
    }
    return null;
  }

  constructor(private elementRef: ElementRef, private cdr: ChangeDetectorRef, private renderer: Renderer2, private dwUpdateHostClassService: DwUpdateHostClassService, private ngZone: NgZone) {
    this.el = this.elementRef.nativeElement;
    this.renderer.addClass(this.el, this.prefixCls);
  }

  ngAfterContentInit(): void {
    this.checkContent();
  }

  ngOnInit(): void {
    this.dwWave.ngOnInit();
  }

  ngOnDestroy(): void {
    this.dwWave.ngOnDestroy();
  }
}
