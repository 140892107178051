import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SCROLL_SERVICE_PROVIDER } from '../core/scroll/dw-scroll.service';

import { DwBackTopComponent } from './dw-back-top.component';

@NgModule({
  declarations: [ DwBackTopComponent ],
  exports     : [ DwBackTopComponent ],
  imports     : [ CommonModule ],
  providers   : [ SCROLL_SERVICE_PROVIDER ]
})
export class DwBackTopModule {
}
