import { ObserversModule } from '@angular/cdk/observers';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DwWaveModule } from '../core/wave/dw-wave.module';

import { DwButtonGroupComponent } from './dw-button-group.component';
import { DwButtonComponent } from './dw-button.component';

@NgModule({
  declarations   : [ DwButtonComponent, DwButtonGroupComponent ],
  exports        : [ DwButtonComponent, DwButtonGroupComponent ],
  imports        : [ CommonModule, ObserversModule, DwWaveModule ]
})
export class DwButtonModule {
}
