import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef
} from '@angular/core';

import { of, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { toBoolean } from '../core/util/convert';
import { DwI18nService } from '../i18n/dw-i18n.service';

import { TransferCanMove, TransferChange, TransferItem, TransferSearchChange, TransferSelectChange } from './interface';

@Component({
  selector           : 'dw-transfer',
  preserveWhitespaces: false,
  templateUrl        : './dw-transfer.component.html',
  host               : {
    '[class.ant-transfer]': 'true'
  }
})
export class DwTransferComponent implements OnInit, OnChanges, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  // tslint:disable-next-line:no-any
  locale: any = {};
  private _showSearch = false;

  leftFilter = '';
  rightFilter = '';

  // region: fields

  @Input() dwDataSource: TransferItem[] = [];
  @Input() dwTitles: string[] = [ '', '' ];
  @Input() dwOperations: string[] = [];
  @Input() dwListStyle: object;
  @Input() dwItemUnit: string;
  @Input() dwItemsUnit: string;
  @Input() dwCanMove: (arg: TransferCanMove) => Observable<TransferItem[]> = (arg: TransferCanMove) => of(arg.list);
  @Input() dwRender: TemplateRef<void>;
  @Input() dwFooter: TemplateRef<void>;

  // search
  @Input()
  set dwShowSearch(value: boolean) {
    this._showSearch = toBoolean(value);
  }

  get dwShowSearch(): boolean {
    return this._showSearch;
  }

  @Input() dwFilterOption: (inputValue: string, item: TransferItem) => boolean;
  @Input() dwSearchPlaceholder: string;
  @Input() dwNotFoundContent: string;

  // events
  @Output() dwChange: EventEmitter<TransferChange> = new EventEmitter();
  @Output() dwSearchChange: EventEmitter<TransferSearchChange> = new EventEmitter();
  @Output() dwSelectChange: EventEmitter<TransferSelectChange> = new EventEmitter();

  // endregion

  // region: process data

  // left
  leftDataSource: TransferItem[] = [];

  // right
  rightDataSource: TransferItem[] = [];

  private splitDataSource(): void {
    this.leftDataSource = [];
    this.rightDataSource = [];
    this.dwDataSource.forEach(record => {
      if (record.direction === 'right') {
        this.rightDataSource.push(record);
      } else {
        this.leftDataSource.push(record);
      }
    });
  }

  private getCheckedData(direction: string): TransferItem[] {
    return this[ direction === 'left' ? 'leftDataSource' : 'rightDataSource' ].filter(w => w.checked);
  }

  handleLeftSelectAll = (checked: boolean) => this.handleSelect('left', checked);
  handleRightSelectAll = (checked: boolean) => this.handleSelect('right', checked);

  handleLeftSelect = (item: TransferItem) => this.handleSelect('left', item.checked, item);
  handleRightSelect = (item: TransferItem) => this.handleSelect('right', item.checked, item);

  handleSelect(direction: 'left' | 'right', checked: boolean, item?: TransferItem): void {
    const list = this.getCheckedData(direction);
    this.updateOperationStatus(direction, list.length);
    this.dwSelectChange.emit({ direction, checked, list, item });
  }

  handleFilterChange(ret: { direction: string, value: string }): void {
    this.dwSearchChange.emit(ret);
  }

  // endregion

  // region: operation

  leftActive = false;
  rightActive = false;

  private updateOperationStatus(direction: string, count?: number): void {
    this[ direction === 'right' ? 'leftActive' : 'rightActive' ] = (typeof count === 'undefined' ? this.getCheckedData(direction).filter(w => !w.disabled).length : count) > 0;
  }

  moveToLeft = () => this.moveTo('left');
  moveToRight = () => this.moveTo('right');

  moveTo(direction: string): void {
    const oppositeDirection = direction === 'left' ? 'right' : 'left';
    this.updateOperationStatus(oppositeDirection, 0);
    const datasource = direction === 'left' ? this.rightDataSource : this.leftDataSource;
    const moveList = datasource.filter(item => item.checked === true && !item.disabled);
    this.dwCanMove({ direction, list: moveList })
    .subscribe(
      newMoveList => this.truthMoveTo(direction, newMoveList.filter(i => !!i)),
      () => moveList.forEach(i => i.checked = false)
    );
  }

  private truthMoveTo(direction: string, list: TransferItem[]): void {
    const oppositeDirection = direction === 'left' ? 'right' : 'left';
    const datasource = direction === 'left' ? this.rightDataSource : this.leftDataSource;
    const targetDatasource = direction === 'left' ? this.leftDataSource : this.rightDataSource;
    for (const item of list) {
      item.checked = false;
      targetDatasource.push(item);
      datasource.splice(datasource.indexOf(item), 1);
    }
    this.updateOperationStatus(oppositeDirection);
    this.dwChange.emit({
      from: oppositeDirection,
      to  : direction,
      list
    });
  }

  // endregion

  constructor(private i18n: DwI18nService, private el: ElementRef) {
  }

  ngOnInit(): void {
    this.i18n.localeChange.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.locale = this.i18n.getLocaleData('Transfer'));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('dwDataSource' in changes) {
      this.splitDataSource();
      this.updateOperationStatus('left');
      this.updateOperationStatus('right');
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
