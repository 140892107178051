import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DwWaveModule } from '../core/wave/dw-wave.module';

import { DwSwitchComponent } from './dw-switch.component';

@NgModule({
  exports     : [ DwSwitchComponent ],
  declarations: [ DwSwitchComponent ],
  imports     : [ CommonModule, DwWaveModule ]
})
export class DwSwitchModule {
}
