import { Component, Inject, Optional } from '@angular/core';

import { DwMessageContainerComponent } from '../message/dw-message-container.component';

import { DwNotificationConfig, DW_NOTIFICATION_CONFIG, DW_NOTIFICATION_DEFAULT_CONFIG } from './dw-notification-config';

@Component({
  selector           : 'dw-notification-container',
  preserveWhitespaces: false,
  templateUrl        : './dw-notification-container.component.html'
})
export class DwNotificationContainerComponent extends DwMessageContainerComponent {

  constructor(@Optional() @Inject(DW_NOTIFICATION_DEFAULT_CONFIG) defaultConfig: DwNotificationConfig,
              @Optional() @Inject(DW_NOTIFICATION_CONFIG) config: DwNotificationConfig) {
    super(defaultConfig, config);
  }

}
