import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { DwButtonModule } from '../button/dw-button.module';
import { DwMenuModule } from '../menu/dw-menu.module';

import { DwDropDownButtonComponent } from './dw-dropdown-button.component';
import { DwDropdownContextComponent } from './dw-dropdown-context.component';
import { DwDropDownComponent } from './dw-dropdown.component';
import { DwDropDownDirective } from './dw-dropdown.directive';

@NgModule({
  imports        : [ CommonModule, OverlayModule, FormsModule, DwButtonModule, DwMenuModule ],
  declarations   : [ DwDropDownComponent, DwDropDownButtonComponent, DwDropDownDirective, DwDropdownContextComponent ],
  entryComponents: [ DwDropdownContextComponent ],
  exports        : [ DwDropDownComponent, DwDropDownButtonComponent, DwDropDownDirective ]
})
export class DwDropDownModule {
}
