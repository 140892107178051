import {
  Component,
  ContentChild,
  TemplateRef
} from '@angular/core';
import { fadeAnimation } from '../core/animation/fade-animations';
import { DwToolTipComponent } from '../tooltip/dw-tooltip.component';

@Component({
  selector           : 'dw-popover',
  animations         : [ fadeAnimation ],
  templateUrl        : './dw-popover.component.html',
  preserveWhitespaces: false,
  styles             : [ `
    .ant-popover {
      position: relative;
    }
  ` ]
})
export class DwPopoverComponent extends DwToolTipComponent {
  _prefix = 'ant-popover-placement';
  @ContentChild('neverUsedTemplate') _title: string | TemplateRef<void>; // used to remove DwToolTipComponent @ContentChild('dwTemplate')
  @ContentChild('dwTemplate') _content: string | TemplateRef<void>;
}
