import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DwCarouselContentDirective } from './dw-carousel-content.directive';
import { DwCarouselComponent } from './dw-carousel.component';

@NgModule({
  declarations: [ DwCarouselComponent, DwCarouselContentDirective ],
  exports     : [ DwCarouselComponent, DwCarouselContentDirective ],
  imports     : [ CommonModule ]
})
export class DwCarouselModule {
}
