import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { isNotNil } from '../core/util/check';

import { toBoolean } from '../core/util/convert';

import { DwDropDownComponent } from '../dropdown/dw-dropdown.component';

/* tslint:disable-next-line:no-any */
export type DwThFilterType = Array<{ text: string; value: any; byDefault?: boolean }>;

export interface DwThItemInterface {
  text: string;
  /* tslint:disable-next-line:no-any */
  value: any;
  checked: boolean;
}

@Component({
  // tslint:disable-next-line:component-selector
  selector           : 'th:not(.dw-disable-th)',
  preserveWhitespaces: false,
  templateUrl        : './dw-th.component.html'
})
export class DwThComponent {
  private _sort = null;
  private _filters: DwThFilterType = [];
  private _showSort = false;
  private _showFilter = false;
  private _showCheckbox = false;
  private _showRowSelection = false;
  private _hasDefaultFilter = false;
  el: HTMLElement;
  hasFilterValue = false;
  multipleFilterList: DwThItemInterface[] = [];
  singleFilterList: DwThItemInterface[] = [];
  /* tslint:disable-next-line:no-any */
  @Input() dwSelections: Array<{ text: string, onSelect: any }> = [];
  @Input() dwChecked = false;
  @Input() dwDisabled = false;
  @Input() dwIndeterminate = false;
  @Input() dwSortKey: string;
  @Input() dwFilterMultiple = true;
  @Input() dwWidth: string;
  @Output() dwCheckedChange = new EventEmitter<boolean>();
  @ViewChild(DwDropDownComponent) dwDropDownComponent: DwDropDownComponent;
  @Output() dwSortChange = new EventEmitter<string>();
  @Output() dwSortChangeWithKey = new EventEmitter<{ key: string, value: string }>();
  /* tslint:disable-next-line:no-any */
  @Output() dwFilterChange = new EventEmitter<any[] | any>();

  @HostBinding('class.ant-table-column-has-filters')
  get hasFiltersClass(): boolean {
    return this.dwShowSort || this.dwShowFilter;
  }

  @Input()
  set dwShowSort(value: boolean) {
    this._showSort = toBoolean(value);
  }

  get dwShowSort(): boolean {
    return this._showSort;
  }

  @Input()
  set dwShowFilter(value: boolean) {
    this._showFilter = toBoolean(value);
  }

  get dwShowFilter(): boolean {
    return this._showFilter;
  }

  @Input()
  set dwShowRowSelection(value: boolean) {
    this._showRowSelection = toBoolean(value);
    if (this._showRowSelection) {
      this.renderer.addClass(this.el, 'ant-table-selection-column-custom');
    } else {
      this.renderer.removeClass(this.el, 'ant-table-selection-column-custom');
    }
  }

  get dwShowRowSelection(): boolean {
    return this._showRowSelection;
  }

  @Input()
  set dwLeft(value: string) {
    if (isNotNil(value)) {
      this.renderer.addClass(this.el, 'ant-table-th-left-sticky');
      this.renderer.setStyle(this.el, 'left', value);
    } else {
      this.renderer.removeClass(this.el, 'ant-table-th-left-sticky');
      this.renderer.removeStyle(this.el, 'left');
    }
  }

  @Input()
  set dwRight(value: string) {
    if (isNotNil(value)) {
      this.renderer.addClass(this.el, 'ant-table-th-right-sticky');
      this.renderer.setStyle(this.el, 'right', value);
    } else {
      this.renderer.removeClass(this.el, 'ant-table-th-right-sticky');
      this.renderer.removeStyle(this.el, 'right');
    }
  }

  @Input()
  set dwExpand(value: boolean) {
    const isExpand = toBoolean(value);
    if (isExpand) {
      this.renderer.addClass(this.el, 'ant-table-expand-icon-th');
    } else {
      this.renderer.removeClass(this.el, 'ant-table-expand-icon-th');
    }
  }

  @Input()
  set dwShowCheckbox(value: boolean) {
    this._showCheckbox = toBoolean(value);
    if (this._showCheckbox) {
      this.renderer.addClass(this.el, 'ant-table-selection-column');
    } else {
      this.renderer.removeClass(this.el, 'ant-table-selection-column');
    }
  }

  get dwShowCheckbox(): boolean {
    return this._showCheckbox;
  }

  @Input()
  set dwSort(value: string) {
    this._sort = value;
    if ((value !== 'ascend') && (value !== 'descend')) {
      this.renderer.removeClass(this.el, 'ant-table-column-sort');
    } else {
      this.renderer.addClass(this.el, 'ant-table-column-sort');
    }
  }

  get dwSort(): string {
    return this._sort;
  }

  setSortValue(value: string): void {
    if (this.dwSort === value) {
      this.dwSort = null;
    } else {
      this.dwSort = value;
    }
    this.dwSortChangeWithKey.emit({ key: this.dwSortKey, value: this.dwSort });
    this.dwSortChange.emit(this.dwSort);
  }

  get filterList(): DwThItemInterface[] {
    return this.multipleFilterList.filter(item => item.checked).map(item => item.value);
  }

  /* tslint:disable-next-line:no-any */
  get filterValue(): any {
    const checkedFilter = this.singleFilterList.find(item => item.checked);
    return checkedFilter ? checkedFilter.value : null;
  }

  updateFilterStatus(): void {
    if (this.dwFilterMultiple) {
      this.hasFilterValue = this.filterList.length > 0;
    } else {
      this.hasFilterValue = isNotNil(this.filterValue);
    }
  }

  search(): void {
    this.updateFilterStatus();
    if (this.dwFilterMultiple) {
      this.dwFilterChange.emit(this.filterList);
    } else {
      this.dwFilterChange.emit(this.filterValue);
    }
    this.hideDropDown();
  }

  reset(): void {
    this.initMultipleFilterList(true);
    this.initSingleFilterList(true);
    this.search();
    this.hideDropDown();
    this.hasFilterValue = false;
  }

  checkMultiple(filter: DwThItemInterface): void {
    filter.checked = !filter.checked;
  }

  checkSingle(filter: DwThItemInterface): void {
    this.singleFilterList.forEach(item => item.checked = item === filter);
  }

  hideDropDown(): void {
    this.dwDropDownComponent.dwVisible = false;
    this.dwDropDownComponent.hide();
  }

  dropDownVisibleChange(value: boolean): void {
    if (!value) {
      this.search();
    }
  }

  @Input()
  set dwFilters(value: DwThFilterType) {
    if (Array.isArray(value)) {
      this._filters = value;
      this.initMultipleFilterList();
      this.initSingleFilterList();
      this.updateFilterStatus();
    } else {
      console.warn('dwFilters only accept type of Array<{ text: string; value: any }>');
    }
  }

  get dwFilters(): DwThFilterType {
    return this._filters;
  }

  initMultipleFilterList(force?: boolean): void {
    this.multipleFilterList = this.dwFilters.map(item => {
      const checked = force ? false : !!item.byDefault;
      if (checked) { this._hasDefaultFilter = true; }
      return { text: item.text, value: item.value, checked };
    });
    this.checkDefaultFilters();
  }

  initSingleFilterList(force?: boolean): void {
    this.singleFilterList = this.dwFilters.map(item => {
      const checked = force ? false : !!item.byDefault;
      if (checked) { this._hasDefaultFilter = true; }
      return { text: item.text, value: item.value, checked };
    });
    this.checkDefaultFilters();
  }

  checkDefaultFilters(): void {
    if (!this.dwFilters || this.dwFilters.length === 0 || !this._hasDefaultFilter) { return; }
    this.updateFilterStatus();
  }

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
    this.el = this.elementRef.nativeElement;
  }
}
