import { Component, ElementRef, Input } from '@angular/core';
import { isNotNil } from '../core/util/check';
import { DwOptionComponent } from './dw-option.component';

@Component({
  selector   : '[dw-option-li]',
  templateUrl: './dw-option-li.component.html',
  host       : {
    '[class.ant-select-dropdown-menu-item]'         : 'true',
    '[class.ant-select-dropdown-menu-item-selected]': 'selected && !dwOption.dwDisabled',
    '[class.ant-select-dropdown-menu-item-disabled]': 'dwOption.dwDisabled',
    '[class.ant-select-dropdown-menu-item-active]'  : 'active && !dwOption.dwDisabled && dwShowActive && !selected',
    '[attr.unselectable]'                           : '"unselectable"',
    '[style.user-select]'                           : '"none"'
  }
})
export class DwOptionLiComponent {
  el: Element;
  selected = false;
  active = false;
  @Input() dwOption: DwOptionComponent;
  @Input() dwShowActive = true;
  // tslint:disable-next-line:no-any
  @Input() compareWith: (o1: any, o2: any) => boolean;

  @Input()
  set dwActiveOption(value: DwOptionComponent) {
    if (value) {
      this.active = this.compareWith(value.dwValue, this.dwOption.dwValue);
    } else {
      this.active = false;
    }
  }

  @Input()
  // tslint:disable-next-line:no-any
  set dwListOfSelectedValue(valueList: any[]) {
    this.selected = isNotNil(valueList.find(v => this.compareWith(v, this.dwOption.dwValue)));
  }

  constructor(private elementRef: ElementRef) {
    this.el = elementRef.nativeElement;
  }
}
