import {
  ComponentFactory,
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Renderer2,
  ViewContainerRef
} from '@angular/core';

import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { toBoolean } from '../core/util/convert';
import { DwTooltipDirective } from '../tooltip/dw-tooltip.directive';
import { DwPopconfirmComponent } from './dw-popconfirm.component';

@Directive({
  selector: '[dw-popconfirm]'
})
export class DwPopconfirmDirective extends DwTooltipDirective implements OnInit, OnDestroy {
  private subclassUnsubscribe$ = new Subject<void>();

  factory: ComponentFactory<DwPopconfirmComponent> = this.resolver.resolveComponentFactory(DwPopconfirmComponent);
  _condition: boolean = false;
  _okText: string;
  _okType: string = 'primary';
  _cancelText: string;

  @Output() dwOnCancel: EventEmitter<void> = new EventEmitter();
  @Output() dwOnConfirm: EventEmitter<void> = new EventEmitter();

  @Input()
  set dwOkText(value: string) {
    this._okText = value;
    this.updateCompValue('dwOkText', value);
  }

  get dwOkText(): string {
    return this._okText;
  }

  @Input()
  set dwOkType(value: string) {
    this._okType = value;
    this.updateCompValue('dwOkType', value);
  }

  get dwOkType(): string {
    return this._okType;
  }

  @Input()
  set dwCancelText(value: string) {
    this._cancelText = value;
    this.updateCompValue('dwCancelText', value);
  }

  get dwCancelText(): string {
    return this._cancelText;
  }

  @Input()
  set dwCondition(value: boolean) {
    this._condition = toBoolean(value);
    this.updateCompValue('dwCondition', value);
  }

  get dwCondition(): boolean {
    return this._condition;
  }

  constructor(
    elementRef: ElementRef,
    hostView: ViewContainerRef,
    resolver: ComponentFactoryResolver,
    renderer: Renderer2,
    @Optional() tooltip: DwPopconfirmComponent) {
    super(elementRef, hostView, resolver, renderer, tooltip);
  }

  ngOnInit(): void {
    if (!this.tooltip) {
      const tooltipComponent = this.hostView.createComponent(this.factory);
      this.tooltip = tooltipComponent.instance;
      // Remove element when use directive https://github.com/NG-ZORRO/ng-zorro-antd/issues/1967
      this.renderer.removeChild(this.renderer.parentNode(this.elementRef.nativeElement), tooltipComponent.location.nativeElement);
      this.isDynamicTooltip = true;
      const properties = [
        'dwTitle',
        'dwContent',
        'dwOverlayClassName',
        'dwOverlayStyle',
        'dwMouseEnterDelay',
        'dwMouseLeaveDelay',
        'dwVisible',
        'dwTrigger',
        'dwPlacement',
        'dwOkText',
        'dwOkType',
        'dwCancelText',
        'dwCondition' ];
      properties.forEach(property => this.updateCompValue(property, this[ property ]));
      this.tooltip.dwVisibleChange.pipe(takeUntil(this.subclassUnsubscribe$), distinctUntilChanged()).subscribe(data => {
        this._visible = data;
        this.dwVisibleChange.emit(data);
      });
      (this.tooltip  as DwPopconfirmComponent).dwOnCancel.pipe(takeUntil(this.subclassUnsubscribe$)).subscribe(data => {
        this.dwOnCancel.emit();
      });
      (this.tooltip  as DwPopconfirmComponent).dwOnConfirm.pipe(takeUntil(this.subclassUnsubscribe$)).subscribe(data => {
        this.dwOnConfirm.emit();
      });
    }
    this.tooltip.setOverlayOrigin(this);
  }

  ngOnDestroy(): void {
    this.subclassUnsubscribe$.next();
    this.subclassUnsubscribe$.complete();
  }
}
