import {
  AfterContentInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output
} from '@angular/core';

import { toBoolean } from '../core/util/convert';

import { DwMenuItemDirective } from './dw-menu-item.directive';
import { DwSubMenuComponent } from './dw-submenu.component';

export type DwMode = 'vertical' | 'horizontal' | 'inline';

@Directive({
  selector: '[dw-menu]'
})

export class DwMenuDirective implements AfterContentInit {
  private _selectable = true;
  private _inlineCollapsed = false;
  private _inDropDown = false;
  /** view init flat */
  private isInit = false;
  /** cache mode */
  private cacheMode: DwMode;
  /** opened index of array */
  private subMenusOpenIndex = [];

  /** collection of menu item */
  menuItems: DwMenuItemDirective[] = [];
  /** collection of sub menu */
  subMenus: DwSubMenuComponent[] = [];
  @Input() dwTheme: 'light' | 'dark' = 'light';
  @Input() dwInlineIndent = 24;
  @Input() dwMode: DwMode = 'vertical';
  @Output() dwClick = new EventEmitter<DwMenuItemDirective>();

  @Input()
  set dwInDropDown(value: boolean) {
    this._inDropDown = toBoolean(value);
    this.dwSelectable = !this._inDropDown;
    this.menuItems.forEach(menu => menu.isInDropDown = this._inDropDown);
    this.subMenus.forEach(subMenu => subMenu.isInDropDown = this._inDropDown);
  }

  get dwInDropDown(): boolean {
    return this._inDropDown;
  }

  @Input()
  set dwSelectable(value: boolean) {
    this._selectable = toBoolean(value);
  }

  get dwSelectable(): boolean {
    return this._selectable;
  }

  @Input()
  set dwInlineCollapsed(value: boolean) {
    this._inlineCollapsed = toBoolean(value);
    if (this.isInit) {
      this.updateInlineCollapse();
    }
  }

  get dwInlineCollapsed(): boolean {
    return this._inlineCollapsed;
  }

  updateInlineCollapse(): void {
    if (this._inlineCollapsed) {
      this.hideSubMenus();
      this.dwMode = 'vertical';
    } else {
      this.reductionSubMenus();
      this.dwMode = this.cacheMode;
    }
  }

  /** define host class */
  @HostBinding('class.ant-dropdown-menu')
  @HostBinding('class.ant-menu-dropdown-vertical')
  @HostBinding('class.ant-dropdown-menu-root')
  get isInDropDownClass(): boolean {
    return this.dwInDropDown;
  }

  @HostBinding('class.ant-menu')
  @HostBinding('class.ant-menu-root')
  get isNotInDropDownClass(): boolean {
    return !this.dwInDropDown;
  }

  @HostBinding('class.ant-dropdown-menu-light')
  get setDropDownThemeLightClass(): boolean {
    return this.dwInDropDown && (this.dwTheme === 'light');
  }

  @HostBinding('class.ant-dropdown-menu-dark')
  get setDropDownThemeDarkClass(): boolean {
    return this.dwInDropDown && (this.dwTheme === 'dark');
  }

  @HostBinding('class.ant-menu-light')
  get setMenuThemeLightClass(): boolean {
    return (!this.dwInDropDown) && (this.dwTheme === 'light');
  }

  @HostBinding('class.ant-menu-dark')
  get setMenuThemeDarkClass(): boolean {
    return (!this.dwInDropDown) && (this.dwTheme === 'dark');
  }

  @HostBinding('class.ant-menu-vertical')
  get setMenuVerticalClass(): boolean {
    return (!this.dwInDropDown) && (this.dwMode === 'vertical');
  }

  @HostBinding('class.ant-menu-horizontal')
  get setMenuHorizontalClass(): boolean {
    return (!this.dwInDropDown) && (this.dwMode === 'horizontal');
  }

  @HostBinding('class.ant-menu-inline')
  get setMenuInlineClass(): boolean {
    return (!this.dwInDropDown) && (this.dwMode === 'inline');
  }

  @HostBinding('class.ant-menu-inline-collapsed')
  get setMenuInlineCollapsedClass(): boolean {
    return (!this.dwInDropDown) && (this.dwMode !== 'horizontal') && this.dwInlineCollapsed;
  }

  constructor(public el: ElementRef) {

  }

  ngAfterContentInit(): void {
    this.isInit = true;
    this.cacheMode = this.dwMode;
    this.updateInlineCollapse();
  }

  /** trigger when menu item clicked */
  clearAllSelected(): void {
    this.menuItems.forEach(menu => menu.dwSelected = false);
  }

  hideSubMenus(): void {
    this.subMenusOpenIndex = [];
    this.subMenus.forEach((submenu, index) => {
      if (submenu.dwOpen) {
        this.subMenusOpenIndex.push(index);
      }
      submenu.dwOpen = false;
    });
  }

  reductionSubMenus(): void {
    this.subMenusOpenIndex.forEach(i => this.subMenus[ i ].dwOpen = true);
    this.subMenusOpenIndex = [];
  }

  clickItem(value: DwMenuItemDirective): void {
    this.dwClick.emit(value);
  }
}
