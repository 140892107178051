import { Directive } from '@angular/core';

@Directive({
  selector: '[dw-card-grid]',
  host    : {
    '[class.ant-card-grid]': 'true'
  }
})
export class DwCardGridDirective {
}
