import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { DwI18nModule } from '../i18n/dw-i18n.module';
import { DwSelectModule } from '../select/dw-select.module';

import { DwPaginationComponent } from './dw-pagination.component';

@NgModule({
  declarations: [ DwPaginationComponent ],
  exports     : [ DwPaginationComponent ],
  imports     : [ CommonModule, FormsModule, DwSelectModule, DwI18nModule ]
})

export class DwPaginationModule {
}
