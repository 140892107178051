import { ObserversModule } from '@angular/cdk/observers';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DwTabBodyComponent } from './dw-tab-body.component';
import { DwTabLabelDirective } from './dw-tab-label.directive';
import { DwTabComponent } from './dw-tab.component';
import { DwTabsInkBarDirective } from './dw-tabs-ink-bar.directive';
import { DwTabsNavComponent } from './dw-tabs-nav.component';
import { DwTabSetComponent } from './dw-tabset.component';

@NgModule({
  declarations: [ DwTabComponent, DwTabSetComponent, DwTabsNavComponent, DwTabLabelDirective, DwTabsInkBarDirective, DwTabBodyComponent ],
  exports     : [ DwTabComponent, DwTabSetComponent, DwTabsNavComponent, DwTabLabelDirective, DwTabsInkBarDirective, DwTabBodyComponent ],
  imports     : [ CommonModule, ObserversModule ]
})
export class DwTabsModule {
}
