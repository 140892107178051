import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DwProgressComponent } from './dw-progress.component';

@NgModule({
  exports     : [DwProgressComponent],
  declarations: [DwProgressComponent],
  imports     : [CommonModule]
})
export class DwProgressModule {
}
