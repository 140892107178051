import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DwGridModule } from '../grid/dw-grid.module';
import { DwFormControlComponent } from './dw-form-control.component';
import { DwFormExplainComponent } from './dw-form-explain.component';
import { DwFormExtraComponent } from './dw-form-extra.component';
import { DwFormItemComponent } from './dw-form-item.component';
import { DwFormLabelComponent } from './dw-form-label.component';
import { DwFormSplitComponent } from './dw-form-split.component';
import { DwFormTextComponent } from './dw-form-text.component';
import { DwFormDirective } from './dw-form.directive';

@NgModule({
  declarations: [ DwFormExtraComponent, DwFormLabelComponent, DwFormDirective, DwFormItemComponent, DwFormControlComponent, DwFormExplainComponent, DwFormTextComponent, DwFormSplitComponent ],
  exports     : [ DwFormExtraComponent, DwFormLabelComponent, DwFormDirective, DwFormItemComponent, DwFormControlComponent, DwFormExplainComponent, DwFormTextComponent, DwFormSplitComponent ],
  imports     : [ CommonModule, DwGridModule ]
})
export class DwFormModule {
}
